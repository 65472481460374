import { Flex } from 'antd';
import { useChatListStore } from '../../../store/chat-list';
import { useGetReason } from '../../../api/hooks/inbox/useRejectReason';
import { Paragraph, SelectMenu } from '@squantumengine/horizon';
import { RejectReasonKYCInterface } from './kyc.interface';

function RejectReasonKYC({ setRejectReason }: RejectReasonKYCInterface) {
  const { selectedCall } = useChatListStore();
  const { data: dataReason } = useGetReason(selectedCall!?.inboxId!);

  const mappedReason = dataReason!?.map(({ uuid: value, reason: label, ...rest }) => ({
    value,
    label,
    ...rest
  }));

  return (
    <Flex vertical>
      <Paragraph>Alasan Penolakan KYC:</Paragraph>
      <SelectMenu
        onChange={(value: any) => setRejectReason(value)}
        options={mappedReason}
        placeholder="Pilih Alasan Penolakan"
      />
    </Flex>
  );
}

export default RejectReasonKYC;
