import Collapsible from '../../../commons/Collapsible';

import CustomerInfo from '../components/customer-info';
import TitleCollapse from '../components/title-collapse';
import CustomerFiles from '../components/customer-files';
import HistoryList from '../components/history';
import { useGetVerdictKYC } from '../../../../api/hooks/kyc/useGetVerdictKYC';
import { useChatListStore } from '../../../../store/chat-list';
import KYCResult from '../../kyc/kyc-result';
import { Icon } from '@squantumengine/horizon';
import useLoadBerkas from '../../../../hooks/useLoadBerkas';
import { KYCPageEnum } from '../../../kyc/kyc.interface';
import KYCPage from '../../kyc';
import SummaryKYC from '../../kyc/summary-kyc';
import { StatusKYCEnum } from '../../kyc/kyc.interface';
import { isNullOrUndefined } from '../../../../utils/isNullUndefined';
import { useInboxStore } from '../../../../store/new/inbox';

enum CollapseKey {
  CHAT = 'chat',
  DATA_PELANGGAN = 'data_pelanggan',
  INFORMASI = 'informasi',
  RIWAYAT = 'riwayat',
  BERKAS = 'berkas',
  KYC = 'kyc',
  RINGKASAN = 'isi ringkasan'
}

type CollapsibleItem = {
  key: CollapseKey;
  label: JSX.Element | string;
  content: JSX.Element;
};
function AgentWorkspace() {
  const { isSelectedInboxManualKyc } = useInboxStore();

  const { selectedCall } = useChatListStore();
  const { data: kycVerdict } = useGetVerdictKYC(selectedCall);
  const { isRefetchBerkas, handleReloadBerkas } = useLoadBerkas();

  const isManualKYCMode = isSelectedInboxManualKyc();

  const collapsibleItems = [
    {
      key: CollapseKey.INFORMASI,
      label: <TitleCollapse icon="folder-download" title="Data KYC" />,
      content: <KYCResult kycVerdict={kycVerdict} />
    },
    {
      key: CollapseKey.KYC,
      label: <TitleCollapse icon="folder-download" title="Lengkapi Data KYC" />,
      content: <KYCPage defaultPage={KYCPageEnum.KYC} />
    },
    {
      key: CollapseKey.RINGKASAN,
      label: <TitleCollapse icon="sticky-note" title="Isi Ringkasan" />,
      content: <SummaryKYC />
    },
    {
      key: CollapseKey.DATA_PELANGGAN,
      label: <TitleCollapse icon="user" title="Info Pelanggan" />,
      content: <CustomerInfo />
    },
    {
      key: CollapseKey.BERKAS,
      label: (
        <TitleCollapse
          icon="paperclip"
          title="Berkas Pelanggan"
          actionEl={
            <span
              className={`${isRefetchBerkas && 'animate-spin'} flex cursor-pointer`}
              onClick={handleReloadBerkas}>
              <Icon name="sync" />
            </span>
          }
        />
      ),
      content: <CustomerFiles />
    },
    {
      key: CollapseKey.RIWAYAT,
      label: <TitleCollapse icon="history" title="Riwayat" />,
      content: <HistoryList />
    }
  ].reduce((acc, curr): any => {
    const shouldShowInformasi =
      curr.key === CollapseKey.INFORMASI &&
      kycVerdict?.status !== StatusKYCEnum.FACE_UMATCH &&
      !isNullOrUndefined(kycVerdict) &&
      isManualKYCMode;
    const shouldShowKYC =
      curr.key === CollapseKey.KYC &&
      (kycVerdict?.status === StatusKYCEnum.FACE_UMATCH || isNullOrUndefined(kycVerdict)) &&
      isManualKYCMode;
    const shouldShowRingkasan = curr.key === CollapseKey.RINGKASAN && isManualKYCMode;

    // Condition 1: Show CollapseKey.INFORMASI if kycVerdict is present and not face unmatch
    if (shouldShowInformasi) {
      return [...acc, curr];
    }

    // Condition 2: Show CollapseKey.KYC if kycVerdict is not present and isManualKYCMode is true
    if (shouldShowKYC) {
      return [...acc, curr];
    }

    // Condition 3: Show CollapseKey.RINGKASAN only if isManualKYCMode is true
    if (shouldShowRingkasan) {
      return [...acc, curr];
    }

    // Add the current item to the accumulator if no conditions block it
    // Note: Other CollapseKeys that do not depend on the conditions should be included
    if (
      curr.key !== CollapseKey.INFORMASI &&
      curr.key !== CollapseKey.KYC &&
      curr.key !== CollapseKey.RINGKASAN
    ) {
      return [...acc, curr];
    }

    return acc; // Default return if conditions do not match
  }, []);

  const _renderCollapsibleItem = ({ key, label, content }: CollapsibleItem, index: number) => (
    <>
      <div
        className={`px-[24px] py-[12px] ${
          index !== 0 && 'border-b-0 border-l-0 border-r-0 border-t border-solid border-gray-300'
        }`}>
        <Collapsible key={key} label={label}>
          {content}
        </Collapsible>
      </div>
    </>
  );

  return (
    <div className="space-y-0" id="dashboard-workspace">
      {collapsibleItems.map(_renderCollapsibleItem)}
    </div>
  );
}

export default AgentWorkspace;
