import { MessageEventEnum } from '../static/message-event';
import { ConversationListInterface, ConversationType } from './conversation-list-state.interface';

export const getConversationListKey = (key: ConversationType): keyof ConversationListInterface => {
  switch (key) {
    case MessageEventEnum.ASSIGNED:
      return 'assignedConversationList';
    case MessageEventEnum.CREATED:
      return 'createdConversationList';
    case MessageEventEnum.SESSION_CLOSED:
      return 'preClosureConversationList';
    case MessageEventEnum.INACTIVE:
      return 'closedConversationList';
    case MessageEventEnum.RECENT_ACTIVITY:
      return 'recentActivity';
    default:
      return 'activeConversationList';
  }
};
