import {
  KYCVerdictResponse,
  ManualKYCFunnelInterface,
  TopRejectReasonInterface
} from '../components/kyc/kyc.interface';
import axiosInstance, { AxiosResponse } from '../config/axios';
import axiosInstanceAnalytics from '../config/axios/analytics';
import { buildQueryString } from '../utils/string';
import { KYCParam, PayloadKYCParam } from './param.interface';

export const allowTakePicture: ({
  conversationId,
  actionType
}: KYCParam) => Promise<void> = async ({ conversationId, actionType }: KYCParam) => {
  try {
    await axiosInstance.post(`conversation/${conversationId}/contact/action/${actionType}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const allowStartVideoCall: ({
  conversationId,
  actionType
}: KYCParam) => Promise<void> = async ({ conversationId, actionType }: KYCParam) => {
  try {
    await axiosInstance.post(`conversation/${conversationId}/contact/action/${actionType}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const submitKYC: ({
  data,
  accountId,
  conversationId
}: PayloadKYCParam) => Promise<AxiosResponse<string> | undefined> = async ({
  data,
  accountId,
  conversationId
}: PayloadKYCParam) => {
  try {
    const response = await axiosInstance.post(
      `account/${accountId}/conversation/${conversationId}/manual_kyc`,
      data
    );

    return response;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw new Error(error);
  }
};

export const getVerdictKYC: (
  conversationId: string
) => Promise<KYCVerdictResponse | undefined> = async (conversationId) => {
  try {
    const response = await axiosInstance.get(`conversation/${conversationId}/kyc/status`);

    return response?.data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw new Error(error);
  }
};
export const CaptureScreeshoot = async (conversationId: string) => {
  try {
    const response = await axiosInstance.post(`conversation/${conversationId}/capture/screenshot`);

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const SelectInstructuctionFrame = async (
  conversationId: string | undefined,
  frame: string | null
) => {
  try {
    const response = await axiosInstance.post(
      `conversation/${conversationId}/frame/${frame || 'NORMAL'}`
    );

    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const getKYCFunnel: (
  accountId: string,
  inboxId: string,
  startDateISO: string,
  endDateISO: string
) => Promise<ManualKYCFunnelInterface | undefined> = async (
  accountId,
  inboxId,
  startDateISO,
  endDateISO
) => {
  try {
    const response = await axiosInstanceAnalytics.get(
      `accounts/${accountId}/inboxes/${inboxId}/manual-kyc?start=${startDateISO}&end=${endDateISO}`
    );

    return response?.data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw new Error(error);
  }
};

export const getTopRejectReason: (
  accountId: string,
  inboxId: string,
  params: Record<string, any>
) => Promise<TopRejectReasonInterface | undefined> = async (accountId, inboxId, params) => {
  try {
    const response = await axiosInstanceAnalytics.get(
      `accounts/${accountId}/inboxes/${inboxId}/reject-reason?${buildQueryString(params)}`
    );

    return response?.data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw new Error(error);
  }
};
