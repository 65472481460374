import { useRoutes, Navigate } from 'react-router-dom';

import { HomeLayout } from './layouts/home-layout';
import { ProtectedLayout } from './layouts/protected-layout';
import { AuthLayout } from './layouts/auth-layout';

import DashboardPage from './pages/dashboard/index';
import DashboardPageV2 from './pages/v2/dashboard/index';
import LoginPage from './pages/login';
import User from './pages/user';
import Team from './pages/team';
import AdminTeam from './pages/team/admin';
import SPVTeam from './pages/team/supervisor';
import PageError from './pages/error';
import AnalyticSupervisor from './pages/analytics/analytics-supervisor';
import ConversationHistory from './pages/conversations/conversation-history';
import ConversationHistoryV2 from './pages/v2/conversations/conversation-history';
import AuditTrail from './pages/audit-trail';
import AuditTrailV2 from './pages/v2/audit-trail';
import SSOCallbackPage from './pages/sso-callback';
import PrivacyPoliciesPage from './pages/privacy-policy';
import NoAccessRole from './pages/no-access';
import ResetPassword from './pages/auth/new-password';
import EmailResetPassword from './pages/auth/reset-password';
import WrongUrlPage from './pages/wrong-url';
import ShouldUpdatePassword from './pages/auth/should-update-password';
import ExpiredResetLink from './pages/auth/reset-link-expired';
import ResetPasswordSent from './pages/auth/reset-password-sent';
import ListTeam from './pages/team/list';
import CreateEditTeam from './pages/team/create-edit';
import DetailTeam from './pages/team/detail';
import ListUserSQEID from './pages/v2/users/sqeid';
import { useAuthStore } from './store/new/auth';
import { LoginPlatform } from './shared/types/auth.interface';
import ListUserNATIVE from './pages/v2/users/native';

function Router() {
  const { isNativeLoggedIn, authProvider } = useAuthStore();

  let element = useRoutes([
    {
      element: <AuthLayout />,
      children: [
        {
          element: <HomeLayout />,
          children: [
            {
              path: 'sso-callback',
              element: <SSOCallbackPage />
            },
            { path: 'login', element: <LoginPage /> }
          ]
        },
        {
          path: 'dashboard',
          element: <ProtectedLayout />,
          children: [
            { path: '', element: <DashboardPage /> },
            { path: 'inboxes/:inboxId', element: <DashboardPage /> },
            {
              path: 'inboxes/:inboxId/conversations/conversation-history/',
              element: <ConversationHistory />
            },
            { path: 'user', element: <User /> },
            {
              path: 'team',
              element: <Team />,
              children: [
                { path: 'admin', element: <AdminTeam /> },
                { path: 'supervisor', element: <SPVTeam /> }
              ]
            },
            { path: 'audit-trail', element: <AuditTrail /> },
            { path: 'analytics/:inboxId', element: <AnalyticSupervisor /> },
            { path: 'error/:status', element: <PageError /> }
          ]
        },
        {
          path: 'v2',
          children: [
            {
              path: 'dashboard',
              element: <ProtectedLayout />,
              children: [
                { path: '', element: <DashboardPageV2 /> },
                { path: 'inboxes/:inboxId', element: <DashboardPageV2 /> },
                {
                  path: 'inboxes/:inboxId/conversations/conversation-history/',
                  element: <ConversationHistoryV2 />
                },
                {
                  path: 'team',
                  children: [
                    { path: 'list', element: <ListTeam /> },
                    { path: 'create', element: <CreateEditTeam /> },
                    { path: 'detail/:inboxId', element: <DetailTeam /> },
                    { path: 'edit/:inboxId', element: <CreateEditTeam /> },
                    {
                      path: 'users',
                      element:
                        isNativeLoggedIn() || authProvider === LoginPlatform.NATIVE ? (
                          <ListUserNATIVE />
                        ) : (
                          <ListUserSQEID />
                        )
                    }
                  ]
                },
                { path: 'audit-trail', element: <AuditTrailV2 /> }
              ]
            }
          ]
        },
        { path: 'reset', element: <ResetPassword /> },
        { path: 'reset-password', element: <EmailResetPassword /> },
        { path: 'should-update-password', element: <ShouldUpdatePassword /> },
        { path: 'wrong-url', element: <WrongUrlPage /> },
        { path: 'reset-link-expired', element: <ExpiredResetLink /> },
        { path: 'reset-link-sent', element: <ResetPasswordSent /> }
      ]
    },
    {
      path: 'privacy-policies',
      element: <PrivacyPoliciesPage />
    },
    {
      path: 'no-access',
      element: <NoAccessRole />
    },
    { path: '*', element: <Navigate to="/login" /> }
  ]);

  return element;
}

export default Router;
