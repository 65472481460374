import { MutableRefObject, useRef, useState } from 'react';
import { Button, Form, message, Upload } from 'antd';
import ProfileInput from '../components/form/profile';
import { ProfileFormInputInterface } from '../shared/types/profile.interface';
import { getIconUrl } from '../utils/getIconUrl';
import { getUserInitialData } from '../static/user-state';
import { UploadProfilePicture, updateUser } from '../api/user';
import parseFullName from '../utils/fullNameParse';
import { useUserStatusStore } from '../store/user-state';
import { getUserJSON } from '../utils/user-json';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { fastLoginInterface } from '../shared/types/fasLogin.iterface';
import { useMutation } from 'react-query';
import { LoginPlatform } from '../shared/types/auth.interface';
import { useGetUsrProfileSQEID } from '../api/hooks/sqeid/useSQEID';
import { LocalStorageKey } from '../static/local-storage';

const isSIMASIDPlatform = process.env.REACT_APP_LOGIN_PLATFORM === LoginPlatform.SIMAS_ID;

function ConfigPage() {
  const userInitialData = getUserInitialData();
  const [imagePreview, setImagePreview] = useState<File | null>();
  const [isUpdatedProfPict, setIsUpdatedProfPict] = useState<boolean>(false);
  const [imageObject, setImageObject] = useState<any>({});
  const [form] = Form.useForm();
  const fileInputButton = useRef() as MutableRefObject<HTMLButtonElement>;
  const [messageApi, contextHolder] = message.useMessage();
  const { name: userName, setName, setImageUrl, imageUrl } = useUserStatusStore();
  const userJSON = getUserJSON();
  const [fastLogin, setFastLogin] = useLocalStorage(
    LocalStorageKey.FAST_LOGIN,
    [] as fastLoginInterface[]
  );
  const user = getUserJSON();

  const successToastr = (message: string) => {
    messageApi.open({
      type: 'success',
      content: message
    });
  };

  const errorToastr = (error: any) => {
    messageApi.open({
      type: 'error',
      content: error.response?.data?.message
        ? error.response?.data?.message
        : 'Terjadi kesalahan, silahkan coba lagi nanti!'
    });
  };

  const handleProfileUpload = (e: any) => {
    const selectedFile = e.file.originFileObj;
    setImagePreview(selectedFile);

    if (Object.keys(imageObject).length === 0 || imageObject.file?.originFileObj !== selectedFile) {
      setIsUpdatedProfPict(true);
      setImageObject(e);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setImagePreview(null);
  };

  useGetUsrProfileSQEID({
    refetchOnWindowFocus: true,
    onSuccess: (data: any) => {
      const firstName = data?.agent?.firstName;
      const lastName = data?.agent?.lastName;
      const fullName = `${firstName} ${lastName ? lastName : ''}`;
      setName(fullName);

      const payload = {
        fullName,
        agentId: data?.agent?.agentId,
        phone: data?.agent?.phone
      };

      localStorage.setItem(
        'user',
        JSON.stringify({
          ...user,
          ...payload
        })
      );

      form.setFieldsValue(payload);
    }
  });

  const { mutate: updateUserMutation, isLoading: isLoadingUserUpdate } = useMutation(updateUser, {
    onSuccess: (data) => {
      let newArr = fastLogin;
      const userName = `${data.firstName} ${data.lastName}`;
      const index = fastLogin.findIndex((item) => item.agentId === data.agentId);
      newArr[index].name = userName;
      newArr[index].phone = data.phone;
      setFastLogin(newArr);

      localStorage.setItem(
        'user',
        JSON.stringify({
          ...userJSON,
          name: userName,
          phone: data.phone
        })
      );
      successToastr('Update Data Diri Berhasil!');
      setName(userName);
    },
    onError: (error) => {
      errorToastr(error);
    }
  });
  const { mutate: updataProfileMutation, isLoading: isLoadingProfPict } = useMutation(
    UploadProfilePicture,
    {
      onSuccess: (data, variables, context) => {
        const index = fastLogin.findIndex((item) => item.agentId === userJSON.agentId);
        let newArr = fastLogin;
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...userJSON,
            image: data.url
          })
        );
        setImageUrl(data.url);
        newArr[index].image = data.url;
        setFastLogin(newArr);
        successToastr('Update Profil Picture Berhasil!');
      },
      onError: (error) => {
        errorToastr(error);
      }
    }
  );
  const isDisableSubmit = isLoadingProfPict || isLoadingUserUpdate;

  const onSubmit = async ({ agentId, fullName, phone }: ProfileFormInputInterface) => {
    const isUserMatch =
      isUpdatedProfPict || userName.trim() !== fullName.trim() || userJSON.phone !== phone;
    if (isUserMatch) {
      const [firstName, lastName] = parseFullName(fullName);
      updateUserMutation({ agentId, firstName, lastName, phone });
      if (!isLoadingProfPict) {
        updataProfileMutation(imageObject.file?.originFileObj);
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        name="ProfileForm"
        autoComplete="off"
        initialValues={userInitialData}
        layout="vertical"
        className="flex gap-10 bg-white p-5"
        onFinish={onSubmit}
        form={form}>
        <div>
          <h1 className="text-2xl font-semibold">Foto</h1>
          <div className="relative h-40 w-40 overflow-hidden rounded-full">
            <div className="absolute left-[50%] top-[70%] z-0 flex h-8 w-8 -translate-x-[50%] cursor-pointer items-center justify-center rounded-full border-2 border-solid border-white bg-black/20 duration-200 ease-out hover:bg-black/50">
              <img
                src={getIconUrl('pencil.svg')}
                alt="edit-icon"
                className={`h-4 w-4 ${isSIMASIDPlatform && 'cursor-not-allowed'}`}
                onClick={() => fileInputButton.current.click()}
              />
              <Form.Item
                name="upload"
                label="Upload"
                valuePropName="fileList"
                className="hidden"
                getValueFromEvent={handleProfileUpload}>
                <Upload name="image" accept="image/png, image/jpeg, image/png">
                  <Button disabled={isSIMASIDPlatform} className="hidden" ref={fileInputButton} />
                </Upload>
              </Form.Item>
            </div>
            <img
              className={`h-full w-full rounded-full border-2 border-solid border-[#D2D2D2] object-cover object-center ${
                imageUrl || 'bg-grey-10'
              }}`}
              src={
                imagePreview
                  ? URL.createObjectURL(imagePreview)
                  : imageUrl || getIconUrl('advisor-avatar.svg')
              }
              alt="profile_pic"
            />
          </div>
        </div>
        <div className="flex w-[486px] flex-col">
          <h1 className="text-2xl font-semibold">Data Diri</h1>
          <ProfileInput formAttribute="fullName" label="Nama" disabled={isSIMASIDPlatform} />
          <ProfileInput formAttribute="role" label="Jabatan" disabled />
          <ProfileInput formAttribute="agentId" label="Nomor ID" disabled />
          <ProfileInput formAttribute="phone" label="Nomor Telepon" disabled={isSIMASIDPlatform} />
          <Form.Item>
            <div className="mt-5 flex flex-wrap gap-3">
              <Button
                className={`h-full w-40 rounded-3xl border-none ${
                  isDisableSubmit || isSIMASIDPlatform
                    ? 'cursor-not-allowed bg-gray-400'
                    : 'cursor-pointer bg-bold-green'
                } py-3 font-semibold text-white`}
                htmlType="submit"
                disabled={isDisableSubmit || isSIMASIDPlatform}>
                Simpan
              </Button>
              <Button
                disabled={isSIMASIDPlatform}
                className={`h-full ${
                  isSIMASIDPlatform
                    ? 'cursor-not-allowed bg-gray-100'
                    : 'cursor-pointer bg-transparent'
                } w-40 rounded-3xl border-[1px] border-solid border-black/70 py-3 font-semibold text-black outline-none`}
                onClick={(e: any) => {
                  e.preventDefault();
                  onCancel();
                }}>
                Batalkan
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

export default ConfigPage;
