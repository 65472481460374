import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from '../../../static/role';
import { useConversationListStore } from '../../../store/conversation-list-state';
import { useUserStatusStore } from '../../../store/user-state';
import { ConversationInterface } from '../../card/chat-message/chat-message.interface';
import IncomingCallItems from './incomingCallItem';
import { Helmet } from 'react-helmet';
import { useChatListStore } from '../../../store/chat-list';
import { NotificationEnum, NotificationInterface } from './notification.interface';
import VideoCallSound from '../../../assets/sounds/video-call.mp3';
import { CommunicationTypeEnum, ConversationContextEnum } from '../../../static/communication-type';
import { useCallStore } from '../../../store/call-state';
import { inboxDashboard } from '../../../static/routeConstructor';

interface HTMLAudioElementWithSinkId extends HTMLAudioElement {
  setSinkId: (sinkId: string) => Promise<void>;
}
export default function IncomingCallNotification(props: {
  handleActiveCall: (data: ConversationInterface) => void;
}) {
  const { handleActiveCall } = props;
  const [tabNotifList, setTabNotifList] = useState<NotificationInterface[]>([]);
  const [currentCallCount, setCurrentCallCount] = useState(0);
  const [currentMessageCount, setCurrentMessageCount] = useState(0);
  const { setIsManualKYCMode } = useCallStore();
  const { userRole } = useUserStatusStore();
  const { unreadMessages } = useChatListStore();
  const { setFullScreen } = useCallStore();
  const { assignedConversationList, bannerNotificationList, removeBannerNotificationlist } =
    useConversationListStore();
  const incomingCall =
    userRole === RoleEnum.SUPERVISOR ? bannerNotificationList : assignedConversationList;
  const navigate = useNavigate();

  const audioRefs = useRef<(HTMLAudioElementWithSinkId | null)[]>([]);

  const playToAllDevices = async () => {
    const devices = await navigator?.mediaDevices?.enumerateDevices();
    const audioOutputs = devices?.filter((device) => device.kind === 'audiooutput');

    const audioSrc = VideoCallSound;

    // Use a Set to track unique device IDs
    const uniqueDeviceIds = new Set<string>();

    audioOutputs?.forEach(async (device, index) => {
      if (!uniqueDeviceIds.has(device?.deviceId)) {
        uniqueDeviceIds.add(device?.deviceId); // Add the device ID to the Set

        const audioElement = new Audio(audioSrc) as HTMLAudioElementWithSinkId;
        audioRefs.current[index] = audioElement;

        if (audioElement.setSinkId) {
          audioElement.loop = true;
          await audioElement.setSinkId(device.deviceId); // Set output device
          audioElement.play();
        }
      }
    });
  };

  const pauseAudioOnAllDevices = () => {
    audioRefs.current?.forEach((audioElement) => {
      audioElement?.pause(); // Pause each audio element
    });
  };

  const handleClick = (data: ConversationInterface) => {
    if (userRole === RoleEnum.SUPERVISOR) {
      navigate(inboxDashboard({ inboxId: data?.inboxId!! }));
      removeBannerNotificationlist(data);
    } else {
      setIsManualKYCMode(data.inboxConfig?.conversationContext === ConversationContextEnum.KYC);
      if (data.inboxConfig?.conversationContext === ConversationContextEnum.KYC) {
        setFullScreen(true);
      }
      handleActiveCall(data);
    }
  };

  const MessageArray: { id: string; value: number }[] = [];
  for (const id in unreadMessages) {
    if (unreadMessages.hasOwnProperty(id)) {
      MessageArray.unshift({ id, value: unreadMessages[id] });
    }
  }
  const totalMessage = MessageArray.reduce((acc, curr) => acc + curr.value, 0);
  const tabNotifCounter = incomingCall.length + totalMessage;

  useEffect(() => {
    if (incomingCall.length > currentCallCount) {
      setCurrentCallCount(currentCallCount + 1);
      const obj = {
        type: NotificationEnum.IncomingCall,
        id: incomingCall[0].id
      };
      setTabNotifList((prev) => [obj, ...prev]);
      // if sound is not playing and there is incoming chat with type video
      if (
        incomingCall.find((item) =>
          [CommunicationTypeEnum.VIDEO, CommunicationTypeEnum.CHAT].includes(
            item.communicationType!
          )
        )
      ) {
        playToAllDevices();
      }
    }
    if (MessageArray.length > currentMessageCount) {
      setCurrentMessageCount(currentMessageCount + 1);
      const obj = {
        type: NotificationEnum.IncomingMessage,
        id: MessageArray[0].id
      };
      setTabNotifList((prev) => [obj, ...prev]);
    }
    if (MessageArray.length < currentMessageCount) {
      setCurrentMessageCount(MessageArray.length);
      const result = tabNotifList.filter((item1) =>
        MessageArray.some((item2) => item1.id === item2.id)
      );
      setTabNotifList(result);
    }
    if (incomingCall.length < currentCallCount) {
      setCurrentCallCount(incomingCall.length);
      const result = tabNotifList.filter((item1) =>
        incomingCall.some((item2) => item1.id === item2.id)
      );
      setTabNotifList(result);
      pauseAudioOnAllDevices();
    }

    // eslint-disable-next-line
  }, [incomingCall, MessageArray]);
  const tabTitle =
    tabNotifList.length > 0
      ? `(${tabNotifCounter}) ${tabNotifList[0].type}`
      : 'SQECC Contact Center';

  return (
    <div className="absolute left-1/2 top-2 z-[53] flex flex-col gap-2">
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      {incomingCall?.map((items: ConversationInterface, key) => (
        <IncomingCallItems
          items={items}
          key={key}
          handleActiveCall={handleClick}
          assignedConversationList={assignedConversationList}
        />
      ))}
    </div>
  );
}
