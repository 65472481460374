import { useCallStore } from '../../../../store/call-state';
import { useEffect, useRef } from 'react';
import VideoRequest from '../../../../utils/webSocket/videoRequest';
import { IncomingChatCallInterface } from './incoming-call.interface';
import { useChatListStore } from '../../../../store/chat-list';
import {
  AllowStartCall,
  CommunicationTypeEnum,
  defaultCommunicationTypeEnum
} from '../../../../static/communication-type';
import { meetingRoomId as WSMeetingRoomId, setMeetingRoomId } from '../../../../utils/webSocket';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import { useConversationListStore } from '../../../../store/conversation-list-state';
import { MessageEventEnum } from '../../../../static/message-event';
import { stopVideoNotification } from '../../../../utils/notification';
import { Button, Icon, Paragraph } from '@squantumengine/horizon';
import { generateAvatarImage } from '../../../../utils/fullNameParse';
import { useAllowStartVideoCall } from '../../../../api/hooks/kyc/useStartVideoCall';
import { useInboxStore } from '../../../../store/new/inbox';

export default function IncomingChatCall({ conversationId, messageId }: IncomingChatCallInterface) {
  const { isVideoRequested, setFullScreen, setShowVideo, setIsVoiceCall, setShowCameraButton } =
    useCallStore();
  const { selectedCall } = useChatListStore();
  const { activeConversationList, setConversationList } = useConversationListStore();
  const { userRole } = useUserStatusStore();

  const { isSelectedInboxManualKyc } = useInboxStore();
  const isManualKYCMode = isSelectedInboxManualKyc();

  const videoRef = useRef<HTMLVideoElement>(null);
  const firstRender = useRef(true);
  let mediaStreamRef = useRef<MediaStream | null>(null);

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      mediaStreamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      // console.error('Error accessing camera: ', error);
    }
  };

  const stopCamera = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  const defaultCommunicationType =
    selectedCall?.inboxConfig?.mediaCallConfiguration.defaultCommunicationType;

  const isAudioOnly = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO;
  const isAudioVideo = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO_VIDEO;
  const isVoiceCall = isAudioOnly || isAudioVideo;
  // const isVoiceCall = true;
  const isSwitched =
    selectedCall?.metadata?.communicationType === defaultCommunicationTypeEnum.VIDEO;
  const isChat = selectedCall?.communicationType === CommunicationTypeEnum.CHAT;

  const { mutate } = useAllowStartVideoCall();
  const handleAnswerCall = () => {
    mutate({
      conversationId: conversationId,
      actionType: AllowStartCall.AGENT_JOINED_MEETING_ROOM
    });
    stopCamera(); // Stop the camera when the "Jawab" button is clicked

    if (isManualKYCMode) setFullScreen(true);
    setIsVoiceCall(isAudioVideo || isAudioOnly);
    isVoiceCall && setShowCameraButton(isSwitched || !isVoiceCall);
    VideoRequest({ audio: true, video: !isVoiceCall });
    setShowVideo(true);
    localStorage.setItem('videoMessageId', messageId);

    const newCallList = [...activeConversationList];

    const newCall = newCallList.find((item) => item.conversationId === conversationId);
    if (newCall && newCall.communicationType !== CommunicationTypeEnum.VIDEO) {
      newCall.communicationType = CommunicationTypeEnum.VIDEO;
      setConversationList(MessageEventEnum.ACTIVE, newCallList);
    }

    localStorage.setItem('meetingRoomId', WSMeetingRoomId);
    setMeetingRoomId(WSMeetingRoomId);
    stopVideoNotification();
  };

  useEffect(() => {
    const isAutoVideoReady =
      (isManualKYCMode || !isChat) && WSMeetingRoomId && userRole === RoleEnum.AGENT;
    if (isAutoVideoReady && firstRender.current && !isVideoRequested) {
      firstRender.current = false;
      setTimeout(() => {
        if (isManualKYCMode) setFullScreen(true);
        handleAnswerCall();
      }, 500);
    }
    // eslint-disable-next-line
  }, [WSMeetingRoomId, isManualKYCMode]);

  useEffect(() => {
    if (!isVoiceCall) {
      openCamera();
    }
    return () => {
      stopCamera();
    };
  }, [isVoiceCall]);

  return (
    <div className="flex w-full gap-2 bg-black-rich p-4">
      <div className="">
        {isVoiceCall ? (
          <img
            className="cursor-pointer rounded-full border-2 border-solid border-[#D2D2D2] bg-grey-10 object-cover"
            width={52}
            height={52}
            src={generateAvatarImage(selectedCall?.name || '', '#8490A1', '#FFFFFF', 60)}
            alt="profile_pic"
          />
        ) : (
          <video
            ref={videoRef}
            className="max-h-[177px] w-full min-w-[300px] rounded-lg object-cover"
            playsInline
            muted></video>
        )}
      </div>
      <div
        className={`${
          isVoiceCall ? 'flex-row items-center' : 'flex-col'
        }  flex w-full  justify-center gap-6 rounded-lg shadow-lg`}>
        <div className="flex flex-col items-center justify-center gap-6 text-base text-white">
          <Paragraph size="s" weight="semibold">
            Pelanggan ingin melakukan panggilan {isVoiceCall ? 'suara' : 'video'}
          </Paragraph>
        </div>
        {userRole === RoleEnum.AGENT && (
          <div className="flex flex-col gap-3">
            <Button
              className="flex h-8 w-full items-center gap-2 rounded-lg border-none"
              onClick={handleAnswerCall}>
              <Paragraph size="s" color="white" weight="semibold">
                Jawab
              </Paragraph>
              <Icon className="ml-2" color="white" name={isVoiceCall ? 'phone' : 'video'} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
