/* eslint-disable no-console */
import axios from 'axios';
import { isDatadogLogEnabled, sendLogError, sendLogInfo } from '../datadog';
import HttpStatusCode from '../../types/enums/request';
import { PageLocation } from '../../types/enums/page';
import axiosInstancev2 from './axiosInstanceV2';
import { LoginPlatform } from '../../shared/types/auth.interface';
import { forceLogOut } from '../../utils/login';
import { useAuthStore } from '../../store/new/auth';
import { ErrorCodeEnum } from '../../static/error';
import interceptor from '../../utils/interceptors';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
    authenticationPlatform: `${LoginPlatform.NATIVE}`,
    Authorization: `jwt ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  }
});

axiosInstance.interceptors.request.use(async (config) => {
  const authProvider = useAuthStore.getState().authProvider;
  const isSQEIDLoggedIn = useAuthStore.getState().isSQEIDLoggedIn();

  if (isSQEIDLoggedIn || authProvider === LoginPlatform.SIMAS_ID) {
    config.headers.authenticationPlatform = LoginPlatform.SIMAS_ID;
    return interceptor.SQEID({config , axiosInstance});
  }

  config.headers.authenticationPlatform = LoginPlatform.NATIVE;
  return interceptor.NATIVE(config);
});

axiosInstance?.interceptors.response.use(
  (response: any) => {
    if (isDatadogLogEnabled) {
      sendLogInfo(`[${response?.status}] ${JSON.stringify(response?.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: response?.config?.url,
        method: response?.config?.method,
        status_code: response?.status
      });
    }

    return response;
  },
  (error: any) => {
    const code = error.response?.status;
    if (isDatadogLogEnabled) {
      sendLogError(`[${error.response.status}] ${JSON.stringify(error.response.data)}`, {
        host: window.location.hostname,
        path: window.location.pathname,
        api_path: error?.response?.config?.url,
        method: error?.response?.config?.method,
        status_code: error?.response?.status
      });
    }

    if (error?.code === ErrorCodeEnum.ERR_NETWORK) {
      return Promise.reject(error);
    }

    if (
      error.response?.data?.statusCode === HttpStatusCode.UNAUTHORIZED
    ) {
      forceLogOut();
    } else if ([HttpStatusCode.REQUEST_TIMEOUT].includes(code)) {
      window.location.href = `${PageLocation.ERROR}/${code}`;
    }

    return Promise.reject(error);
  }
);

if (isDatadogLogEnabled) {
  axiosInstance.interceptors.request.use((request) => {
    sendLogInfo(`[${request.method?.toUpperCase()}] ${request.url}`, {
      host: window.location.hostname,
      path: window.location.pathname,
      api_path: request?.url || '',
      method: request?.method || '',
      request_body: request?.data
    });
    return request;
  });
}

export const setAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.Authorization = `jwt ${token}`;
  axiosInstancev2.defaults.headers.Authorization = `jwt ${token}`;
};

export default axiosInstance;

export interface AxiosResponse<T> {
  data?: T;
  status: number;
}

export interface CreateInboxResponse {
  channelType: string;
  inboxId: string;
  message: string;
}
