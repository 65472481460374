export enum URLPathEnum {
  DASHBOARD = 'dashboard',
  INBOXES = 'inboxes',
  ANALYTICS = 'analytics',
  TEAM = 'team',
  USER = 'user',
  AUDIT_TRAIL = 'audit-trail'
}

export const inboxDashboard = (params: { inboxId: string }, v2: boolean = false) => {
  const path = `/${URLPathEnum.DASHBOARD}/${URLPathEnum.INBOXES}/${params.inboxId}`;
  if (v2) return '/v2' + path;
  return path;
};

export const analyticsDashboard = (params: { inboxId: string }) => {
  const path = `/${URLPathEnum.DASHBOARD}/${URLPathEnum.ANALYTICS}/${params.inboxId}`;
  return path;
};

export const auditTrailDashboard = (v2: boolean = false) => {
  const path = `/${URLPathEnum.DASHBOARD}/${URLPathEnum.AUDIT_TRAIL}/`;
  if (v2) return '/v2' + path;
  return path;
};

export const teamDashboard = () => {
  const path = `/${URLPathEnum.DASHBOARD}/${URLPathEnum.TEAM}`;
  return path;
};

export const userDashboard = () => {
  const path = `/${URLPathEnum.DASHBOARD}/${URLPathEnum.USER}`;
  return path;
};

export const contactConversationHistory = ({
  v2 = false,
  inboxId,
  conversationId,
  contactId
}: {
  v2?: boolean;
  inboxId: string;
  conversationId: string;
  contactId: string;
}) => {
  const path = `/${URLPathEnum.DASHBOARD}/${URLPathEnum.INBOXES}/${inboxId}/conversations/conversation-history/?conversationId=${conversationId}&contactId=${contactId}`;
  if (v2) return '/v2' + path;
  return path;
};
