import { Col, Flex, Form, Switch } from 'antd';
import { ToggleBtnInterface } from '../../../shared/types/inbox.interface';
import CustomSmallTooltip from '../../form/inbox/custom-small-tooltip';
import { Paragraph } from '@squantumengine/horizon';

function ToggleBtn(props: ToggleBtnInterface) {
  const { title, event, value, formInstance, tooltip, disabled } = props;

  return (
    <>
      <Col span={20} md={18} xl={20}>
        <Flex justify="space-between mr-4">
          <Paragraph>{value}</Paragraph>
          {!!tooltip && <CustomSmallTooltip text={tooltip} />}
        </Flex>
      </Col>
      <Col span={4} md={6} xl={4}>
        <Form.Item name={title} style={{ display: 'inline' }} required>
          <Switch
            checked={formInstance.getFieldValue(title!)}
            className={`${
              formInstance.getFieldValue(title!) ? 'bg-green-emerald' : 'bg-grey-50'
            } h-6 w-6`}
            onChange={event}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </>
  );
}

export default ToggleBtn;
