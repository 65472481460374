import { useEffect, useMemo, useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Col, Row, Button } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ChatBox from '../../../../components/v2/card/chatbox/conversation';
import { useCustomerDetails } from '../../../../api/hooks/customer/useCustomerDetails';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useChatListStore } from '../../../../store/chat-list';
import { ConversationInterface } from '../../../../components/card/chat-message/chat-message.interface';
import { InboxListItemInterface } from '../../../../shared/types/inbox.interface';
import { pageLimit } from '../../../../utils/pageLimit';
import { fetchHistoryChat } from '../../../../api/conversation';
import { CurrentUserObjectInterface } from '../../../../shared/types/user.interface';
import { RoleEnum } from '../../../../static/role';
import { fetchInboxByAgent, fetchInboxList } from '../../../../api/inbox';
import { Header } from '@squantumengine/horizon';
import ConversationDetailWorkspace from '../../../../components/v2/workspace/conversation-detail';

import { inboxDashboard } from '../../../../static/routeConstructor';
import ConversationItem from './conversation-item';
import { FetchedConversationInterface } from '../../../../utils/webSocket/websocket-interfaces';

const ConversationHistory = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<CurrentUserObjectInterface>();
  const { inboxId } = useParams();
  const [conversationId, setConversationId] = useState('');
  const [contactId, setContactId] = useState('');
  const [searchParams] = useSearchParams();
  const { setSelectedCall, resetSelectedCall } = useChatListStore();
  const isAgent = currentUser?.role === RoleEnum.AGENT;
  const { data: inboxList } = useQuery(
    ['inboxList', currentUser],
    async () => {
      const accountId = currentUser?.account?.accountId;
      return isAgent
        ? fetchInboxByAgent(accountId!, currentUser?.agentId!)
        : fetchInboxList(accountId!);
    },
    { enabled: !!currentUser, select: (data) => data?.inboxList || [] }
  );
  const currentInbox = inboxList?.find(
    ({ inboxId: id }) => id === inboxId
  ) as InboxListItemInterface;

  const {
    data: conversationData,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery<FetchedConversationInterface[]>(
    ['useHistory', contactId],
    async ({ pageParam = 1 }) => {
      const res = await fetchHistoryChat(String(contactId), {
        'page-no': pageParam,
        active: false,
        'rating-required': true
      });
      const conversations = res?.data?.data || [];
      return conversations;
    },
    {
      retry: false,
      enabled: !!contactId,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = lastPage?.length === pageLimit ? allPages?.length + 1 : undefined;
        return nextPage;
      }
    }
  );

  const conversationList = useMemo(() => {
    return conversationData?.pages?.reduce((acc, curr) => [...acc, ...curr], []);
  }, [conversationData]);

  const isEmptyConversationList = !isLoading && conversationList?.length === 0;

  const { data: customerDetail } = useCustomerDetails(contactId, !!contactId);

  const handleClickConversation = ({ conversationId, inbox, agent }: any) => {
    setConversationId(conversationId!!);
    const selectedCall = {
      agent,
      conversationId,
      inboxId: inbox?.inboxId,
      inboxConfig: inbox?.inboxConfig,
      contactId
    } as ConversationInterface;
    setSelectedCall(selectedCall);
  };

  useEffect(() => {
    setConversationId(searchParams.get('conversationId') || '');
    setContactId(searchParams.get('contactId') || '');
  }, [searchParams]);

  useEffect(() => {
    if (currentInbox) {
      const selectedCall = {
        conversationId,
        contactId,
        inboxId,
        inboxConfig: currentInbox.inboxConfig
      } as unknown as ConversationInterface;
      setSelectedCall(selectedCall);
    }
    //eslint-disable-next-line
  }, [currentInbox]);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('user') || '{}');
    setCurrentUser(currentUser);
  }, []);

  return (
    <div>
      <Row className="flex h-[calc(100vh-64px)] flex-wrap">
        <Col span={16} className="flex h-full flex-col space-y-2 p-4">
          <Header
            className="p-0 [&>div]:space-x-4"
            background="none"
            onBack={() => {
              resetSelectedCall();
              navigate(inboxDashboard({ inboxId: currentInbox?.inboxId }, true));
            }}
            title={`Riwayat Percakapan: ${customerDetail?.name || ''}`}
          />
          <div className="scrollbar-main flex w-full items-center space-x-4 overflow-x-scroll pb-4">
            {conversationList?.map((item) => (
              <ConversationItem
                onClick={handleClickConversation}
                isActive={item.conversationId === conversationId}
                conversation={item}
              />
            ))}
            {hasNextPage && !isEmptyConversationList && (
              <Button
                icon={<RightOutlined className="text-green-emerald" />}
                className="flex items-center justify-center border border-green-emerald p-1"
                onClick={() => fetchNextPage()}
              />
            )}
          </div>
          {/* Chatbox */}
          <div className="flex-1 overflow-hidden rounded-xl border border-solid border-gray-300">
            <ChatBox showHeading={false} showCustomerAction={false} />
          </div>
        </Col>
        <Col
          span={8}
          className="flex h-full flex-col overflow-y-scroll border-b-0 border-l border-r-0 border-t-0 border-solid border-grey-50 bg-white pb-16">
          <ConversationDetailWorkspace />
        </Col>
      </Row>
    </div>
  );
};

export default ConversationHistory;
