import { useQuery } from 'react-query';
import axiosInstance from '../../../config/axios';
import { getAccountId } from '../../../utils/getAccountId';
import { InboxListInterface } from '../../../shared/types/inbox.interface';

export const getTeamList: (accountId: string) => Promise<InboxListInterface> = async (
  accountId: string
) => {
  const response = await axiosInstance.get(`account/${accountId}/inboxes`);
  return response?.data;
};

export const useGetTeamList = () => {
  const accountId = getAccountId();

  return useQuery(['get-teams'], () => getTeamList(accountId));
};
