import { Flex, Modal } from 'antd';
import { Button, Icon, Paragraph, Title } from '@squantumengine/horizon';
import { useState } from 'react';
import { ChooseResultInterface } from './kyc.interface';
import { useChatListStore } from '../../../store/chat-list';
import { ConversationStatusEnum } from '../../../static/message-event';

function ChooseResult(item: ChooseResultInterface) {
  const { data, name, isKYCSubmitted, setData } = item;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedCall } = useChatListStore();

  const isPrecloseOrResolved = [
    ConversationStatusEnum.PRE_CLOSURE,
    ConversationStatusEnum.RESOLVED
  ].includes(selectedCall?.conversationStatus as ConversationStatusEnum);

  const shouldShowNoImage = !data?.documentUrl && isPrecloseOrResolved;

  return (
    <>
      {data && (
        <Flex vertical gap={12} data-testid="choose-result">
          <Flex
            align="center"
            gap={12}
            className="w-full overflow-hidden rounded-lg border border-solid border-neutral-400 bg-neutral-100 pr-[14px]">
            {shouldShowNoImage ? (
              <Paragraph> No Image</Paragraph>
            ) : (
              <>
                <img
                  src={data?.documentUrl}
                  alt="choose-item"
                  className="h-[48px] w-[48px] object-cover"
                />
                <Paragraph className="flex-1 text-neutral-950">{name}</Paragraph>
                <div data-testid="button-expand" className="flex cursor-pointer items-center">
                  {isKYCSubmitted ? (
                    <Button variant="text" className="p-0" onClick={() => setIsModalOpen(true)}>
                      <Icon name="expand" />
                    </Button>
                  ) : (
                    <Button variant="text" className="p-0" onClick={() => setData && setData()}>
                      <Icon name="close-small" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </Flex>
          <Modal
            title={
              <Flex align="center" gap={12}>
                <div
                  data-testid="button-close"
                  className="flex cursor-pointer items-center"
                  onClick={() => setIsModalOpen(false)}>
                  <Icon name="close-large"></Icon>
                </div>
                <Title level={5}>Foto {name}</Title>
              </Flex>
            }
            open={isModalOpen}
            footer={null}
            closable={false}
            onCancel={() => setIsModalOpen(false)}>
            <img src={data?.documentUrl} alt="choose-item" className="w-full object-cover" />
          </Modal>
        </Flex>
      )}
    </>
  );
}

export default ChooseResult;
