import React from 'react';
import { Button, Typography } from '@squantumengine/horizon';
import { Flex } from 'antd';
import { InboxMemberListWithCapacityInterface } from '../../../../../shared/types/inbox.interface';
import { generateAvatarImage } from '../../../../../utils/fullNameParse';

const { Paragraph } = Typography;

interface Props {
  agent: InboxMemberListWithCapacityInterface | null; // Add null safety to the agent prop
  onAssign: () => void;
}

export enum AgentStatusEnum {
  ONLINE = 'Online',
  REST = 'Istirahat',
  ON_CALL = 'Sedang dalam panggilan',
  LIVE_CHAT = 'Live'
}

const getAgentStatus = (agent: InboxMemberListWithCapacityInterface | null) => {
  if (!agent) {
    return {
      agentStatus: AgentStatusEnum.REST,
      agentStatusColor: 'text-base text-grey-100',
      disabled: true
    };
  }

  const capacity = agent.capacity || {};
  const {
    maxChatCapacity = 0,
    onGoingChatCapacity = 0,
    maxCallCapacity = 0,
    onGoingCallCapacity = 0
  } = capacity;

  const available = agent?.available || false;

  if (!available) {
    return {
      agentStatus: AgentStatusEnum.REST,
      agentStatusColor: 'text-base text-grey-100',
      disabled: true
    };
  }

  if (maxCallCapacity && onGoingCallCapacity >= maxCallCapacity) {
    return {
      agentStatus: AgentStatusEnum.ON_CALL,
      agentStatusColor: 'text-base text-grey-100',
      disabled: true
    };
  }

  if (maxChatCapacity && onGoingChatCapacity === 0) {
    return {
      agentStatus: AgentStatusEnum.ONLINE,
      agentStatusColor: 'text-green-500 font-semibold',
      disabled: false
    };
  }

  return {
    agentStatus: AgentStatusEnum.LIVE_CHAT,
    agentStatusColor: '',
    disabled: maxChatCapacity <= onGoingChatCapacity,
    chatInfo: (
      <Flex align="center" gap={6}>
        <div className="h-2 w-2 rounded-full bg-green-500" />
        <Paragraph className="text-base text-grey-100">
          <span className="font-semibold text-green-500">Online</span> | Live chat dengan&nbsp;
          {onGoingChatCapacity} pelanggan
        </Paragraph>
      </Flex>
    )
  };
};

const AgentCardV2: React.FC<Props> = ({ agent, onAssign }) => {
  const agentName = agent
    ? `${agent.firstName ?? ''} ${agent.lastName ?? ''}`.trim()
    : 'Unknown Agent'; // Handle null or missing names

  const { agentStatus, agentStatusColor, disabled, chatInfo } = getAgentStatus(agent);

  return (
    <Flex
      justify="space-between"
      align="center"
      className="box-border w-full rounded-md bg-neutral-50 p-3">
      <Flex gap={10} align="center" justify="center">
        <img
          className="rounded-full border-2 border-solid border-grey-50"
          src={generateAvatarImage(agentName)}
          alt="agent-profile"
        />
        <Flex vertical gap={2}>
          <Paragraph className="text-lg font-semibold text-black-90">{agentName}</Paragraph>
          <Flex align="center" gap={6}>
            {agentStatus === AgentStatusEnum.LIVE_CHAT ? (
              chatInfo
            ) : (
              <>
                {agentStatus === AgentStatusEnum.ONLINE && (
                  <div className="h-2 w-2 rounded-full bg-green-500" />
                )}
                <Paragraph className={`${agentStatusColor} text-base`}>{agentStatus}</Paragraph>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Button disabled={disabled} onClick={onAssign} variant="secondary" size="lg">
        Tugaskan Agen
      </Button>
    </Flex>
  );
};

export default AgentCardV2;
