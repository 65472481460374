import { Flex, Modal } from 'antd';
import { ScreenshotModalInterface, ModeTypeEnum } from './kyc.interface';
import dataURLtoFile from '../../../utils/dataUrltoFile';
import { DocumentInterface } from '../../form/workspace/workspace.interface';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { Button, Paragraph, Title } from '@squantumengine/horizon';

function ScreenshotModal(props: ScreenshotModalInterface) {
  const {
    open,
    handleOk,
    handleCancel,
    confirmLoading,
    canvasRefP,
    canvasRefL,
    setFileKTP,
    setFileSelfie,
    img,
    type
  } = props;

  const { setChooseKTP, setChooseSelfie } = useSelectedCustomerStore();

  const isKTPMode = type === ModeTypeEnum.KTP;

  const onChooseKTP = () => {
    let file = dataURLtoFile(img!?.src, 'ktp.png');
    setFileKTP(file);
    let selectedSS: DocumentInterface = {
      documentId: file.lastModified.toString(),
      documentName: file.name,
      fileSize: file.size,
      documentUrl: img!?.src
    };

    setChooseKTP(selectedSS);
    handleOk();
  };

  const onChooseSelfie = () => {
    let file = dataURLtoFile(img!?.src, 'selfie.png');
    setFileSelfie(file);
    let selectedSS: DocumentInterface = {
      documentId: file.lastModified.toString(),
      documentName: file.name,
      fileSize: file.size,
      documentUrl: img!?.src
    };

    setChooseSelfie(selectedSS);
    handleOk();
  };

  const onSave = () => {
    isKTPMode ? onChooseKTP() : onChooseSelfie();
  };

  return (
    <Modal
      title={
        <Title level={4} className="m-0 p-0">
          Cek Screenshot {isKTPMode ? ModeTypeEnum.KTP : ModeTypeEnum.SELFIE}
        </Title>
      }
      open={open}
      onOk={handleOk}
      width={600}
      confirmLoading={confirmLoading}
      footer={null}
      forceRender
      centered
      onCancel={handleCancel}>
      <Flex vertical gap={16}>
        <Paragraph>
          Pastikan wajah pelanggan terlihat jelas dan tidak terpotong. Anda bisa ulangi screenshot
          bila belum jelas.
        </Paragraph>
        <Flex>
          <div id="snapshot" className="flex h-80 w-full justify-center rounded-3xl"></div>
          <canvas
            width={640}
            height={480}
            ref={canvasRefL}
            id="capture"
            className="hidden"></canvas>
          <canvas
            width={480}
            height={640}
            ref={canvasRefP}
            id="capture"
            className="hidden"></canvas>
        </Flex>
        <Flex gap={12} className="w-full" justify="end">
          <Button variant="secondary" onClick={handleCancel}>
            Ambil Ulang
          </Button>
          <Button onClick={onSave}>Simpan</Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ScreenshotModal;
