import { useState } from 'react';
import { KYCPageEnum, KYCPageInterface } from './kyc.interface';
import ManualKYC from './kyc-manual';
import { useChatListStore } from '../../../store/chat-list';
import { useGetVerdictKYC } from '../../../api/hooks/kyc/useGetVerdictKYC';
import KYCResponse from './kyc-response';

function KYCPage(props: KYCPageInterface) {
  const { defaultPage = KYCPageEnum.KYC } = props;
  const { selectedCall } = useChatListStore();
  const [page, setPage] = useState<number>(defaultPage);
  const { data: kycVerdict } = useGetVerdictKYC(selectedCall);

  switch (page) {
    case KYCPageEnum.KYC:
      return <ManualKYC setPage={setPage} />;
    case KYCPageEnum.SUBMITTED:
      return <KYCResponse kycVerdict={kycVerdict} />;
    default:
      return <></>;
  }
}

export default KYCPage;
