import { useEffect, useState } from 'react';
import { Popover, Row } from 'antd';
import { getAllActiveAgents } from '../../../../api/inbox';
import { fetchInboxUserList } from '../../../../api';
import { useParams } from 'react-router-dom';
import QueueListItem from './QueueItem';
import useAgentActivity from '../../../../hooks/useAgentActivity';
import { getAccountId } from '../../../../utils/getAccountId';
import './recent-activity.css';
import { useInboxStore } from '../../../../store/new/inbox';
import dayjs from 'dayjs';
import useRealtime from '../../../../hooks/useRealtime';
import { Paragraph, Title, Icon } from '@squantumengine/horizon';
import AgentList from './agent-list';
import useConversation from '../../../../hooks/useConversation';
import { useConversationListStore } from '../../../../store/conversation-list-state';

function RecentActivity() {
  const { agentCapacityList, setAgentList, setAgentCapacityList } = useAgentActivity();
  const [onlineAgentPopover, setOnlineAgentPopover] = useState<boolean>(false);
  const [queuePopover, setQueuePopover] = useState<boolean>(false);
  const { selectedInboxId, getSelectedInbox } = useInboxStore();
  const { currentTime } = useRealtime();
  const { recentActivity } = useConversationListStore();

  const { inboxId: inboxIdParam } = useParams();
  const inboxId = inboxIdParam || selectedInboxId;
  const accountId = getAccountId();

  const filter = {
    email: '',
    endDate: '',
    name: '',
    phoneNumber: '',
    startDate: '',
    ticketNumber: ''
  };

  useConversation({
    filter,
    active: true,
    page: 1,
    limit: 1000,
    isRecentActivity: true
  });

  const filteredCreated = recentActivity
    ? recentActivity.filter((data) => data.inboxId === inboxId)
    : [];

  const waitingList = () => (
    <div className="flex max-h-44 flex-col gap-2 overflow-y-scroll">
      {filteredCreated.map(({ id, name, time }) => (
        <QueueListItem key={id} customerName={name} startTimer={time} />
      ))}
    </div>
  );

  const initialize = async () => {
    try {
      const resActiveAgent = await getAllActiveAgents(accountId);
      const resInboxUserList = await fetchInboxUserList(accountId, inboxId!);
      const allAgentInInbox = resInboxUserList?.inboxMembersList;
      const currentAgentList =
        allAgentInInbox?.filter((agent) =>
          resActiveAgent?.agentList?.find((a: any) => a.agentId === agent.agentId)
        ) || [];
      setAgentList(allAgentInInbox || []);
      setAgentCapacityList(currentAgentList);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    if (inboxId) initialize();
  }, [inboxId]);

  return (
    <Row className="flex h-max w-full items-center rounded-lg bg-neutral-50 p-4">
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col space-y-1">
            <Title level={6}>{getSelectedInbox()?.name}</Title>
            <Paragraph size="r">
              {dayjs(currentTime).locale('id-ID').format('dddd, DD MMM YYYY - HH:mm')}
            </Paragraph>
          </div>
        </div>
        <div className="flex flex-row gap-6">
          <Popover
            zIndex={9}
            overlayClassName="recent-activity-popover"
            title={
              <Title level={6} className="mb-4">
                Agen Online
              </Title>
            }
            content={AgentList}
            arrow={false}
            placement="bottom"
            open={onlineAgentPopover}
            onOpenChange={setOnlineAgentPopover}>
            <div className="flex cursor-pointer flex-col space-y-1">
              <Paragraph size="r" weight="bold">
                Agen Online
              </Paragraph>
              <div className="flex flex-row items-center gap-1">
                <Paragraph size="l" weight="bold" className="text-green-500">
                  {agentCapacityList?.length ?? 0}
                </Paragraph>
                <Icon name="chevron-small-right" />
              </div>
            </div>
          </Popover>
          <Popover
            zIndex={9}
            overlayClassName="recent-activity-popover"
            title={
              <Title level={6} className="mb-4">
                Pelanggan Belum Terjawab
              </Title>
            }
            content={waitingList}
            placement="bottom"
            arrow={false}
            open={queuePopover}
            onOpenChange={setQueuePopover}>
            <div className="flex cursor-pointer flex-col space-y-1">
              <Paragraph size="r" weight="bold">
                Belum Terjawab
              </Paragraph>
              <div className="flex flex-row items-center gap-1">
                <Paragraph size="l" weight="bold" className="text-red-500">
                  {filteredCreated?.length}
                </Paragraph>
                <Icon name="chevron-small-right" />
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </Row>
  );
}

export default RecentActivity;
