import { create } from 'zustand';
import { ConversationListInterface, ConversationType } from './conversation-list-state.interface';
import { ConversationInterface } from '../components/card/chat-message/chat-message.interface';
import { getConversationListKey } from './conversation-list.helper';

const overwriteConversationList = (
  stateKey: keyof ConversationListInterface,
  data: ConversationInterface[]
) => {
  return { [stateKey]: data };
};

const handleEditConversationList =
  (
    key: ConversationType,
    conversationList: ConversationInterface[],
    overwriteData: boolean,
    addToFront?: boolean
  ) =>
  (state: ConversationListInterface) => {
    const stateKey = getConversationListKey(key);

    if (overwriteData) {
      return overwriteConversationList(stateKey, conversationList);
    }

    const existingData = state[stateKey] as ConversationInterface[];

    const removeDuplicate: ConversationInterface[] = [];

    conversationList.forEach((item) => {
      const isFound = existingData.some((existingItem) => existingItem.id === item.id);
      if (!isFound) {
        removeDuplicate.push(item);
      }
    });

    return {
      [stateKey]: addToFront
        ? [...removeDuplicate, ...existingData]
        : [...existingData, ...removeDuplicate]
    };
  };

const handleRemoveConversation =
  (key: ConversationType, conversationId: string) => (state: ConversationListInterface) => {
    const stateKey = getConversationListKey(key);
    const existingData = state[stateKey] as ConversationInterface[];
    const filteredData = existingData.filter((conversation) => conversation.id !== conversationId);

    return { [stateKey]: filteredData };
  };

const handleLastReplyConversation =
  (conversationId: string, timestamp: number) => (state: ConversationListInterface) => {
    const lastReplyConversation = { ...state.lastReplyConversation };
    if (!conversationId || !timestamp) return state;
    lastReplyConversation[conversationId] = timestamp;
    return { lastReplyConversation };
  };

const handleAnswerConversation =
  (conversationId: string, timestamp: string) => (state: ConversationListInterface) => {
    const assignedConversationList = [...state.assignedConversationList];
    const assignedConversationIndex = assignedConversationList.findIndex(
      (conversation: any) => conversation.conversationId === conversationId
    );
    if (assignedConversationIndex !== -1) {
      assignedConversationList[assignedConversationIndex].jawabAt = timestamp;
      return { assignedConversationList };
    }
    const activeConversationList = [...state.activeConversationList];
    const activeConversationIndex = activeConversationList.findIndex(
      (conversation: any) => conversation.conversationId === conversationId
    );
    if (activeConversationIndex !== -1) {
      activeConversationList[activeConversationIndex].jawabAt = timestamp;
      return { activeConversationList };
    }
    return state;
  };

export const useConversationListStore = create<ConversationListInterface>((set, get) => ({
  createdConversationList: [],
  assignedConversationList: [],
  activeConversationList: [],
  preClosureConversationList: [],
  closedConversationList: [],
  lastReplyConversation: {},
  bannerNotificationList: [],
  recentActivity: [],
  shouldRefetchConv: false,
  appendBannerNotifiactionlist: (bannerNotificationList: ConversationInterface) =>
    set((state) => ({
      bannerNotificationList: [...state.bannerNotificationList, bannerNotificationList]
    })),
  removeBannerNotificationlist: (bannerNotificationList: ConversationInterface) =>
    set((state) => ({
      bannerNotificationList: state.bannerNotificationList.filter(
        (item) => item.id !== bannerNotificationList.id
      )
    })),

  clearConversationList: () =>
    set({
      createdConversationList: [],
      assignedConversationList: [],
      activeConversationList: [],
      preClosureConversationList: [],
      closedConversationList: []
    }),
  clearSPVConversation: () =>
    set({
      createdConversationList: [],
      activeConversationList: [],
      assignedConversationList: []
    }),

  setConversationList: (key, conversationList) =>
    set(handleEditConversationList(key, conversationList, true)),
  appendConversationList: (key, conversationList, addToFront) =>
    set(handleEditConversationList(key, conversationList, false, addToFront)),
  removeConversation: (key, conversationId) => set(handleRemoveConversation(key, conversationId)),
  moveConversation: (from, to, conversation) => {
    get().removeConversation(from, conversation.id);
    get().appendConversationList(to, [conversation], true);
  },
  updateLastReplyConversation: (conversationId: string, timestamp: number) =>
    set(handleLastReplyConversation(conversationId, timestamp)),
  setShouldRefetchConv: (shouldRefetchConv: boolean) => set({ shouldRefetchConv }),
  updateAnswerConversation: (conversationId: string, timestamp: string) =>
    set(handleAnswerConversation(conversationId, timestamp))
}));
