import {
  CommunicationTypeEnum,
  defaultCommunicationTypeEnum
} from '../../../../static/communication-type';
import { FetchedConversationInterface } from '../../../../utils/webSocket/websocket-interfaces';
import { _renderRating } from '../../../../static/analysis';
import dayjs from 'dayjs';
import { formatSecondToHHMMSS } from '../../../../utils/formatter';
import { Icon } from '@squantumengine/horizon';

type Props = {
  isActive?: boolean;
  conversation: FetchedConversationInterface;
  onClick: (conversation: FetchedConversationInterface) => void;
};

const ConversationItem = (props: Props) => {
  const { conversation, isActive, onClick } = props;
  const date = dayjs(conversation.createdAt).format('DD MMM YYYY');
  const duration = formatSecondToHHMMSS(
    Math.round(
      (new Date(conversation.endTime!).getTime() - new Date(conversation.startTime!).getTime()) /
        1000
    ) || 0
  );

  const getCommunicationType = () => {
    const inboxCommunicationType =
      conversation?.inbox?.inboxConfig?.mediaCallConfiguration?.defaultCommunicationType;
    const isVoiceCall = [
      defaultCommunicationTypeEnum.AUDIO,
      defaultCommunicationTypeEnum.AUDIO_VIDEO
    ].includes(inboxCommunicationType as defaultCommunicationTypeEnum);
    const withVideoCall = conversation?.metadata?.communicationType === CommunicationTypeEnum.VIDEO;

    return {
      isVoiceCall,
      isVideoCall: !isVoiceCall || (isVoiceCall && withVideoCall),
      isChat: conversation?.communicationType === CommunicationTypeEnum.CHAT
    };
  };

  const getConversationIcon = () => {
    const { isVoiceCall, isVideoCall, isChat } = getCommunicationType();
    switch (true) {
      case isChat:
        return <Icon name="comment" />;
      case isVideoCall:
        return <Icon name="video" />;
      case isVoiceCall:
        return <Icon name="phone" />;
      default:
        return <Icon name="comment" />;
    }
  };

  return (
    <div
      className={`min-w-[200px] cursor-pointer space-y-2 rounded-2xl border-solid bg-white p-4 hover:border-blue-500 ${
        isActive ? 'border-1 border-blue-500' : 'border border-gray-400'
      }`}
      onClick={onClick?.bind(this, conversation)}>
      <div className="flex text-xs font-bold">
        <div className="!w-[68px] truncate">#{conversation?.conversationId}</div>
        <div>&#8226;</div>
        <div>{date}</div>
      </div>
      <div className="flex items-center space-x-2 text-sm">
        <span>{duration}</span>
        <span className="[&>*]:!inline">Rating: {_renderRating(conversation?.rating || 0)}</span>
        <span className="flex items-center space-x-1">{getConversationIcon()}</span>
      </div>
    </div>
  );
};

export default ConversationItem;
