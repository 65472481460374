import { useMemo } from 'react';
import { useChatListStore } from '../../../../store/chat-list';
import { Paragraph, Skeleton } from '@squantumengine/horizon';
import { useWorkspaceSummary } from '../../../../api/hooks/workspace/useWorkspaceSummary';

const FIELDS_SUMMARY = [
  ['agentName', 'Agen yang mengirim ringkasan'],
  ['topic', 'Topik'],
  ['note', 'Catatan']
];

const CustomerSummary = () => {
  const { selectedCall, selectedOfflineChat } = useChatListStore();
  const { data: summary, isLoading: loadingSummary } = useWorkspaceSummary(
    selectedCall?.conversationId || '',
    !!selectedCall?.conversationId,
    selectedCall
  );

  // eslint-disable-next-line no-console
  const currentSummary = useMemo(
    () => ({ ...summary, agentName: selectedCall?.agent?.firstName || '-' }),
    [summary, selectedOfflineChat, selectedCall]
  );

  return (
    <div className="max-h-[380px] overflow-y-auto">
      <div className="space-y-4">
        {FIELDS_SUMMARY.map(([key, label]) => {
          // @ts-ignore: unparseable key
          const value = (currentSummary && currentSummary[key]) || '-';
          return (
            <div key={key} className="min-h-[24px] w-full">
              <Paragraph className="text-neutral-600">{label}</Paragraph>
              <Paragraph className="truncate-5-lines text-neutral-950">
                {(loadingSummary && <Skeleton size="sm" />) || value}
              </Paragraph>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomerSummary;
