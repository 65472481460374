import { Modal, message } from 'antd';
import { AssignAgentDataInterface, AssignAgentModalProps } from './assign-agent-modal.interface';
import { getIconUrl } from '../../../utils/getIconUrl';
import { AssignAgentModalContent } from './modal-content';
import { useInboxUserList } from '../../../api/hooks/inbox/useInboxUserList';
import { InboxMemberListWithCapacityInterface } from '../../../shared/types/inbox.interface';
import { AgentInterface } from '../../card/chat-message/chat-message.interface';
import { CommunicationTypeEnum } from '../../../static/communication-type';
import { CurrentUserObjectInterface } from '../../../shared/types/user.interface';
import { useMutation } from 'react-query';
import {
  assignAgentToConversation,
  getAgentMaxChat,
  unassignAgentFromConversation
} from '../../../api/user';

const AssignAgentModal = (props: AssignAgentModalProps) => {
  const { conversation, isOpen, onClose } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const inboxId = conversation?.inboxId as string;
  const { data: agents, isLoading, refetch: refetchMember } = useInboxUserList(inboxId, isOpen);
  const assignMutation = useMutation(assignAgentToConversation, {
    onSuccess: () => {
      refetchMember();
      messageApi.open({
        type: 'success',
        content: 'Agent telah dihubungkan dengan pelanggan.'
      });
    },
    onError(error: any) {
      showError(error.response.data.message || 'Manual assign gagal');
      refetchMember();
    }
  });
  const unAssignMutation = useMutation(unassignAgentFromConversation, {
    onSuccess: (data, variables: AssignAgentDataInterface) => {
      assignMutation.mutate(variables);
      messageApi.open({
        type: 'info',
        content: 'Berhasil meng-unassign agent sebelumnya.'
      });
    }
  });
  const assignAgent = async (
    conversationId: string,
    agent: AgentInterface,
    communicationType: CommunicationTypeEnum,
    isUnassign?: boolean
  ) => {
    const currentUser: CurrentUserObjectInterface = JSON.parse(
      localStorage.getItem('user') || '{}'
    );
    const accountId: string = currentUser.account?.accountId || '';
    const agentId = agent.id;
    const data: AssignAgentDataInterface = {
      accountId,
      conversationId,
      agentId,
      communicationType
    };

    const isAssignable = await checkIsAgentAvailable(agent.id);
    if (!isAssignable) return;
    onClose();
    isUnassign ? unAssignMutation.mutate(data) : assignMutation.mutate(data);
  };
  if (!conversation) return null;

  const showError = (message: string) => {
    messageApi.open({
      type: 'error',
      content: message
    });
  };
  const checkIsAgentAvailable = async (agentId: string) => {
    try {
      const { available, capacity } = await getAgentMaxChat(agentId);
      if (!available) {
        showError('Agent baru saja mulai beristirahat.');
        return false;
      }

      const { onGoingChatCapacity, maxChatCapacity, onGoingCallCapacity, maxCallCapacity } =
        capacity;

      if (onGoingChatCapacity >= maxChatCapacity || !maxChatCapacity) {
        showError('Agent tidak memiliki kapasitas untuk menerima percakapan.');
        return false;
      }

      if (onGoingCallCapacity >= maxCallCapacity || !maxCallCapacity) {
        showError(
          'Agent sedang dalam panggilan atau tidak memiliki kapasitas untuk menerima panggilan.'
        );
        return false;
      }

      return true;
    } catch (err: any) {
      showError(
        `Gagal memeriksa status agen: ${
          err.response?.data?.message || 'Kesalahan tidak diketahui.'
        } `
      );
      return false;
    }
  };
  return (
    <>
      {contextHolder}
      <Modal centered open={isOpen} footer={null} closable={false} width={900}>
        <div className="relative flex items-center justify-between gap-8">
          <p className="my-0 flex-1 text-2xl font-bold">Agen Yang Saat Ini Tersedia</p>
          <span className="cursor-pointer p-1" onClick={onClose}>
            <img src={getIconUrl('times.svg')} alt="times-icon" />
          </span>
        </div>
        <div className="mt-6 flex max-h-[600px] flex-col gap-4 overflow-y-auto">
          <AssignAgentModalContent
            agents={agents?.inboxMembersList as InboxMemberListWithCapacityInterface[]}
            conversation={conversation}
            isLoading={isLoading}
            onAssign={assignAgent}
          />
        </div>
      </Modal>
    </>
  );
};

export default AssignAgentModal;
