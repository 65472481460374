import { Alert, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { HistoryCardProps } from '../../../form/workspace-customer-files/history/workspace-customer-files-history.interface';
import { useChatListStore } from '../../../../store/chat-list';
import { fetchHistoryChat } from '../../../../api/conversation';
import { pageLimit } from '../../../../utils/pageLimit';
import HistoryItem from './history-item';
import { ConversationStatusEnum } from '../../../../static/message-event';
import { useInfiniteQuery } from 'react-query';
import { Button, Paragraph, SearchBar } from '@squantumengine/horizon';

function HistoryList() {
  const [search, setSearch] = useState('');
  const { selectedCall } = useChatListStore();
  const [currentContactId, setCurrentContactId] = useState('');
  const { data, isLoading, hasNextPage, isFetchingNextPage, isFetching, isError, fetchNextPage } =
    useInfiniteQuery(
      ['useHistory', currentContactId],
      async ({ pageParam = 1, queryKey: [_, contactId] }) => {
        const res = await fetchHistoryChat(contactId as string, {
          'page-no': pageParam,
          active: false,
          'rating-required': true
        });
        const historyItems = (res.data.data || []) as HistoryCardProps[];
        return historyItems;
      },
      {
        retry: false,
        enabled: !!currentContactId,
        getNextPageParam: (lastPage, allPages) => {
          const nextPage = lastPage?.length === pageLimit ? allPages?.length + 1 : undefined;
          return nextPage;
        }
      }
    );

  const handleSearch = (e: any) => setSearch(e);

  const historyList = useMemo(() => {
    return data?.pages?.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.filter(
          ({ conversationStatus }) => conversationStatus === ConversationStatusEnum.RESOLVED
        )
      ],
      []
    );
  }, [data]);

  const historyItems = useMemo(() => {
    if (!search) return historyList;
    return historyList?.filter(
      (item: HistoryCardProps) =>
        item.conversationId.includes(search) ||
        `${item?.agent?.firstName} ${item?.agent?.lastName}`
          .toLowerCase()
          .includes(search?.toLowerCase())
    );
  }, [historyList, search]);

  const isEmpty = !isLoading && (!historyList || historyList?.length === 0);
  const isSearchEmpty = historyItems?.length === 0 && search;
  const isFirstLoading = isFetching && !isFetchingNextPage;

  useEffect(() => {
    setCurrentContactId(selectedCall?.contactId || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCall?.contactId]);

  return (
    <div>
      {!isEmpty && (
        <SearchBar
          dataTestId="search-bar"
          placeholder="Cari Nama Agen / Tiket ID"
          className="mb-[16px] w-full"
          onChange={handleSearch}
        />
      )}

      {!isFirstLoading &&
        historyItems?.map((item: HistoryCardProps) => (
          <HistoryItem key={item.conversationId} {...item} />
        ))}

      {isEmpty && (
        <Paragraph className="p-4 text-center text-neutral-400" size="l">
          Belum ada riwayat
        </Paragraph>
      )}

      {isSearchEmpty && (
        <Paragraph className="p-4 text-center text-neutral-400" size="l">
          Tidak ditemukan Nama Agen / Tiket ID
        </Paragraph>
      )}

      {(isFirstLoading || (isLoading && hasNextPage)) && (
        <div className="flex items-center justify-center pt-4">
          <Spin />
        </div>
      )}
      {isError && <Alert className="text-center" type="error" message="Gagal memuat data" />}
      {hasNextPage && !isEmpty && (
        <Button size="sm" variant="secondary" full onClick={() => fetchNextPage()}>
          Muat Lebih Banyak
        </Button>
      )}
    </div>
  );
}

export default HistoryList;
