import { useToaster } from '@squantumengine/horizon';
import { stopVideoNotification } from '../../../../../utils/notification';
import { useChatListStore } from '../../../../../store/chat-list';
import { useConversationListStore } from '../../../../../store/conversation-list-state';
import IncomingNotificationItem from './incoming-notification-item';
import { ConversationInterface } from '../../../../../components/card/chat-message/chat-message.interface';
import { useEffect, useState } from 'react';
import { NOTIFICATION_TIMEOUT } from '../../../../../static/dashboard';
import { unionBy } from '../../../../../utils/common';
import { useConversationStore } from '../../../../../store/new/conversation';
import { setMeetingRoomId } from '../../../../../utils/webSocket';
import { ConversationStatusEnum } from '../../../../../static/message-event';
import { useInboxStore } from '../../../../../store/new/inbox';

type Props = {
  handleActiveCall: (conversation: ConversationInterface) => Promise<void>;
};

export default function IncomingNotification({ handleActiveCall }: Props) {
  const [unansweredConversations, setUnansweredConversations] = useState<ConversationInterface[]>(
    []
  );
  const { assignedConversationList, activeConversationList, updateAnswerConversation } =
    useConversationListStore();
  const { answeredConversationMap, setAnsweredConversationMap } = useConversationStore();

  const { clearUnreadMessages } = useChatListStore();
  const { setSelectedInboxId } = useInboxStore();

  const { contextHolder, open, close } = useToaster({
    position: 'top',
    timeout: NOTIFICATION_TIMEOUT
  });

  useEffect(() => {
    const conversations = unionBy(
      assignedConversationList,
      activeConversationList,
      'conversationId'
    );

    setUnansweredConversations((prev) => {
      const currentNotifications = [...prev];
      if (conversations.length > 0) {
        conversations.forEach((item) => {
          item.conversationStatus = ConversationStatusEnum.OPEN;
          const notificationItem = currentNotifications.find(
            (conv) => conv.conversationId === item.conversationId
          );
          const isAnswered =
            !!notificationItem?.jawabAt || answeredConversationMap?.[item.conversationId];
          if (!notificationItem && isAnswered) return;
          if (notificationItem && isAnswered) {
            close(notificationItem.conversationId);
            stopVideoNotification(notificationItem.conversationId);
            currentNotifications.splice(currentNotifications.indexOf(notificationItem), 1);
          } else if (!notificationItem) {
            currentNotifications.push(item);
          }
        });
      }
      return currentNotifications;
    });
  }, [activeConversationList, assignedConversationList]);

  const _makeNotificationMessage = ({ name, isWarning }: { name: string; isWarning: boolean }) => {
    if (isWarning) {
      if (!name) return 'Pelanggan sudah menunggu. Mohon berikan respon segera.';
      return `${name || ''} sudah menunggu. Mohon berikan respon segera.`;
    }
    if (!name) return 'Pelanggan menunggu.';
    return `Pelanggan menunggu: ${name}`;
  };

  const handleClickNotification = (conversation: ConversationInterface) => {
    setSelectedInboxId(conversation.inboxId || '');
    setMeetingRoomId(conversation.meetingRoomId as string);
    handleActiveCall(conversation);
    clearUnreadMessages(conversation.conversationId, conversation?.inboxId!);
    updateAnswerConversation(conversation.conversationId, Date.now().toString());
    setAnsweredConversationMap(conversation.conversationId, Date.now().toString());
    stopVideoNotification(conversation?.conversationId);
    setUnansweredConversations((prev) =>
      prev.map((item) => {
        if (item.conversationId === conversation.conversationId)
          return {
            ...item,
            jawabAt: new Date().toString()
          };
        return item;
      })
    );
  };

  const showDefaultNotification = (conversation: ConversationInterface) => {
    open({
      id: conversation.conversationId,
      label: _makeNotificationMessage({ name: conversation?.name || '', isWarning: false }),
      buttonLabel: 'Buka Sesi',
      onActionClick: () => handleClickNotification(conversation)
    });
  };

  const showWarningNotification = (conversation: ConversationInterface) => {
    open({
      id: `${conversation.conversationId}-warning`,
      label: _makeNotificationMessage({ name: conversation?.name || '', isWarning: true }),
      buttonLabel: 'Buka Sesi',
      onActionClick: () => handleClickNotification(conversation)
    });
  };

  return (
    <div>
      {/* Temporary styling. will update after banner notification improvement deployed */}
      <div className="banner-notification [&>div>div>button]:font-bold [&>div>div>button]:text-sky-400 [&>div>div>p]:flex-1">
        {contextHolder}
      </div>
      {unansweredConversations.map((item) => (
        <IncomingNotificationItem
          key={item.conversationId}
          conversation={item}
          enabled={!item.jawabAt}
          handleShowDefaultNotification={showDefaultNotification}
          handleShowWarningNotification={showWarningNotification}
          handleCloseNotification={close}
        />
      ))}
    </div>
  );
}
