import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Spin, notification } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { ChatFilterEnum, filterOption } from '../../static/chat-filter';

import ChatBox from '../../components/card/chatbox/conversation';
import ChatFilter from '../../components/card/chat-filter';
import AgentWorkspace from '../../components/workspace/agent';
import { useDashboardPage } from '../../hooks/useDashboardPage';
import {
  createCallListComponent,
  createIncomingCallComponents,
  fetchOfflineConversations,
  parseAgentConversation
} from './dashboard.helper';
import { ConversationStatusEnum, MessageEventEnum } from '../../static/message-event';
import ExclamationCircleIcon from '../../assets/icons/exclamation-circle';
import { useCallStore } from '../../store/call-state';
import { ConversationInterface } from '../../components/card/chat-message/chat-message.interface';
import { conversationByAgent } from '../../config/conversation-agent';
import { FetchedConversationInterface } from '../../utils/webSocket/websocket-interfaces';
import { useChatListStore } from '../../store/chat-list';
import { AGENT_CONVERSATION_PAGE_SIZE } from '../../static/dashboard';
import OfflineCard from '../../components/card/offline-card';
import OfflineMessageChatBox from '../../components/card/chatbox/offline-message';
import { getVBG } from '../../api/upload';
import { CurrentUserObjectInterface } from '../../shared/types/user.interface';
import { useConversationListStore } from '../../store/conversation-list-state';
import { setSelectedCallJSON } from '../../utils/user-json';
import { useInboxDetailStore } from '../../store/inbox-state';
import IncomingCallNotification from '../../components/notification/incoming-call';
import SearchFilter from '../../components/filter/search-filter';
import { debounce } from '../../utils/rate-limit';
import { useAgentDashboard } from '../../hooks/new/useAgentDashboard';
import { items } from '../../static/search-filter';
import ConnectionInfo from '../../components/notification/connection-info';

function DashboardAgent() {
  useAgentDashboard();
  const [canLoadInactiveConv, setCanLoadInactiveConv] = useState(false);
  const [canLoadOfflineConv, setCanLoadOfflineConv] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [ticketNumber, setTickeNumber] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [page, setPage] = useState<number>(2);
  const [offlinePage, setOfflinePage] = useState<number>(1);
  const { inboxId } = useInboxDetailStore();

  const {
    selectedCall,
    offlineChatList,
    setOfflineChatList,
    appendOfflineChatList,
    selectedOfflineChat
  } = useChatListStore();

  const { handleActiveCall, handleEndCall } = useDashboardPage();

  const { fullScreen, showChat, setVirtualBackground, isManualKYCMode } = useCallStore();
  const {
    assignedConversationList,
    activeConversationList,
    closedConversationList,
    preClosureConversationList,
    lastReplyConversation,
    appendConversationList,
    clearConversationList
  } = useConversationListStore();
  const filteredAssignedConversationList = assignedConversationList?.filter(
    (item) => item?.inboxId === inboxId
  );
  const [selectedFilter, setSelectedFilter] = useState<ChatFilterEnum>(ChatFilterEnum.ALL);
  const isInitial = useRef(true);

  const getActiveConversations = async () => {
    try {
      const currentTime = Date.now();
      const res = await conversationByAgent(
        true,
        1,
        inboxId,
        email,
        ticketNumber,
        phoneNumber,
        name
      );

      const activeCallList: ConversationInterface[] = [];
      const preClosureCallList: ConversationInterface[] = [];

      res?.data?.forEach((item: FetchedConversationInterface) => {
        const isPreclosure = item.conversationStatus === ConversationStatusEnum.PRE_CLOSURE;
        const event = isPreclosure ? MessageEventEnum.SESSION_CLOSED : MessageEventEnum.ACTIVE;
        const newObject = parseAgentConversation({ item, currentTime, event });
        const targetArray = isPreclosure ? preClosureCallList : activeCallList;
        targetArray.push(newObject);
      });
      appendConversationList(MessageEventEnum.ACTIVE, activeCallList);
      appendConversationList(MessageEventEnum.SESSION_CLOSED, preClosureCallList);
    } catch {
      notification.error({ message: `Gagal memuat percakapan aktif.` });
    }
  };
  const getInactiveConversations = async (currentPage?: number) => {
    try {
      const currentTime = Date.now();
      const res = await conversationByAgent(
        false,
        currentPage && page,
        inboxId,
        email,
        ticketNumber,
        phoneNumber,
        name
      );
      currentPage ? setPage(res.pageNo + 1) : setPage(2);

      const closedCallList: ConversationInterface[] = [];

      res?.data?.forEach((item: FetchedConversationInterface) => {
        const event = MessageEventEnum.INACTIVE;
        const newObject = parseAgentConversation({ item, currentTime, event });
        closedCallList.push(newObject);
      });

      appendConversationList(MessageEventEnum.INACTIVE, closedCallList);

      setCanLoadInactiveConv(res.data.length === AGENT_CONVERSATION_PAGE_SIZE);
    } catch {
      notification.error({ message: `Gagal memuat percakapan tidak aktif.` });
    }
  };

  const getAllConversationList = async () => {
    clearConversationList();
    const promises = [getActiveConversations(), getInactiveConversations()];
    await Promise.all(promises);
  };

  const handleLoadOfflineConv = async () => {
    const { offlineMessages, size, pageSize } = await fetchOfflineConversations(offlinePage);

    if (!offlineMessages?.length) {
      setCanLoadOfflineConv(false);
      return;
    }

    setCanLoadOfflineConv(size === pageSize);
    appendOfflineChatList(offlineMessages);
    setOfflinePage((prev) => prev + 1);
  };

  const fetchVBG = async (accountId: string) => {
    const response = await getVBG(accountId);

    if (!response.documents.length) return;
    setVirtualBackground(response.documents[response.documents.length - 1]?.url);
  };

  useEffect(() => {
    if (inboxId) {
      getAllConversationList();
    }
    if (isInitial.current) {
      isInitial.current = false;
      const currentUser: CurrentUserObjectInterface = JSON.parse(
        localStorage.getItem('user') || '{}'
      );
      if (currentUser?.account?.accountId) {
        fetchVBG(currentUser.account?.accountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxId]);
  useEffect(() => {
    if (inboxId) {
      debounce(getAllConversationList, 500)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, ticketNumber, email, phoneNumber]);
  const handleChangeFilter = (selected: ChatFilterEnum) => {
    if (selected === ChatFilterEnum.OFFLINE) {
      handleLoadOfflineConv();
    } else {
      setOfflinePage(1);
      setOfflineChatList([]);
    }
    setSelectedFilter(selected);
  };

  const isFilterType = (selected: ChatFilterEnum) => selectedFilter === selected;

  const conversationList = useMemo(() => {
    const answeredActiveConversationList = activeConversationList.filter(
      (conv) =>
        !filteredAssignedConversationList.some(
          (assignedConv) => assignedConv.conversationId === conv.conversationId
        )
    );
    const sortedActiveConversation = answeredActiveConversationList.sort(
      (conv1, conv2) =>
        (lastReplyConversation[conv2.conversationId] || 0) -
        (lastReplyConversation[conv1.conversationId] || 0)
    );

    return [...sortedActiveConversation, ...preClosureConversationList, ...closedConversationList];
  }, [
    filteredAssignedConversationList,
    activeConversationList,
    preClosureConversationList,
    closedConversationList,
    lastReplyConversation
  ]);

  useEffect(() => {
    if (activeConversationList.length > 0) {
      setSelectedCallJSON(JSON.stringify(activeConversationList[0]));
    }
  }, [activeConversationList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row className="flex h-[calc(100vh-64px)] flex-wrap gap-4 bg-white p-0">
      <ConnectionInfo />
      <IncomingCallNotification handleActiveCall={handleActiveCall} />
      <Col
        span={7}
        className="mx-2 flex h-[calc(100vh-64px)] flex-col gap-4 overflow-y-auto py-6 pl-2 pr-4">
        <div className="space-y-2">
          <div className="flex flex-wrap items-center justify-between">
            <p className="m-0 text-3xl font-semibold">Percakapan</p>
            <ChatFilter
              filterOption={filterOption}
              active={selectedFilter}
              setActive={handleChangeFilter}
            />
          </div>
          <div className="flex flex-wrap items-center justify-between">
            <SearchFilter
              items={items}
              value={name || ticketNumber || email || phoneNumber}
              setName={setName}
              setTicketNumber={setTickeNumber}
              setEmail={setEmail}
              setPhoneNumber={setPhoneNumber}
            />
          </div>
        </div>

        {(isFilterType(ChatFilterEnum.ALL) || isFilterType(ChatFilterEnum.UNANSWERED)) &&
          Boolean(filteredAssignedConversationList.length) && (
            <Row className="flex flex-col gap-4">
              {createIncomingCallComponents(handleActiveCall)}
            </Row>
          )}
        {(isFilterType(ChatFilterEnum.ALL) || isFilterType(ChatFilterEnum.ANSWERED)) && (
          <InfiniteScroll
            initialLoad={false} // Data for Inactive call page 1 already called on getAllConversationList
            pageStart={1}
            className="w-full space-y-4"
            loadMore={getInactiveConversations}
            hasMore={canLoadInactiveConv}
            loader={<Spin className="flex justify-center" key="scroll-loader" />}
            useWindow={false}>
            <Row className="flex flex-col gap-4" key={'scroll-container'}>
              {createCallListComponent(conversationList)}
            </Row>
          </InfiniteScroll>
        )}
        {isFilterType(ChatFilterEnum.OFFLINE) && offlineChatList.length > 0 && !name && (
          <InfiniteScroll
            className="w-full space-y-4"
            loadMore={handleLoadOfflineConv}
            hasMore={canLoadOfflineConv}
            loader={<Spin className="flex justify-center" />}
            useWindow={false}>
            <Row className="flex flex-col gap-4">
              {offlineChatList.map((conv) => (
                <OfflineCard data={conv} key={conv.messageId} />
              ))}
            </Row>
          </InfiniteScroll>
        )}
      </Col>
      <Col span={9} className="zoom flex h-full flex-[2] flex-col gap-4 py-6">
        {selectedOfflineChat ? <OfflineMessageChatBox /> : <ChatBox onClose={handleEndCall} />}
        <div className="mt-4 flex items-start">
          <ExclamationCircleIcon />
          <p className="my-0 ml-3 text-base text-chat-gray">
            Pelanggan bisa melihat pesan yang Anda kirim di sini
          </p>
        </div>
      </Col>
      <Col
        span={9}
        className={`${fullScreen && !showChat && 'fixed -right-[100%] z-50'} ${
          fullScreen && showChat && 'fixed -right-0 z-50'
        } flex h-[calc(100vh-64px)] w-[25vw] flex-1 flex-col gap-4 overflow-y-auto border-y-0 border-l border-r-0 border-solid border-l-grey-50 bg-white p-4 transition-all`}>
        {!isManualKYCMode && (
          <div className={`${fullScreen ? 'zoom mb-0' : 'hidden'}`}>
            <ChatBox onClose={handleEndCall} withoutVideo={true} />
            <div className="mt-4 flex items-start">
              <ExclamationCircleIcon />
              <p className="my-0  ml-3 text-base text-chat-gray">
                Pelanggan bisa melihat pesan yang Anda kirim di sini
              </p>
            </div>
          </div>
        )}
        {(!!selectedCall || !!selectedOfflineChat) && <AgentWorkspace />}
      </Col>
    </Row>
  );
}

export default DashboardAgent;
