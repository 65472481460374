import { AssignAgentModalContentProps } from '../assign-agent-modal.interface';
import { AgentCard } from '../agent-card/index';
import { InboxMemberListWithCapacityInterface } from '../../../../shared/types/inbox.interface';
import { CommunicationTypeEnum } from '../../../../static/communication-type';

const getAgentPriority = (agent: InboxMemberListWithCapacityInterface) => {
  const { capacity, available } = agent;
  const { maxChatCapacity, onGoingChatCapacity, maxCallCapacity, onGoingCallCapacity } =
    capacity || {};

  if (!available) return -2;
  if (maxCallCapacity && onGoingCallCapacity >= maxCallCapacity) return -1;
  if (!onGoingChatCapacity && maxChatCapacity) return Infinity;
  return maxChatCapacity - onGoingChatCapacity;
};

export const AssignAgentModalContent = (props: AssignAgentModalContentProps) => {
  const { isLoading, agents, onAssign, conversation } = props;

  if (isLoading) {
    return <p className="text-center">Loading...</p>;
  }

  if (!agents?.length) {
    return <p className="text-center">Tidak ada agent yang tersedia.</p>;
  }

  if (agents?.length === 1 && agents[0].agentId === conversation.agent?.id) {
    return (
      <div className="text-center">
        Satu-satunya agent dalam Inbox ini telah di-assign ke dalam percakapan ini.
      </div>
    );
  }

  const isAssigned = !!conversation.agent;

  const sortedAgent = agents.sort((a, b) => getAgentPriority(b) - getAgentPriority(a));
  const filteredAgent = isAssigned
    ? sortedAgent.filter((agent) => conversation.agent?.id !== agent.agentId)
    : sortedAgent;

  return (
    <>
      {filteredAgent.map((agent: InboxMemberListWithCapacityInterface) => (
        <AgentCard
          key={agent.agentId}
          agent={agent}
          onAssign={() =>
            onAssign(
              conversation.conversationId,
              {
                id: agent.agentId,
                firstName: agent.firstName,
                lastName: agent.lastName
              },
              conversation.communicationType as CommunicationTypeEnum,
              isAssigned
            )
          }
        />
      ))}
    </>
  );
};
