import { useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import { useAuth } from '../hooks/useAuth';

import Header from '../components/header';
import Sidebar from '../components/sidebar';
import { PageLocation } from '../types/enums/page';
import { HIDE_FEATURE_PROD } from '../config/app';
import { useSidebarStore } from '../store/new/sidebar';
import HeaderV2 from '../components/v2/header';
import WarningIddleAutoLogout from '../components/modal/warning-logout';

export const ProtectedLayout = () => {
  const location = useLocation();
  const v2 = location.pathname.includes('v2');
  const { isExpanded } = useSidebarStore();

  const { accessToken } = useAuth();
  const { isErrorPage, layoutCls } = useMemo(() => {
    const isErrorPage = location.pathname.includes(PageLocation.ERROR);
    const mlV2 = isExpanded ? 'ml-[268px]' : 'ml-[84px]';
    const ml = v2 ? mlV2 : 'ml-52';
    const bgColor = v2 ? 'bg-[#F9FBFF]' : 'bg-white';
    return {
      isErrorPage,
      layoutCls: isErrorPage ? 'h-full' : `${bgColor} ${ml} mt-16 h-full`
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isExpanded]);

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  if (v2 && HIDE_FEATURE_PROD) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Layout className="bg-white">
      <WarningIddleAutoLogout />
      {v2 ? <HeaderV2 /> : <Header />}
      {!isErrorPage && (
        <Layout className="flex-none">
          <Sidebar />
        </Layout>
      )}
      <Layout className={layoutCls}>
        <Outlet />
      </Layout>
    </Layout>
  );
};
