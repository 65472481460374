/* eslint-disable no-console */

import {
  ROUND_TRIP_TIME_THRESHOLD,
  JITTER_THRESHOLD,
  PACKET_LOSS_THRESHOLD
} from '../static/internetConnection';
import { useConnectionStore } from '../store/new/connection-state';
import { ConnectionStateEnum } from '../store/new/connection-state/connection-state.interface';

export async function updateStreamingScore(
  getTransportStat: (isRecievetransport: boolean) => Promise<any>
) {
  const RTCstatus = await getTransportStat(false);
  let videoConnectionScore = 0;
  let audioConnectionScore = 0;
  RTCstatus.forEach((value: any) => {
    if (value.type === 'remote-inbound-rtp') {
      const mediaType = value.kind; // 'audio' or 'video'
      const roundTripTime = value.roundTripTime;
      const jitter = value.jitter;
      const fractionLost = value.fractionLost;

      console.log('==========================');
      console.log(`${mediaType.charAt(0).toUpperCase() + mediaType.slice(1)} Stream:`);
      console.log(`Round Trip Time: ${roundTripTime} | ${roundTripTime * 1000}ms`);
      console.log(`Jitter: ${jitter} | ${jitter * 1000}ms`);
      console.log(`Fraction Lost: ${fractionLost} | ${(fractionLost * 100).toFixed(2)}%`);

      if (mediaType === 'video') {
        videoConnectionScore = calculateVideoScore(roundTripTime, jitter, fractionLost);
      } else {
        audioConnectionScore = calculateAudioScore(roundTripTime, jitter, fractionLost);
      }
    }
  });
  const finalConnectionScore = calculateStreamScore(audioConnectionScore, videoConnectionScore);
  console.log('++++++++++++++++++++++++++++');
  console.log(`Video Connection Score: ${videoConnectionScore}`);
  console.log(`Audio Connection Score: ${audioConnectionScore}`);
  console.log(`Final Connection Score: ${finalConnectionScore}`);
  console.log('++++++++++++++++++++++++++++');
  useConnectionStore.getState().setConnectionState(finalConnectionScore);
}

export function getScore(
  roundTripTime: number,
  jitter: number,
  packetLossRate: number
): {
  rttScore: number;
  jitterScore: number;
  packetLossScore: number;
} {
  const rttScore = Math.max(0, 100 - (roundTripTime / ROUND_TRIP_TIME_THRESHOLD) * 100);
  const jitterScore = Math.max(0, 100 - (jitter / JITTER_THRESHOLD) * 100);
  const packetLossScore = Math.max(0, 100 - (packetLossRate / PACKET_LOSS_THRESHOLD) * 100);
  return {
    rttScore,
    jitterScore,
    packetLossScore
  };
}

export function calculateAudioScore(
  roundTripTime: number,
  jitter: number,
  packetLossRate: number
): number {
  const { rttScore, jitterScore, packetLossScore } = getScore(
    roundTripTime,
    jitter,
    packetLossRate
  );
  return rttScore * 0.3 + jitterScore * 0.4 + packetLossScore * 0.3;
}

export function calculateVideoScore(
  roundTripTime: number,
  jitter: number,
  packetLossRate: number
): number {
  const { rttScore, jitterScore, packetLossScore } = getScore(
    roundTripTime,
    jitter,
    packetLossRate
  );
  return rttScore * 0.4 + jitterScore * 0.3 + packetLossScore * 0.3;
}

export function calculateStreamScore(audioScore: number, videoScore: number): ConnectionStateEnum {
  const audioScoreResult = audioScore || 0;
  const videoScoreResult = videoScore || 0;
  let finalScore = 0;
  // if audioScoreResult or videoScoreResult is 0, return only the other one
  if (!audioScoreResult) {
    finalScore = videoScoreResult;
  } else if (!videoScoreResult) {
    finalScore = audioScoreResult;
  } else {
    // if both audioScoreResult and videoScoreResult are not 0, return the average of them
    finalScore = audioScoreResult * 0.3 + videoScoreResult * 0.7;
  }
  console.log(`Final Score: ${finalScore}`);
  // Assuming a threshold of 70 for good connection
  return finalScore >= 70 ? ConnectionStateEnum.GOOD : ConnectionStateEnum.POOR;
}
