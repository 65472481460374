import Collapsible from '../../../commons/Collapsible';
import { useCallStore } from '../../../../store/call-state';

import CustomerInfo from '../components/customer-info';
import TitleCollapse from '../components/title-collapse';
import CustomerFiles from '../components/customer-files';
import HistoryList from '../components/history';
import { useGetVerdictKYC } from '../../../../api/hooks/kyc/useGetVerdictKYC';
import { useChatListStore } from '../../../../store/chat-list';
import KYCResult from '../../kyc/kyc-result';
import { Icon } from '@squantumengine/horizon';
import useLoadBerkas from '../../../../hooks/useLoadBerkas';
import CustomerSummary from '../components/customer-summary';

enum CollapseKey {
  CHAT = 'chat',
  DATA_PELANGGAN = 'data_pelanggan',
  INFORMASI = 'informasi',
  RIWAYAT = 'riwayat',
  BERKAS = 'berkas',
  RINGKASAN = 'isi ringkasan'
}

type CollapsibleItem = {
  key: CollapseKey;
  label: JSX.Element | string;
  content: JSX.Element;
};

function ConversationDetailWorkspace() {
  const { isManualKYCMode } = useCallStore();
  const { selectedCall } = useChatListStore();
  const { data: kycVerdict } = useGetVerdictKYC(selectedCall);
  const { isRefetchBerkas, handleReloadBerkas } = useLoadBerkas();

  const collapsibleItems = [
    {
      key: CollapseKey.DATA_PELANGGAN,
      label: <TitleCollapse icon="user" title="Info Pelanggan" />,
      content: <CustomerInfo />
    },
    {
      key: CollapseKey.INFORMASI,
      label: <TitleCollapse icon="folder" title="Data KYC" />,
      content: <KYCResult kycVerdict={kycVerdict} />
    },
    {
      key: CollapseKey.RINGKASAN,
      label: <TitleCollapse icon="sticky-note" title="Ringkasan" />,
      content: <CustomerSummary />
    },
    {
      key: CollapseKey.BERKAS,
      label: (
        <TitleCollapse
          icon="paperclip"
          title="Berkas Pelanggan"
          actionEl={
            <span
              className={`${isRefetchBerkas && 'animate-spin'} flex cursor-pointer`}
              onClick={handleReloadBerkas}>
              <Icon name="sync" />
            </span>
          }
        />
      ),
      content: <CustomerFiles />
    },
    {
      key: CollapseKey.RIWAYAT,
      label: <TitleCollapse icon="history" title="Riwayat" />,
      content: <HistoryList />
    }
  ].reduce((acc, curr): any => {
    if (CollapseKey.INFORMASI === curr.key && !kycVerdict) return [...acc];
    if (!([CollapseKey.INFORMASI].includes(curr.key) && !isManualKYCMode)) return [...acc, curr];
    return [...acc];
  }, []);

  const _renderCollapsibleItem = ({ key, label, content }: CollapsibleItem, index: number) => (
    <>
      <div
        className={`px-[24px] py-[12px] ${
          index !== 0 && 'border-b-0 border-l-0 border-r-0 border-t border-solid border-gray-300'
        }`}>
        <Collapsible key={key} label={label}>
          {content}
        </Collapsible>
      </div>
    </>
  );

  return (
    <div className="space-y-0" id="dashboard-workspace">
      {collapsibleItems.map(_renderCollapsibleItem)}
    </div>
  );
}

export default ConversationDetailWorkspace;
