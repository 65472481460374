import { Flex } from 'antd';
import { Button, Paragraph, SelectMenu } from '@squantumengine/horizon';
import { useEffect, useState } from 'react';
import { ChatFooterSummaryEnum } from '../../../../static/tab-list';
import { useChatListStore } from '../../../../store/chat-list';
import { getUserJSON } from '../../../../utils/user-json';
import { useWorkspaceSummary } from '../../../../api/hooks/workspace/useWorkspaceSummary';
import { useGetTopic } from '../../../../api/hooks/inbox/useTopic';
import { updateWorkspaceSummary } from '../../../../api/workspace';
import { ResponseGetTopic, WorkspaceSummary } from '../../../../api/param.interface';
import {
  ChatFooterFormInterface,
  transformConversationTopics,
  TypeSummaryEnum
} from './chat-footer.interface';
import { useCallStore } from '../../../../store/call-state';

const FormChatFooter = ({
  setSelectedMenus,
  openNotification,
  type = TypeSummaryEnum.FILL,
  onClickEndTicket
}: ChatFooterFormInterface) => {
  const [selectedTopic, setSelectedTopic] = useState<string>('');
  const [selectedNotes, setSelectedNotes] = useState<string>('');
  const [topic, setTopic] = useState<string>('');
  const { selectedCall, setIsReadyToClose } = useChatListStore();
  const { setIsOpenModalSummaryConfirm } = useCallStore();

  const { account } = getUserJSON();
  const conversationId = selectedCall?.conversationId;

  const { data: summaryData } = useWorkspaceSummary(conversationId as string);
  const { data: topicData } = useGetTopic(selectedCall!?.inboxId!);

  const options = transformConversationTopics((topicData as ResponseGetTopic) || []);
  const msgCounter = selectedNotes!.length;
  const maxCharacter = 1024;
  const isLimitCharacter = msgCounter > maxCharacter;
  const accountId = account?.accountId;

  const onFinish = async () => {
    topic && setSelectedTopic(topic);
    topic && setSelectedMenus && setSelectedMenus(ChatFooterSummaryEnum.DONE);

    if (accountId && conversationId) {
      const payload: WorkspaceSummary = {
        topic: topic || selectedTopic || '',
        background: '-',
        specificRequest: '-',
        solution: '-',
        note: selectedNotes || '-'
      };
      try {
        selectedTopic && setIsReadyToClose(true);
        updateWorkspaceSummary(accountId, conversationId, payload);

        if (type === TypeSummaryEnum.END_CHAT) {
          onClickEndTicket && onClickEndTicket();
          setIsOpenModalSummaryConfirm(false);
          openNotification({
            label: `Sesi dengan ${selectedCall?.name} sudah selesai.`,
            variant: 'primary'
          });
        }
      } catch {
        openNotification({
          label: 'Update data gagal!',
          variant: 'secondary'
        });
      }

      const localStorageSummaryString = JSON.stringify({
        topic: selectedTopic,
        background: '-',
        specificRequest: '-',
        solution: '-',
        note: selectedNotes
      });
      localStorage.setItem('customerDetails', localStorageSummaryString);
    }
  };

  useEffect(() => {
    const existedTopic = summaryData?.topic;
    const existedNote = summaryData?.note;
    const isDataExisted = existedNote && existedTopic !== '-';

    if (isDataExisted) {
      setSelectedTopic(existedTopic || '');
      setIsReadyToClose(true);
    } else {
      setSelectedNotes('');
      setIsReadyToClose(false);
    }
    // eslint-disable-next-line
  }, [summaryData, selectedCall]);

  return (
    <Flex vertical gap={8} className="w-full">
      <SelectMenu
        onChange={(e: any) => setTopic(e.label)}
        options={options}
        placeholder="Pilih Topik (Wajib)"
        showSearch
      />
      <Flex
        className={`box-border w-full rounded-xl border border-solid p-2 ${
          isLimitCharacter ? 'border-red-700' : 'border-border-gray'
        }`}>
        <textarea
          style={{ resize: 'none' }}
          className="no-scrollbar h-28 w-full overflow-y-scroll rounded-xl border-none text-sm outline-none placeholder:text-sm placeholder:text-neutral-300 focus:outline-none"
          value={selectedNotes}
          onChange={(e) => setSelectedNotes(e.target.value)}
          placeholder="Tulis Catatan (Opsional)"
        />
      </Flex>
      <Flex justify="end">
        <Paragraph size="s" className={`${isLimitCharacter && 'text-red-700'}`}>
          {msgCounter}/{maxCharacter}
        </Paragraph>
      </Flex>
      <Button
        onClick={onFinish}
        disabled={!topic || isLimitCharacter}
        type="submit"
        className="rounded-lg">
        Simpan
      </Button>
    </Flex>
  );
};

export default FormChatFooter;
