import { useEffect, useState } from 'react';
import useNavigatorOnLine from '../../../hooks/useNavigatorOnline';
import { useConnectionStore } from '../../../store/new/connection-state';
import { ConnectionStateEnum } from '../../../store/new/connection-state/connection-state.interface';

const ConnectionInfo = () => {
  const [show, setShow] = useState(true);
  const isOnline = useNavigatorOnLine();
  const connectionState = useConnectionStore((state) => state.connectionState);

  useEffect(() => {
    if (isOnline || Object.values(ConnectionStateEnum).includes(connectionState)) setShow(true);
  }, [connectionState, isOnline]);

  return (
    <div
      role="dialog"
      className={`${
        (!isOnline || connectionState === ConnectionStateEnum.POOR) && show
          ? 'translate-y-8 opacity-100'
          : '-translate-y-full opacity-0'
      } bg-orange-danger-light absolute top-0 z-[200] w-fit rounded-xl text-white 
        py-0.5 px-6 transition-all duration-300 left-1/2 -translate-x-1/2`}>
      <div className="text-left text-sm">
        {!isOnline ? (
          <p>
            Menghubungkan kembali ke internet. Selagi menunggu, mohon cek internet Anda.
          </p>
        ) : (
          <p>
            Koneksi internet sedang tidak optimal. Silakan cek koneksi internet Anda.
          </p>
        )}
      </div>
    </div>
  );
};

export default ConnectionInfo;
