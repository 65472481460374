import { useQuery } from 'react-query';
import { useConversationStore } from '../store/new/conversation';
import { messageHistory } from '../config/message-history';
import { IChatMessage } from '../types/new/conversation';
import { Icon, Paragraph } from '@squantumengine/horizon';
import { LastMessageEnum } from '../static/last-message';
import { useEffect } from 'react';
import CONVERSATION_DESCRIPTION_MAP from '../static/conversation-item';

const useLastChat = (
  conversationId: string,
  enabled: boolean = true,
  isTicketInactive: boolean = false
) => {
  const { lastMessageMap, setLastMessageMap } = useConversationStore();

  const isConversationMessageExist = !!lastMessageMap[conversationId];

  const { refetch } = useQuery(['lastChat', conversationId], () => messageHistory(conversationId), {
    enabled: enabled && !isConversationMessageExist,
    onSuccess: (data) => {
      const convId = data.conversationId;
      if (data?.messageList[0]) setLastMessageMap(convId, data.messageList?.[0] as IChatMessage);
    }
  });

  useEffect(() => {
    // set content to empty string if in ticket resolved last chat is 'Chat baru masuk'
    if (isConversationMessageExist && isTicketInactive) {
      if (lastMessageMap[conversationId].content === CONVERSATION_DESCRIPTION_MAP.INCOMING_CHAT) {
        setLastMessageMap(conversationId, { content: '' } as IChatMessage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConversationMessageExist, isTicketInactive]);

  const getAttachmentName = () => {
    const lastMessage = lastMessageMap[conversationId];

    if (!lastMessage) return;

    if (lastMessage.attachment && lastMessage.attachment.length) {
      return lastMessage.attachment[0].name;
    }

    if (lastMessage.documents && lastMessage.documents.length) {
      return lastMessage.documents[0].name;
    }

    return;
  };

  const getLastMessageElement = () => {
    const lastMessage = lastMessageMap[conversationId];
    const attachmentName = getAttachmentName();

    if (attachmentName) {
      return (
        <>
          <Icon name="paperclip" size="sm" />
          <span>{attachmentName}</span>
        </>
      );
    }

    if (!lastMessage || !lastMessage?.content) {
      return <Paragraph italic>{LastMessageEnum.NO_MESSAGE}</Paragraph>;
    }

    return lastMessage.content;
  };

  return {
    conversationId: conversationId,
    message: lastMessageMap[conversationId],
    element: getLastMessageElement(),
    timestamp: lastMessageMap[conversationId]?.timestamp || 0,
    refetch
  };
};

export default useLastChat;
