import axios, { InternalAxiosRequestConfig } from 'axios';
import { LoginPlatform } from '../shared/types/auth.interface';
import { useAuthStore } from '../store/new/auth';
import interceptor from './interceptors';

const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1/`,
  headers: {
    'Content-Type': 'application/json',
    authenticationPlatform: `${LoginPlatform.NATIVE}`,
    Authorization: `jwt ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  }
});

export const requestV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v2/`,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `jwt ${localStorage.getItem('accessToken')?.replaceAll('"', '')}`
  }
});

const handleInterceptors = (config: InternalAxiosRequestConfig) => {
  const authProvider = useAuthStore.getState().authProvider;
  const isSQEIDLoggedIn = useAuthStore.getState().isSQEIDLoggedIn();

  if (isSQEIDLoggedIn || authProvider === LoginPlatform.SIMAS_ID) {
    config.headers.authenticationPlatform = LoginPlatform.SIMAS_ID;
    return interceptor.SQEID({config , axiosInstance: request});
  }

  config.headers.authenticationPlatform = LoginPlatform.NATIVE;
  return interceptor.NATIVE(config);
}

request.interceptors.request.use((config) => {
  return handleInterceptors(config);
});

requestV2.interceptors.request.use((config) => {
  return handleInterceptors(config);
});

export default request;
