import { Col, Row } from 'antd';
import { useRef, useState } from 'react';
import ChatBox from '../../../components/v2/card/chatbox/conversation';
import { useDashboardPage } from '../../../hooks/useDashboardPage';
import RecentActivity from '../../../components/v2/card/recent-activity';
import AssignAgentModalV2 from '../../../components/v2/modal/assign-agent-modal';
import OfflineMessageChatBox from '../../../components/v2/card/chatbox/offline-message';
import { ConversationInterface } from '../../../components/card/chat-message/chat-message.interface';
import { useChatListStore } from '../../../store/chat-list';
import IncomingCallNotification from '../../../components/notification/incoming-call';
import SPVWorkspace from '../../../components/v2/workspace/supervisor';
import TicketOngoing, { TicketOngoingComponent } from './components/ticket-ongoing';
import TicketInactive, { TicketInactiveComponent } from './components/ticket-inactive';
import TicketIncoming, { TicketIncomingComponent } from './components/ticket-incoming';
import TicketOffline, { TicketOfflineComponent } from './components/ticket-offline';
import Filter from './components/filter';
import dayjs from 'dayjs';
import NoChat from '../../../components/v2/card/chatbox/no-chat';
import TabFilterConversation from './components/filter/tab-filter-conversation';
import { TabConversation } from './dashboard-supevisor.interface';
import { Icon, Paragraph, Title, Typography } from '@squantumengine/horizon';
import { useConversationListStore } from '../../../store/conversation-list-state';
import { useInboxStore } from '../../../store/new/inbox';
import { useParams } from 'react-router-dom';

function DashboardSupervisorV2() {
  const { selectedOfflineChat, selectedCall } = useChatListStore();
  const [assignAgentConversation, setAssignAgentConversation] =
    useState<ConversationInterface | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { handleActiveCall, handleEndCall } = useDashboardPage();
  const [selectedTab, setSelectedTab] = useState<TabConversation>(TabConversation.ALL);
  const [filters, setFilters] = useState<Record<string, any>>({
    filterBy: {
      key: '',
      value: ''
    },
    startDate: dayjs().add(-6, 'd').startOf('day'),
    endDate: dayjs().endOf('day')
  });

  const { selectedInboxId, getSelectedInbox } = useInboxStore();
  const currentInbox = getSelectedInbox();
  const { inboxId: inboxIdParam } = useParams();
  const inboxId = inboxIdParam || selectedInboxId;

  const ticketIncomingRef = useRef<TicketIncomingComponent>(null);
  const ticketOngoingRef = useRef<TicketOngoingComponent>(null);
  const ticketOfflineRef = useRef<TicketOfflineComponent>(null);
  const ticketInactiveRef = useRef<TicketInactiveComponent>(null);

  const handleAssignAgent = (conversation: ConversationInterface) => {
    setAssignAgentConversation(conversation);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getConversationList = () => {
    ticketIncomingRef.current!.refetchTicketIncoming();
    ticketOngoingRef.current!.refetchTicketOngoing();
    ticketInactiveRef.current!.refetchTicketInactive();
  };

  const { assignedConversationList, createdConversationList, closedConversationList } =
    useConversationListStore();

  const getFiltersValues = () => {
    const filter: any = {
      email: '',
      endDate: '',
      name: '',
      phoneNumber: '',
      startDate: '',
      ticketNumber: ''
    };

    filter.startDate = filters.startDate.format('YYYY-MM-DD');
    filter.endDate = filters.endDate.format('YYYY-MM-DD');

    const filterByKey = filters.filterBy.key;
    switch (filterByKey) {
      case 'ticket':
        filter.ticketNumber = filters.filterBy.value;
        break;
      case 'phone':
        filter.phoneNumber = filters.filterBy.value;
        break;
      case 'name':
        filter.name = filters.filterBy.value;
        break;
      default:
        filter[filterByKey] = filters.filterBy.value;
        break;
    }

    return filter;
  };

  const filteredAssigned = assignedConversationList.filter((data) => data.inboxId === inboxId);
  const filteredCreated = createdConversationList.filter((data) => data.inboxId === inboxId);
  const filteredClosed = closedConversationList.filter((data) => data.inboxId === inboxId);

  const isTabTicketIncoming = [TabConversation.ALL, TabConversation.CREATED].includes(selectedTab);
  const isTabTicketOngoing = [TabConversation.ALL, TabConversation.ONGOING].includes(selectedTab);
  const isTabTicketOffline = [TabConversation.ALL, TabConversation.OFFLINE].includes(selectedTab);
  const isTabTicketResolved = [TabConversation.ALL, TabConversation.RESOLVED].includes(selectedTab);

  const isFetchingTicketIncoming = ticketIncomingRef.current?.isFetching();
  const isFetchingTicketOngoing = ticketOngoingRef.current?.isFetching();
  const isFetchingTicketOffline = ticketOfflineRef.current?.isFetching();
  const isFetchingTicketResolved = ticketInactiveRef.current?.isFetching();

  const showTicketIncoming =
    (isTabTicketIncoming && filteredCreated.length > 0) ||
    (isTabTicketIncoming && isFetchingTicketIncoming);
  const showTicketOngoing =
    (isTabTicketOngoing && filteredAssigned.length > 0) ||
    (isTabTicketOngoing && isFetchingTicketOngoing);
  const showTicketOffline =
    (isTabTicketOffline && ticketOfflineRef.current?.total()) ||
    (isTabTicketOffline && isFetchingTicketOffline);
  const showTicketResolved =
    (isTabTicketResolved && filteredClosed.length > 0) ||
    (isTabTicketResolved && isFetchingTicketResolved);

  const isEmptyConversation =
    !showTicketIncoming && !showTicketOngoing && !showTicketOffline && !showTicketResolved;

  return (
    <Row className="flex flex-wrap bg-white">
      <Col className="flex h-[calc(100vh-64px)] flex-1 flex-col overflow-y-auto">
        <div className="flex flex-col space-y-4 p-4">
          <RecentActivity />
          <IncomingCallNotification handleActiveCall={handleActiveCall} />
          <Filter onChange={setFilters} />
          <TabFilterConversation onChange={setSelectedTab} value={selectedTab} />
        </div>
        {isEmptyConversation && (
          <div className="flex justify-center pt-8 text-gray-500">
            <Typography.Paragraph>Tidak ada tiket ditemukan</Typography.Paragraph>
          </div>
        )}
        <div className={`${!showTicketIncoming && 'hidden'}`}>
          <TicketIncoming
            ref={ticketIncomingRef}
            handleAssignAgent={handleAssignAgent}
            handleActiveCall={handleActiveCall}
            filter={getFiltersValues()}
          />
        </div>

        <div className={`${!showTicketOngoing && 'hidden'}`}>
          <TicketOngoing
            ref={ticketOngoingRef}
            setAssignAgentConversation={setAssignAgentConversation}
            setModalOpen={setModalOpen}
            filter={getFiltersValues()}
          />
        </div>

        <div className={`${!showTicketOffline && 'hidden'}`}>
          <TicketOffline ref={ticketOfflineRef} filter={getFiltersValues()} />
        </div>

        <div className={`${!showTicketResolved && 'hidden'}`}>
          <TicketInactive ref={ticketInactiveRef} filter={getFiltersValues()} />
        </div>
      </Col>
      <Col
        span={8}
        className="h-[calc(100vh-64px)] overflow-y-auto border-y-0 border-l border-r-0 border-solid border-l-grey-50 bg-white">
        {currentInbox?.inboxConfig?.allowSeeConversation ? (
          <>
            {!selectedCall && !selectedOfflineChat && <NoChat />}
            {(selectedCall || selectedOfflineChat) && (
              <>
                <div className="flex h-[550px] min-h-[480px] flex-col gap-4">
                  {selectedOfflineChat ? (
                    <OfflineMessageChatBox />
                  ) : (
                    <ChatBox onClose={handleEndCall} allowRefresh withoutVideo />
                  )}
                </div>
                <SPVWorkspace />
              </>
            )}
          </>
        ) : (
          <div className="flex h-screen flex-col items-center justify-center space-y-2">
            <Icon name="close-circle" size="lg" />
            <Title level={5}>Tidak punya akses</Title>
            <Paragraph className="text-center text-gray-600">
              Anda tidak memiliki akses untuk melihat chat.
              <br />
              Mohon hubungi admin SQE apabila Anda membutuhkan akses.
            </Paragraph>
          </div>
        )}
      </Col>
      <AssignAgentModalV2
        conversation={assignAgentConversation}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        refetch={getConversationList}
      />
    </Row>
  );
}

export default DashboardSupervisorV2;
