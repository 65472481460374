import { useTimer } from '../../../../../hooks/useTimer';
import { useEffect, useRef } from 'react';
import { playVideoNotification, stopVideoNotification } from '../../../../../utils/notification';
import { ConversationInterface } from '../../../../../components/card/chat-message/chat-message.interface';
import { WAITING_TIME_THRESHOLD } from '../../../../../static/dashboard';

type Props = {
  conversation: ConversationInterface;
  children?: React.ReactNode;
  enabled: boolean;
  handleShowDefaultNotification: (conversation: ConversationInterface) => void;
  handleShowWarningNotification: (conversation: ConversationInterface) => void;
  handleCloseNotification: (id: string) => void;
};

const IncomingNotificationItem = ({
  conversation,
  children,
  enabled = false,
  handleShowDefaultNotification,
  handleShowWarningNotification,
  handleCloseNotification
}: Props) => {
  const { second } = useTimer(Math.round((Date.now() - Number(conversation.startTime)) / 1000));
  const isWarning = second > WAITING_TIME_THRESHOLD;
  const notificationIdRef = useRef('');

  useEffect(() => {
    if (!enabled) return;
    if (notificationIdRef.current === conversation.conversationId && isWarning) {
      handleCloseNotification(notificationIdRef.current);
      handleShowWarningNotification(conversation);
      notificationIdRef.current = `${conversation.conversationId}-warning`;
    } else if (!notificationIdRef.current) {
      handleShowDefaultNotification(conversation);
      playVideoNotification(conversation?.conversationId, true);
      notificationIdRef.current = conversation.conversationId;
    }
  }, [isWarning, notificationIdRef.current, enabled]);

  const destroyNotification = () => {
    handleCloseNotification(conversation?.conversationId);
    handleCloseNotification(`${conversation.conversationId}-warning`);
    stopVideoNotification(conversation?.conversationId);
    notificationIdRef.current = '';
  };

  useEffect(() => {
    if (!enabled && notificationIdRef.current) {
      handleCloseNotification(conversation?.conversationId);
      handleCloseNotification(`${conversation.conversationId}-warning`);
      stopVideoNotification(conversation?.conversationId);
      notificationIdRef.current = '';
    }
  }, [enabled, notificationIdRef.current]);

  return <div onClick={destroyNotification}>{children}</div>;
};

export default IncomingNotificationItem;
