import { useRef, useEffect } from 'react';
import { MessageEventEnum } from '../../../../static/message-event';
import TimestampToString, { DurationToString } from '../../../../utils/timestamp';
import { ChatMessageInterface } from './chat-message.interface';
import { useChatListStore } from '../../../../store/chat-list';
import FileUpload from '../chat-file-upload';
import { addInitialHeight, expandedChatAnimation } from '../../../../utils/chat-message';
import { Icon, Paragraph } from '@squantumengine/horizon';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import { defaultCommunicationTypeEnum } from '../../../../static/communication-type';
import { useTimer } from '../../../../hooks/useTimer';

function ChatMessage({
  id,
  isUser,
  message,
  time,
  isBusy,
  isBot,
  type,
  documents,
  name
}: ChatMessageInterface) {
  const firstUpdate = useRef(true);
  const { userRole } = useUserStatusStore();
  const { selectedCall } = useChatListStore();

  const defaultCommunicationType =
    selectedCall?.inboxConfig?.mediaCallConfiguration?.defaultCommunicationType;

  const isAudioOnly = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO;
  const isAudioVideo = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO_VIDEO;
  const isVoiceCall = isAudioOnly || isAudioVideo;

  useEffect(() => {
    if (firstUpdate.current) {
      addInitialHeight(id);
      firstUpdate.current = false;
      return;
    }
    expandedChatAnimation(id);
  }, [isBusy]); // eslint-disable-line react-hooks/exhaustive-deps
  const { second } = useTimer(Math.round((Date.now() - time) / 1000));
  const showIncomingVideoAsChat =
    type === MessageEventEnum.CALL && userRole === RoleEnum.SUPERVISOR;
  return (
    <>
      {(message || documents || showIncomingVideoAsChat) && (
        <div
          id={id}
          className={`mb-4 flex h-auto flex-row justify-between gap-4 p-4 text-left transition-all duration-200
            ${
              isUser
                ? 'ml-6 mr-[72px] rounded-b-2xl rounded-r-2xl bg-neutral-50'
                : `ml-[72px] mr-6 rounded-l-2xl rounded-t-2xl bg-blue-50`
            }`}>
          <div
            className="
            flex w-full flex-col items-start justify-between gap-1 whitespace-pre-line break-normal 
            break-all text-base font-normal text-neutral-950">
            {type === MessageEventEnum.TEXT ? (
              isBusy ? (
                '• • •'
              ) : (
                message
              )
            ) : type === MessageEventEnum.CALL ? (
              <div className="flex gap-4">
                <Icon name={isVoiceCall ? 'phone' : 'video'} />
                <div className="text-nowrap">
                  <Paragraph>Panggilan Video Berlangsung</Paragraph>
                  <Paragraph weight="bold">Durasi {DurationToString(second)}</Paragraph>
                </div>
              </div>
            ) : (
              <div className="flex w-full flex-col gap-2">
                {documents?.map((items: any, key) => (
                  //TODO: need file validation image file or not
                  <div key={key} className="w-full overflow-hidden">
                    <FileUpload data={items} />
                  </div>
                ))}
              </div>
            )}
            <div className="text-xs text-neutral-600">
              {isBot ? 'Pesan Otomatis' : !isUser ? `Agen: ${name}` : ''}
            </div>
          </div>
          <div className="flex flex-col items-end justify-between text-xs font-normal text-neutral-600">
            <button className="cursor-pointer border-none bg-transparent p-0">
              <Icon name="kebab-vertical" color="#8490A1" />
            </button>
            <div>{TimestampToString(time)}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatMessage;
