import { useEffect, useState, useRef, useCallback } from 'react';

const useIdleDetection = (timeout: number) => {
  const [isIdle, setIsIdle] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timeout);
  const timeoutRef = useRef<number | null>(null);
  const intervalRef = useRef<number | null>(null);
  const visibilityTimeoutRef = useRef<number | null>(null);

  const resetIdleTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setIsIdle(false);
    setTimeLeft(timeout);

    intervalRef.current = window.setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 1000) {
          clearInterval(intervalRef.current!);
          return 0;
        }
        return prevTimeLeft - 1000;
      });
    }, 1000);

    timeoutRef.current = window.setTimeout(() => {
      setIsIdle(true);
      clearInterval(intervalRef.current!);
    }, timeout);
  }, [timeout]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        visibilityTimeoutRef.current = window.setTimeout(() => {
          setIsIdle(true);
        }, timeout);
      } else {
        if (visibilityTimeoutRef.current) {
          clearTimeout(visibilityTimeoutRef.current);
          resetIdleTimer();
        }
      }
    };
    const handleUserActivity = () => {
      resetIdleTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    resetIdleTimer();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      if (visibilityTimeoutRef.current) {
        clearTimeout(visibilityTimeoutRef.current);
      }
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [resetIdleTimer, timeout]);

  return { isIdle, timeLeft, resetTimer: resetIdleTimer };
};

export default useIdleDetection;
