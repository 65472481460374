import { Button } from 'antd';
import { AgentCardProps } from '../assign-agent-modal.interface';
import { InboxMemberListWithCapacityInterface } from '../../../../shared/types/inbox.interface';
import { generateAvatarImage } from '../../../../utils/fullNameParse';

const getAgentCardContent = (agent: InboxMemberListWithCapacityInterface) => {
  const { capacity, available } = agent;

  if (!available)
    return {
      agentStatus: 'Istirahat',
      agentStatusColor: 'text-grey-100',
      disabled: true
    };

  const { maxChatCapacity, onGoingChatCapacity, maxCallCapacity, onGoingCallCapacity } = capacity;

  if (maxCallCapacity && onGoingCallCapacity >= maxCallCapacity) {
    return {
      agentStatus: 'Sedang dalam panggilan',
      agentStatusColor: 'text-orange-danger',
      disabled: true
    };
  }

  if (!onGoingChatCapacity && maxChatCapacity)
    return {
      agentStatus: 'Tersedia',
      agentStatusColor: 'text-bold-green',
      disabled: false
    };

  return {
    agentStatus: (
      <>
        Sedang berbicara dengan <b>{onGoingChatCapacity}</b> pelanggan
      </>
    ),
    agentStatusColor: 'text-orange-danger',
    disabled: maxChatCapacity <= onGoingChatCapacity
  };
};

export const AgentCard = ({ agent, onAssign }: AgentCardProps) => {
  const agentName = `${agent.firstName} ${agent.lastName ? agent.lastName : ''}`;
  const { agentStatus, agentStatusColor, disabled } = getAgentCardContent(agent);

  return (
    <div className="flex items-center rounded-xl bg-grey-10 p-4">
      <div>
        <img
          className="rounded-full border-2 border-solid border-grey-50"
          src={generateAvatarImage(agentName)}
          alt="agent profile"
        />
      </div>
      <div className="mx-4 flex-1">
        <p className="mb-2 mt-0 text-lg font-semibold text-black-90">{agentName}</p>
        <p className={`${agentStatusColor} my-0 text-base`}>{agentStatus}</p>
      </div>
      <Button
        onClick={onAssign}
        disabled={disabled}
        className={`${
          disabled ? 'border-grey-50 text-grey-50' : 'border-black-90 text-black-90'
        } h-12 rounded-full border-solid bg-transparent text-base font-semibold`}>
        Tugaskan
      </Button>
    </div>
  );
};
