import { Flex, Modal, Row, notification } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useCallStore } from '../../store/call-state';
import {
  closeProducer,
  CreateAudioProducer,
  CreateVideoProducer,
  leaveMeeting,
  remoteStreamAudioGlobal,
  remoteStreamScreenGlobal,
  remoteStreamVideoGlobal,
  selfVideoGlobal
} from '../../utils/webSocket/videoRequest';
import RoundedButton from '../input/rounded-button';
import { ButtonEnum, ButtonStateEnum } from '../input/rounded-button/rounded-button.interface';
import { useChatListStore } from '../../store/chat-list';
import { getSelectedCallJSON, getUserJSON, setSelectedCallJSON } from '../../utils/user-json';
import { CurrentUserObjectInterface } from '../../shared/types/user.interface';
import {
  CloseConversationEnum,
  ConversationStatusEnum,
  MessageEventEnum
} from '../../static/message-event';
import {
  CommunicationTypeEnum,
  confirmModalPropsGE,
  confirmModalPropsKYCVideo,
  confirmModalPropsKYCTicket,
  confirmModalEndSession,
  confirmModalCustomerDisconnected,
  confirmMinutesDescription
} from '../../static/communication-type';
import { CloseConversation, DeleteCall } from '../../api/call';
import { useDashboardPage } from '../../hooks/useDashboardPage';
import {
  CloseOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons';
import { LeaveMeetingMetadata } from '../../types/video-call';
import { useConversationListStore } from '../../store/conversation-list-state';
import { useQueryClient } from 'react-query';
import { parseDuration } from '../card/call-list';
import { useTimer } from '../../hooks/useTimer';
import { ConversationInterface } from '../card/chat-message/chat-message.interface';
import WaveVisualizer from './WaveVisualizer';
import { getIconUrl } from '../../utils/getIconUrl';
import Frame from './Frame';
import CustomerDisconnect from './CustomerDisconnect';
import { Button, Title } from '@squantumengine/horizon';
import { useWorkspaceSummary } from '../../api/hooks/workspace/useWorkspaceSummary';
import { useInboxStore } from '../../store/new/inbox';

type Props = {
  v2?: boolean;
};
type DropCallProps = {
  type: CloseConversationEnum;
};
type DropCallModalConfirmProps = {
  openModalConfirm: boolean;
  typeModalConfirm: CloseConversationEnum;
  disconnectModalConfirm: boolean;
};

function VideoCall({ v2 = false }: Props) {
  const queryClient = useQueryClient();
  const [isMicActive, setIsMicActive] = useState<boolean>(true);
  const [{ openModalConfirm, typeModalConfirm, disconnectModalConfirm }, setOpenModalConfirm] =
    useState<DropCallModalConfirmProps>({
      openModalConfirm: false,
      typeModalConfirm: CloseConversationEnum.PRECLOSE,
      disconnectModalConfirm: false
    });

  const { selectedCall, setSelectedCall } = useChatListStore();
  const { handleEndCall } = useDashboardPage();
  const {
    fullScreen,
    zoomFullscreen,
    setZoomFullscreen,
    setFullScreen,
    showChat,
    setShowChat,
    remoteMediaType,
    remoteVideoIcon,
    remoteSoundIcon,
    isManualKYCMode,
    isVoiceCall,
    setIsVoiceCall,
    showCameraButton,
    setShowVideo,
    InstructionMode,
    showCustomerOfflineAlert,
    setShowCustomerOfflineAlert,
    setIsOpenModalSummaryConfirm
  } = useCallStore();

  const { isSelectedInboxManualKyc } = useInboxStore();
  const isManualKYC = isSelectedInboxManualKyc() || isManualKYCMode;

  const { activeConversationList, moveConversation } = useConversationListStore();
  const [isCamActive, setIsCamActive] = useState<boolean>(!isVoiceCall);
  const [currentCall, setCurrentCall] = useState<ConversationInterface | null>(null);
  const currentUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
  const accountId = currentUser.account?.accountId || '';
  const conversationId = selectedCall?.conversationId || '';
  const durationReminder = selectedCall?.inboxConfig?.durationReminder;
  const [duration, setDuration] = useState(durationReminder);
  const { data: summaryData, refetch } = useWorkspaceSummary(conversationId as string);
  const isTopicSubmitted = summaryData?.topic !== '-'; // '-' means summary it's not submitted yet from BE responses

  const modalProps = useMemo(() => {
    if (isManualKYC) {
      return {
        [CloseConversationEnum.PRECLOSE]: confirmModalPropsKYCVideo,
        [CloseConversationEnum.CLOSE]: confirmModalPropsKYCTicket
      }[typeModalConfirm];
    }
    return confirmModalPropsGE;
  }, [isManualKYC, typeModalConfirm]);

  const micToggleHandler = () => {
    isMicActive ? closeProducer('audio') : CreateAudioProducer();
    setIsMicActive(!isMicActive);
  };

  const camToggleHandler = () => {
    isVoiceCall && setIsVoiceCall(false);
    isCamActive ? closeProducer('video') : CreateVideoProducer();
    setIsCamActive(!isCamActive);
  };

  // function when user change route and back to chat
  useEffect(() => {
    let remoteStreamVideo: any = document.getElementById('remote-video');
    let remoteStreamScreen: any = document.getElementById('remote-screen');
    let remoteStreamAudio: any = document.getElementById('remote-audio');
    let selfVideo: any = document.getElementById('self-video');

    if (!selfVideo.srcObject && selfVideoGlobal) {
      selfVideo.srcObject = selfVideoGlobal;
    }
    if (!remoteStreamVideo.srcObject && remoteStreamVideoGlobal) {
      remoteStreamVideo.srcObject = remoteStreamVideoGlobal;
    }

    if (!remoteStreamScreen.srcObject && remoteStreamScreenGlobal) {
      remoteStreamScreen.srcObject = remoteStreamScreenGlobal;
    }

    if (!remoteStreamAudio.srcObject && remoteStreamAudioGlobal) {
      remoteStreamAudio.srcObject = remoteStreamAudioGlobal;
    }
  }, []);

  useEffect(() => {
    refetch();
  }, [openModalConfirm]);

  const handleDirectCloseConversation = async () => {
    try {
      leaveMeeting();
      await CloseConversation(accountId, conversationId);
      useCallStore.setState({ showChat: false, showVideo: false, fullScreen: false });
      localStorage.removeItem('meetingRoomId');
      localStorage.removeItem('isSwicthed');
      const currentConversation = activeConversationList?.find(
        (conv) => conv?.conversationId === conversationId
      );
      currentConversation &&
        moveConversation(MessageEventEnum.ACTIVE, MessageEventEnum.INACTIVE, {
          ...currentConversation,
          conversationStatus: ConversationStatusEnum.RESOLVED,
          event: MessageEventEnum.INACTIVE
        });
      await DeleteCall(accountId, conversationId, localStorage.videoMessageId);
      selectedCall &&
        setSelectedCall({
          ...selectedCall,
          conversationStatus: ConversationStatusEnum.RESOLVED,
          event: MessageEventEnum.INACTIVE
        });
      queryClient.invalidateQueries(['useHistory', currentConversation?.contactId]);
    } catch (error) {
      notification.error({ message: `gagal mengakhiri percakapan` });
    }
  };

  const onDropCall = async (options?: DropCallProps) => {
    if (selectedCall && selectedCall.event === MessageEventEnum.ACTIVE) {
      if (options?.type === CloseConversationEnum.CLOSE) {
        handleDirectCloseConversation();
        return;
      }
      const failedEndCall = await handleEndCall(CloseConversationEnum.PRECLOSE);
      if (!failedEndCall) {
        if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) leaveMeeting();
        setShowVideo(false);
        setFullScreen(false);
        const selectedCallJSON = getSelectedCallJSON();
        selectedCallJSON.event = MessageEventEnum.SESSION_CLOSED;
        setShowChat(false);
        setSelectedCallJSON(JSON.stringify(selectedCallJSON));
        localStorage.removeItem('meetingRoomId');
        localStorage.removeItem('isSwicthed');
        localStorage.removeItem('startHeartBeat');
        // if no video interaction happened, we also skipped DeleteCall
        if (accountId && localStorage.videoMessageId)
          await DeleteCall(accountId, conversationId, localStorage.videoMessageId);
      }
    }
  };

  const onLeaveVideoCall = (metadata?: LeaveMeetingMetadata) => {
    const isChat = selectedCall?.communicationType === CommunicationTypeEnum.CHAT;
    const shouldEndCallChat = isChat && metadata?.endCall === true;
    if (
      selectedCall &&
      selectedCall.event === MessageEventEnum.ACTIVE &&
      (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO || shouldEndCallChat)
    ) {
      localStorage.removeItem('startHeartBeat');
      leaveMeeting(metadata);
      setShowVideo(false);
      setFullScreen(false);
      // TODO: request API to CHAT communication mode
    }
  };

  const handleShowModalConfirm = () =>
    setOpenModalConfirm((prev) => ({
      ...prev,
      openModalConfirm: true,
      typeModalConfirm: CloseConversationEnum.PRECLOSE
    }));

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm((prev) => ({
      ...prev,
      openModalConfirm: false,
      disconnectModalConfirm: false
    }));
  };

  const handleCloseDisconnectModal = () => {
    const localHeartBeat = localStorage.getItem('hearthBeat');
    const timestampInMilliseconds = Number(localHeartBeat) * 1000;
    const reminderInMilliseconds = (durationReminder || 15) * 60000;
    const differenceInMilliseconds = Date.now() - timestampInMilliseconds + reminderInMilliseconds;
    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    setDuration(differenceInMinutes);
    setOpenModalConfirm((prev) => ({ ...prev, disconnectModalConfirm: false }));
  };
  const handleShowDisconnectModal = () => {
    setOpenModalConfirm((prev) => ({ ...prev, disconnectModalConfirm: true }));
  };

  const handleConfirmEndCall = (options?: DropCallProps) => {
    localStorage.removeItem('startHeartBeat');
    setShowCustomerOfflineAlert(false);
    handleCloseModalConfirm();
    const hasEndCallMetadata =
      selectedCall?.inboxConfig?.communicationModes?.includes(CommunicationTypeEnum.CHAT) &&
      !isManualKYC;

    if (!isTopicSubmitted) {
      onDropCall(options);
      setIsOpenModalSummaryConfirm(true);
    } else {
      if (hasEndCallMetadata) onLeaveVideoCall({ endCall: true });
      else onDropCall(options);
    }
  };

  useEffect(() => {
    setCurrentCall(selectedCall);
    // eslint-disable-next-line
  }, []);

  const startTime = currentCall?.startTime || Date.now();
  const endTime = Date.now();
  const { second } = useTimer(Math.round((Date.now() - startTime) / 1000));
  const durationText = parseDuration(second, false, startTime, endTime);

  const className = `flex justify-center transition-all ${
    fullScreen ? `fixed left-0 ${v2 ? 'top-16' : 'top-[85px]'} z-50  h-[93.76%]` : 'relative h-max'
  }`;
  const containerWidthStyle = {
    width: fullScreen && showChat ? `${v2 ? `calc(100% - 394px)` : '75%'}` : '100%'
  };

  return (
    <div className={`${className} flex justify-center transition-all`} style={containerWidthStyle}>
      <div className="absolute left-6 top-6 z-50 flex h-fit w-fit flex-row items-center space-x-4">
        <div className="flex items-center justify-center">
          <img
            className="h-[48px] object-cover"
            src={
              remoteSoundIcon
                ? getIconUrl('microphone-white.svg')
                : getIconUrl('microphone-white-slash.svg')
            }
            alt="mic"
          />
        </div>
        {!isVoiceCall && (
          <div className="flex items-center justify-center">
            <img
              className={`w-[48px] object-cover`}
              src={remoteVideoIcon ? getIconUrl('video.svg') : getIconUrl('video-white-slash.svg')}
              alt="video"
            />
          </div>
        )}
      </div>
      <div className="w-full bg-black-rich text-center">
        {/* button for minimize but we have no button for maximize */}
        {/* <button className="absolute right-8 top-8 z-50 cursor-pointer border-none bg-transparent text-white">
          <img src={getIconUrl('minus-white.svg')} />
        </button> */}
        {InstructionMode && <Frame frame={InstructionMode} />}
        {showCustomerOfflineAlert && (
          <CustomerDisconnect duration={duration || 15} showModal={handleShowDisconnectModal} />
        )}
        <video
          id={'remote-video'}
          className={`${zoomFullscreen ? 'w-full' : 'h-full'} ${
            remoteMediaType === 'screen'
              ? 'absolute right-48 top-4 z-40  h-72 w-44 rounded-tl-3xl'
              : 'relative'
          } ${
            fullScreen && remoteMediaType !== 'screen'
              ? 'h-full'
              : 'h-[300px] max-h-[300px] min-h-[300px]'
          } ${
            isVoiceCall && 'hidden'
          } relative  flex-1 bg-black-rich object-cover transition-[width,height,display] `}
          autoPlay
          muted
          playsInline
        />
        {isVoiceCall && (
          <div className="relative h-[300px] max-h-[300px] min-h-[300px] w-full flex-1 bg-gradient-to-b from-black from-0% via-[#313131] via-20% to-[#313131] to-100% object-cover transition-[width,height,display] ">
            {showCameraButton ? (
              <span className="absolute z-10 flex h-full w-full flex-col items-center justify-center gap-2 font-bold text-white">
                Pelanggan ingin melakukan panggilan video <br /> Silakan aktifkan video untuk
                memulai panggilan video
              </span>
            ) : (
              <div className="absolute z-10 flex h-full w-full flex-col items-center justify-center gap-2 font-bold text-white">
                <span className="text-lg">Panggilan Suara</span>
                <span className="text-2xl">{durationText}</span>
              </div>
            )}
            {remoteStreamAudioGlobal && !showCameraButton && (
              <div className="absolute flex h-full w-full items-center justify-center opacity-15">
                <WaveVisualizer audioStream={remoteStreamAudioGlobal} />
              </div>
            )}
          </div>
        )}

        {remoteMediaType === 'screen' && (
          <>
            <p className="absolute right-[200px] top-2 z-50 max-w-[152px] cursor-pointer truncate border-none bg-transparent text-base font-semibold capitalize text-white">
              {selectedCall?.name}
            </p>
            <p className="absolute right-8 top-2 z-50 max-w-[152px] cursor-pointer truncate border-none bg-transparent text-base font-semibold capitalize text-white">
              {getUserJSON().name}
            </p>
          </>
        )}

        <video
          id={'remote-screen'}
          className={`relative ${remoteMediaType === 'video' ? 'hidden' : ''} ${
            fullScreen ? 'h-full' : 'max-h-[300px] min-h-[300px]'
          }  w-max flex-1 bg-black-rich object-cover transition-[width,height,display] `}
          autoPlay
          muted
          playsInline
        />
      </div>
      <audio id="remote-audio" autoPlay></audio>
      <div className={`absolute right-4 top-4 z-20 ${fullScreen ? 'h-72 w-44' : 'h-40 w-24'}`}>
        <video
          id={'self-video'}
          className={`h-full w-full ${
            remoteMediaType === 'screen' ? 'rounded-tr-3xl' : 'rounded-3xl'
          } ${isVoiceCall && 'hidden'} -scale-x-100 bg-grey-20 object-cover`}
          autoPlay
          muted
          playsInline
        />
      </div>
      {fullScreen && (
        <button
          className="absolute right-0 top-1/2 min-h-[72px] min-w-[120px] -translate-y-1/2 transform cursor-pointer rounded-l-xl border-none bg-black bg-opacity-75 p-2 text-white"
          onClick={() => setShowChat(!showChat)}>
          <div className="flex items-center justify-center space-x-2">
            {showChat ? (
              <DoubleRightOutlined className="text-base" />
            ) : (
              <DoubleLeftOutlined className="text-base" />
            )}
            <span className="w-12 text-center text-sm font-semibold">Ruang Kerja</span>
          </div>
        </button>
      )}
      <Row
        className={`${
          remoteMediaType === 'screen'
            ? 'right-4 top-[304px] w-[352px] rounded-b-[16px] opacity-90'
            : 'bottom-4 rounded-lg opacity-25'
        } absolute z-50 justify-center  space-x-2 bg-black-rich px-4 pb-2 pt-4  text-white shadow-[0px_8px_8px_rgba(0,0,0,0.25)] transition-[width,height,position,transform] hover:opacity-90`}>
        <RoundedButton
          name={ButtonEnum.SPEAKER}
          color={isMicActive ? 'bg-white' : 'bg-grey-100'}
          imageSrc={isMicActive ? getIconUrl('microphone.svg') : getIconUrl('microphone-slash.svg')}
          onClick={micToggleHandler}
          label={isMicActive ? ButtonStateEnum.ON : ButtonStateEnum.OFF}
        />
        {showCameraButton && (
          <RoundedButton
            name={ButtonEnum.CAMERA}
            color={isCamActive ? 'bg-white' : 'bg-grey-100'}
            imageSrc={
              isCamActive ? getIconUrl('video-black.svg') : getIconUrl('video-black-slash.svg')
            }
            onClick={camToggleHandler}
            label={isCamActive ? ButtonStateEnum.ON : ButtonStateEnum.OFF}
          />
        )}

        {isManualKYC ? (
          <>
            <RoundedButton
              name={ButtonEnum.DROP_CALL}
              color="bg-orange-danger"
              imageSrc={getIconUrl('phone-black.svg')}
              onClick={handleShowModalConfirm}
              label={confirmModalPropsKYCVideo.title}
            />
          </>
        ) : (
          <>
            {remoteMediaType !== 'screen' && !isVoiceCall && (
              <RoundedButton
                name={ButtonEnum.ZOOM}
                color={'bg-black-rich'}
                imageSrc={getIconUrl('compress-alt.svg')}
                onClick={() => setFullScreen(!fullScreen)}
                label={fullScreen ? 'Kecilkan' : 'Besarkan'}
                border
              />
            )}
            <RoundedButton
              name={ButtonEnum.DROP_CALL}
              color="bg-orange-danger"
              imageSrc={getIconUrl('phone-slash.svg')}
              onClick={handleShowModalConfirm}
              label="Akhiri"
            />
          </>
        )}
      </Row>

      {!isVoiceCall && (
        <button
          className="cursor-pointer border-none bg-transparent p-0 text-white"
          onClick={() => setZoomFullscreen(!zoomFullscreen)}>
          <div className="absolute bottom-8 right-4 z-50 justify-center space-x-2 rounded-lg  bg-black-rich p-2 text-white  opacity-25 shadow-[0px_8px_8px_rgba(0,0,0,0.25)] transition-[width,height,position,transform] hover:opacity-90">
            {zoomFullscreen ? (
              <ZoomOutOutlined className="text-[32px] text-white" />
            ) : (
              <ZoomInOutlined className="text-[32px]  text-white" />
            )}
          </div>
        </button>
      )}

      {/* End Modal Confirmation */}
      {v2 ? (
        <Modal
          title={
            <Flex align="center" justify="space-between">
              <Title level={4}>
                {isVoiceCall ? confirmModalPropsGE.voiceCall : modalProps.okText}
              </Title>
              <CloseOutlined onClick={handleCloseModalConfirm} />
            </Flex>
          }
          open={openModalConfirm}
          closable={false}
          footer={false}
          width={500}>
          <div className="space-y-2">
            <div className="text-md m-0 py-2">
              {isManualKYC
                ? confirmModalEndSession.description
                : confirmModalEndSession.descriptionVoiceCall}
            </div>
            <Flex gap={10} justify="end">
              <Button variant="secondary" onClick={handleCloseModalConfirm}>
                {confirmModalEndSession.cancelText}
              </Button>
              <Button onClick={() => handleConfirmEndCall({ type: typeModalConfirm })}>
                {confirmModalEndSession.okText}
              </Button>
            </Flex>
          </div>
        </Modal>
      ) : (
        <Modal
          title={
            <div className="flex items-center justify-between">
              <h3 className="m-0 p-0 text-2xl font-semibold">
                {isVoiceCall ? confirmModalPropsGE.voiceCall : modalProps.description}
              </h3>
              <div onClick={handleCloseModalConfirm}>
                <CloseOutlined />
              </div>
            </div>
          }
          open={openModalConfirm}
          closable={false}
          footer={false}
          width={336}>
          <div className="space-y-2">
            <div className="text-md m-0 py-2">{modalProps.description}</div>
            <div className="flex flex-col space-y-3">
              <button
                className="cursor-pointer rounded-3xl border-0 bg-orange-danger py-2 font-sans text-base font-semibold text-white outline-none duration-200 ease-out hover:bg-[#b34307]"
                onClick={() => handleConfirmEndCall({ type: typeModalConfirm })}>
                {isVoiceCall ? confirmModalPropsGE.voiceCall : modalProps.okText}
              </button>
              <button
                className="cursor-pointer rounded-3xl bg-transparent py-2 font-sans text-base font-semibold duration-200 ease-out hover:bg-slate-200"
                onClick={handleCloseModalConfirm}>
                {modalProps.cancelText}
              </button>
            </div>
          </div>
        </Modal>
      )}

      <Modal
        title={
          <div className="flex items-center justify-between">
            <h3 className="m-0 p-0 text-2xl font-semibold">{confirmModalEndSession.title}</h3>
            <div onClick={handleCloseDisconnectModal}>
              <CloseOutlined />
            </div>
          </div>
        }
        open={disconnectModalConfirm}
        closable={false}
        footer={false}
        width={500}>
        <div className="space-y-2">
          <div className="text-md m-0 py-2">
            {confirmMinutesDescription(durationReminder || 15, selectedCall?.name || 'pelanggan')}
          </div>
          <div className="flex justify-end gap-2">
            <button
              className="cursor-pointer rounded-lg border-2 border-solid border-green-500 bg-white py-2 font-sans text-base font-semibold text-green-500 outline-none duration-200 ease-out "
              onClick={() => handleConfirmEndCall({ type: typeModalConfirm })}>
              {confirmModalCustomerDisconnected.okText}
            </button>
            <button
              className="cursor-pointer rounded-lg border-none bg-green-500 py-2 font-sans text-base font-semibold text-white duration-200 ease-out "
              onClick={handleCloseDisconnectModal}>
              {confirmModalCustomerDisconnected.cancelText}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VideoCall;
