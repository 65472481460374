import { Flex, Modal } from 'antd';
import { Button, Icon, Title } from '@squantumengine/horizon';
import { ModeTypeEnum } from './kyc.interface';

interface ExpandedPhotoModalProps {
  isVisible: boolean;
  expandedImageUrl: string | null;
  isKTPMode: boolean;
  handleCancel: () => void;
  handleSave: () => void;
  handleGoBack: () => void;
}

const ExpandedPhotoModal = ({
  isVisible,
  expandedImageUrl,
  isKTPMode,
  handleCancel,
  handleSave,
  handleGoBack
}: ExpandedPhotoModalProps) => {
  return (
    <Modal
      open={isVisible}
      onCancel={handleCancel}
      footer={
        <Flex gap={12} justify="end" align="center">
          <Button variant="secondary" onClick={handleCancel}>
            Kembali
          </Button>
          <Button onClick={handleSave}>
            Pilih sebagai {isKTPMode ? ModeTypeEnum.KTP : ModeTypeEnum.SELFIE}
          </Button>
        </Flex>
      }
      centered
      width={800}
      title={
        <Flex gap={10} align="center">
          <Button variant="text" onClick={handleGoBack}>
            <Icon className="cursor-pointer" name="arrow-left" />
          </Button>
          <Title level={4}>Pilih Foto {isKTPMode ? ModeTypeEnum.KTP : ModeTypeEnum.SELFIE}</Title>
        </Flex>
      }>
      <div className="flex justify-center">
        <img src={expandedImageUrl!} alt="Expanded View" className="w-full rounded-lg" />
      </div>
    </Modal>
  );
};

export default ExpandedPhotoModal;
