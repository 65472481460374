export const createAudioPlayer = (url: string, loop: boolean = false) => {
  return {
    audio: new Audio(url),

    play: function () {
      this.audio.loop = loop;
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.currentTime = 0;
        this.audio.play();
      }
    },

    stop: function () {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  };
};

const audioPlayers: Record<string, ReturnType<typeof createAudioPlayer>> = {};

export const playVideoNotification = (id: string = 'default', loop: boolean = false) => {
  let player = audioPlayers[id];
  if (!player) {
    player = createAudioPlayer(require('../assets/sounds/video-call.mp3'), loop);
    audioPlayers[id] = player;
  }
  player.play();
};

export const stopVideoNotification = (id: string = 'default') => {
  const player = audioPlayers[id];
  player && player.stop();
};

export const playChatNotification = (id: string = 'default', loop: boolean = false) => {
  let player = audioPlayers[id];
  if (!player) {
    player = createAudioPlayer(require('../assets/sounds/chat-notification.mp3'), loop);
    audioPlayers[id] = player;
  }
  player.play();
};

export const stopChatNotification = (id: string = 'default') => {
  const player = audioPlayers[id];
  player && player.stop();
};
