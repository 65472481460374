import ChooseResult from './choose-result';
import {
  ChooseTypeEnum,
  KYCVerdictResponse,
  StatusKYCEnum,
  StatusKYCResultEnum
} from '../../kyc/kyc.interface';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { useEffect } from 'react';
import { useChatListStore } from '../../../store/chat-list';
import { isNullOrUndefined } from '../../../utils/isNullUndefined';
import { Paragraph } from '@squantumengine/horizon';
import { Flex } from 'antd';
import { getIconUrl } from '../../../utils/getIconUrl';

function KYCResponse({ kycVerdict }: { kycVerdict: KYCVerdictResponse | undefined }) {
  const { chooseKTP, chooseSelfie, resetAndSetKYCState } = useSelectedCustomerStore();

  const { selectedCall } = useChatListStore();

  useEffect(() => {
    const ktp = kycVerdict?.ktpObjectName
      ? {
          documentName: 'ktp.png',
          documentUrl: kycVerdict.ktpObjectName,
          documentId: kycVerdict.ktpObjectName
        }
      : null;

    const selfie = kycVerdict?.selfieObjectName
      ? {
          documentName: 'selfie.png',
          documentUrl: kycVerdict.selfieObjectName,
          documentId: kycVerdict.selfieObjectName
        }
      : null;

    resetAndSetKYCState(ktp, selfie);

    // eslint-disable-next-line
  }, [kycVerdict, selectedCall]);

  return (
    <Flex vertical gap={10}>
      <Flex vertical align="center">
        <img src={getIconUrl('check-splash-blue.svg')} alt="check-splash" className="w-16" />
        <Paragraph size="xl" className="m-0 p-0 font-semibold">
          Data tersimpan
        </Paragraph>
        <Paragraph className="m-0 p-0">Anda bisa mengakhiri panggilan video.</Paragraph>
      </Flex>
      <ChooseResult data={chooseKTP!} isKYCSubmitted={true} name={ChooseTypeEnum.KTP} />
      <ChooseResult data={chooseSelfie!} isKYCSubmitted={true} name={ChooseTypeEnum.SELFIE} />
      <Flex justify="space-between">
        <Paragraph className="text-nowrap text-neutral-600">Status KYC:</Paragraph>
        <Paragraph
          className={`truncate ${
            kycVerdict?.status === StatusKYCEnum.COMPLETED ? 'text-blue-500' : 'text-red-500'
          }`}>
          {kycVerdict?.status === StatusKYCEnum.COMPLETED
            ? StatusKYCResultEnum.APPROVED
            : StatusKYCResultEnum.FAILED}
        </Paragraph>
      </Flex>
      {!isNullOrUndefined(kycVerdict?.reason) && (
        <div className="flex flex-row justify-between gap-2">
          <Paragraph className="text-nowrap text-neutral-600">Alasan Penolakan:</Paragraph>
          <Paragraph className="truncate">{kycVerdict?.reason}</Paragraph>
        </div>
      )}
    </Flex>
  );
}

export default KYCResponse;
