import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Row } from 'antd';
import { Icon, Paragraph } from '@squantumengine/horizon';

import { useConversationListStore } from '../../../../store/conversation-list-state';
import { createCallListAgentComponent, createCallListComponent } from '../dashboard.helper';

import ArrowPagination from '../../../../components/v2/pagination/arrow-pagination';

import useConversation from '../../../../hooks/useConversation';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import SkeletonTicket from './skeleton-ticket';
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll';
import CollapseAgent from './collapse-agent';
import useInboxId from '../../../../hooks/useInboxId';

type TicketInactiveProps = {
  filter: {
    email: string;
    name: string;
    ticketNumber: string;
    phoneNumber: string;
    startDate: string;
    endDate: string;
  };
};

export type TicketInactiveComponent = {
  refetchTicketInactive: () => void;
  isFetching: () => boolean;
};

const TicketDone = forwardRef<TicketInactiveComponent, TicketInactiveProps>(
  ({ filter: propsFilter }: TicketInactiveProps, ref) => {
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState(propsFilter);
    const [expand, setExpand] = useState(true);
    const { closedConversationList } = useConversationListStore();
    const { userRole } = useUserStatusStore();
    const isAgent = userRole === RoleEnum.AGENT;

    const inboxId = useInboxId();

    const { data, refetch, isFetching } = useConversation({
      filter,
      active: false,
      limit: 9,
      page,
      shouldAppendConversation: isAgent
    });

    const isPrevDisabled = page === 1;
    const isNextDisabled = closedConversationList.length % 9 !== 0;

    const { lastItemElementRef } = useInfiniteScroll({
      isFetching,
      hasMore: !isNextDisabled,
      loadMore: () => {
        setPage((prev) => {
          return prev + 1;
        });
      }
    });

    const handlePrevPage = () => {
      if (isPrevDisabled) return;
      setPage((prev) => prev - 1);
    };

    const handleNextPage = () => {
      if (isNextDisabled) return;
      setPage((prev) => prev + 1);
    };

    const reInitialize = () => {
      setPage(() => {
        refetch();
        return 1;
      });
    };

    useImperativeHandle(ref, () => ({
      refetchTicketInactive: reInitialize,
      isFetching: () => isFetching
    }));

    useEffect(() => {
      setPage(1);
      setFilter(propsFilter);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      inboxId,
      propsFilter.startDate,
      propsFilter.endDate,
      propsFilter.email,
      propsFilter.ticketNumber,
      propsFilter.phoneNumber,
      propsFilter.name
    ]);

    if (isAgent) {
      return (
        <CollapseAgent
          onChange={() => {
            if (isAgent) setExpand(!expand);
          }}
          label={
            <div className="flex items-center justify-between  bg-neutral-50 px-4 py-2">
              <Paragraph size="r" weight="bold">
                Ticket Selesai ({data?.count || 0})
              </Paragraph>
              <Icon name={expand ? 'chevron-small-up' : 'chevron-small-down'} />
            </div>
          }
          children={
            <Row className={`grid grid-cols-1 bg-white`}>
              {createCallListAgentComponent({
                conversations: closedConversationList,
                lastItemElementRef
              })}
            </Row>
          }
        />
      );
    }

    return (
      <div>
        <div className="flex items-center justify-between bg-neutral-50 px-4 py-2">
          <Paragraph size="r" weight="bold">
            Ticket Selesai ({closedConversationList.length})
          </Paragraph>
          {!isAgent && (
            <ArrowPagination
              disableNext={isNextDisabled}
              disablePrev={isPrevDisabled}
              onClickNext={handleNextPage}
              onClickPrev={handlePrevPage}
            />
          )}
        </div>

        {isFetching && !isAgent && <SkeletonTicket />}

        {closedConversationList.length > 0 && (
          <Row className={`grid grid-cols-3 gap-4 bg-white p-4`}>
            {createCallListComponent(closedConversationList)}
          </Row>
        )}
      </div>
    );
  }
);

export default TicketDone;
