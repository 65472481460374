import { Button, Flex, Typography } from 'antd';
import { TableColumnTypeDef, TableRowTypeModifier, Token } from '@squantumengine/horizon';
import ChatIcon from '../../../assets/icons/chat';
import VideoIcon from '../../../assets/icons/video';
import VoiceCallIcon from '../../../assets/icons/voice-call';
import { InboxListItemInterface } from '../../../shared/types/inbox.interface';
import { CommunicationTypeEnum } from '../../../static/communication-type';
import { useGetSPVTeam } from '../../../api/hooks/team/useGetSPVTeam';
import { Link } from 'react-router-dom';
import { WhatsAppSupportModeNameEnum } from '../../../shared/types/whatsapp.interface';
import { ChannelEnum } from '../create-edit/type';

type ConversationMethodProps = {
  label: string;
  icon: JSX.Element;
  key: number;
  val: Array<string>;
};

export enum ChannelTypeEnum {
  WEB_WIDGET = 'Web Widget',
  WHATSAPP = 'WhatsApp'
}

enum ConversationContextEnum {
  MANUAL_KYC = 'Manual KYC',
  GENERAL = 'Umum'
}

const { TABLE_HORIZONTAL_ALIGN, TABLE_VERTICAL_ALIGN } = Token;

const _renderSPVMember = (accountId: string) => {
  const { data } = useGetSPVTeam(accountId);

  return (
    <>
      {data?.map((value, index) => (
        <Typography.Text className="m-0 p-0 text-gray-800" key={index}>
          {value?.firstName}
          {index < data?.length - 1 && ','}&nbsp;
        </Typography.Text>
      ))}
    </>
  );
};

const _renderAction = (inboxId: string) => {
  return (
    <Link to={`/v2/dashboard/team/detail/${inboxId}`}>
      <Button type="text" className="text-blue-500">
        Detail
      </Button>
    </Link>
  );
};

const _renderConversationMethod = (props: ConversationMethodProps) => {
  const { label, icon, key, val } = props;

  return (
    <Flex
      key={key}
      align="center"
      gap={8}
      className={`w-fit rounded-md border border-solid border-gray-300 bg-neutral-100 px-2 py-0.5 ${
        key < val.length - 1 && 'mb-2'
      }`}>
      {icon}
      <Typography.Text className="font-semibold !text-gray-500">{label}</Typography.Text>
    </Flex>
  );
};

const renderChannel = (row: any) => {
  const val = row?.original?.channelType as keyof typeof ChannelTypeEnum;
  const supportMode = row?.original?.inboxConfig
    ?.supportMode as keyof typeof WhatsAppSupportModeNameEnum;

  if (val === ChannelEnum.WHATSAPP && supportMode)
    return `${ChannelTypeEnum[val]} - ${WhatsAppSupportModeNameEnum[supportMode]}`;

  return ChannelTypeEnum[val];
};

export type ModifiedTeamType = TableRowTypeModifier<InboxListItemInterface>;

export const tableColumns: TableColumnTypeDef<ModifiedTeamType> = [
  {
    header: 'Nama Tim',
    accessorKey: 'name',
    cell: ({ cell, row }) => {
      return (
        <Typography.Text className="m-0 p-0 text-gray-800">{row?.original?.name}</Typography.Text>
      );
    },
    width: 200,
    horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
    headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle
  },
  {
    header: 'Channel',
    accessorKey: 'channelType',
    cell: ({ cell, row }) => {
      return (
        <Typography.Text className="m-0 p-0 text-gray-800">{renderChannel(row)}</Typography.Text>
      );
    },
    width: 200,
    horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
    headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
    isIgnoreLoading: true,
    customSkeletonWidth: 20,
    enableSorting: false
  },
  {
    header: 'Tipe Percakapan',
    accessorKey: 'inboxConfig.conversationContext',
    cell: ({ cell, row }) => {
      const conversationContext = row?.original?.inboxConfig
        ?.conversationContext as keyof typeof ConversationContextEnum;

      return (
        <Typography.Text className="m-0 p-0 text-gray-800">
          {ConversationContextEnum[conversationContext]}
        </Typography.Text>
      );
    },
    width: 200,
    horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
    headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
    isIgnoreLoading: true,
    customSkeletonWidth: 20,
    enableSorting: false
  },
  {
    header: 'Metode Percakapan',
    accessorKey: 'inboxConfig.communicationModes',
    cell: ({ cell, row }) => {
      const communicationModes = row?.original?.inboxConfig?.communicationModes;
      return communicationModes?.map((value, index) => {
        if (value === CommunicationTypeEnum.CHAT) {
          return _renderConversationMethod({
            label: 'Chat',
            icon: <ChatIcon className="!text-gray-500" />,
            key: index,
            val: communicationModes
          });
        } else if (value === CommunicationTypeEnum.VOICE) {
          return _renderConversationMethod({
            label: 'Panggilan Suara',
            icon: <VoiceCallIcon className="!text-gray-500" />,
            key: index,
            val: communicationModes
          });
        } else {
          return _renderConversationMethod({
            label: 'Panggilan Video',
            icon: <VideoIcon className="!text-gray-500" />,
            key: index,
            val: communicationModes
          });
        }
      });
    },
    width: 200,
    horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
    headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
    isIgnoreLoading: true,
    customSkeletonWidth: 20,
    enableSorting: false
  },
  {
    header: 'Supervisor',
    cell: ({ cell, row }) => {
      return _renderSPVMember(row?.original?.accountId!);
    },
    width: 200,
    horizontalAlign: TABLE_HORIZONTAL_ALIGN.left,
    headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle,
    isIgnoreLoading: true,
    customSkeletonWidth: 20,
    enableSorting: false
  },
  {
    header: 'Total Agen',
    accessorKey: 'totalAgent',
    cell: ({ cell, row }) => {
      return (
        <Typography.Text className="m-0 p-0 text-gray-800">
          {row?.original?.totalAgent}
        </Typography.Text>
      );
    },
    width: 200,
    horizontalAlign: TABLE_HORIZONTAL_ALIGN.center,
    headerVerticalAlign: TABLE_VERTICAL_ALIGN.middle
  },
  {
    header: 'Action',
    id: 'action',
    cell: ({ cell, row }) => {
      return _renderAction(row?.original?.inboxId);
    }
  }
];
