/**
 * The `ChatBox` component is responsible for rendering the chat interface, including the chat messages, chat footer, and video call functionality.
 *
 * It manages the state of the chat, including the current chat message, typing status, and the list of chat messages. It also handles the loading of previous chat messages and the scrolling of the chat container.
 *
 * The component uses various hooks and stores to manage the state and functionality of the chat, including the `useCallStore`, `useUserStatusStore`, `useChatListStore`, and `useTeamStore`.
 *
 * The `ChatBox` component also handles the end call and end ticket functionality, as well as the rendering of the `CustomerAction` component.
 *
 * @param {ChatBoxInterface} props - The props for the `ChatBox` component, including `onClose`, `withoutVideo`, and `showCustomerAction`.
 * @returns {JSX.Element} - The rendered `ChatBox` component.
 */
import { useEffect, useRef, useState } from 'react';
import ChatMessage from '../../chat-message';
import { ChatBoxInterface } from './chatbox.interface';
import { ChatMessageInterface } from '../../chat-message/chat-message.interface';
import { postWidgetMessage } from '../../../../../config/send-messages';
import { sendTypingStatus } from '../../../../../config/send-messages/typing';
import { messageHistory } from '../../../../../config/message-history';
import ChatSlashIcon from '../../../../../assets/icons/chat-slash';
import {
  CloseConversationEnum,
  ConversationStatusEnum,
  MessageEventEnum
} from '../../../../../static/message-event';
import { useCallStore } from '../../../../../store/call-state';
import InfiniteScroll from 'react-infinite-scroller';
import VideoCall from '../../../../video-call';
import { useUserStatusStore } from '../../../../../store/user-state';
import { RoleEnum } from '../../../../../static/role';
import { useChatListStore } from '../../../../../store/chat-list';
import {
  CommunicationTypeEnum,
  defaultCommunicationTypeEnum
} from '../../../../../static/communication-type';
import VideoRequest, { leaveMeeting } from '../../../../../utils/webSocket/videoRequest';
// import { Spin } from 'antd';
import { getSelectedCallJSON, setSelectedCallJSON } from '../../../../../utils/user-json';
import { CurrentUserObjectInterface } from '../../../../../shared/types/user.interface';
import { DeleteCall } from '../../../../../api/call';
import { meetingRoomId } from '../../../../../utils/webSocket';
import { useGreetingMessage } from '../../../../../hooks/useGreetingMessage';
import { useTeamStore } from '../../../../../store/team-state';
import CustomerAction from '../customer-action';
import ChatFooterV2 from '../../chat-footer/index';
import Divider from '../divider';
import { TabMenuEnum } from '../../chat-footer/chat-footer.interface';
import { ChannelEnum } from '../../../../../pages/team/create-edit/type';
import IncomingChatCall from '../../incoming-call/incomingChatcall';
import SummaryModal from '../../close-session/summary-modal';
import { useInboxStore } from '../../../../../store/new/inbox';

function ChatBox({ onClose, withoutVideo, showCustomerAction = true }: ChatBoxInterface) {
  const [chat, setChat] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [chatList, setChatList] = useState<ChatMessageInterface[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [initialMessagesLoaded, setInitialMessagesLoaded] = useState(false);
  const [previousMessagesLoaded, setPreviousMessagesLoaded] = useState(false);
  const [chatFooterTab, setChatFooterTab] = useState<string>('');

  const {
    setShowVideo,
    setFullScreen,
    showVideo,
    showChat,
    setShowChat,
    setMeetingRoomId,
    websocketConnected,
    isVideoRequested,
    setIsVideoRequested,
    setIsVoiceCall,
    setShowCameraButton,
    isOpenModalSummaryConfirm
  } = useCallStore();
  const { userRole } = useUserStatusStore();
  const { selectedCall, allChatList } = useChatListStore();
  const { selectedInbox } = useTeamStore();
  useGreetingMessage(chatList, setChatList, selectedInbox);

  const { isSelectedInboxManualKyc } = useInboxStore();
  const isManualKYC = isSelectedInboxManualKyc();

  const filteredChatlist = allChatList.filter(
    (chat) => chat.conversationId === selectedCall?.conversationId
  );

  const mergedArray = [...chatList, ...filteredChatlist];

  const filteredArray = mergedArray.reduce((acc: ChatMessageInterface[], obj) => {
    const existingObj = acc.find((item) => item.id === obj.id);
    if (!existingObj) {
      acc.push(obj);
    }
    return acc;
  }, []);

  const conversationId = selectedCall?.conversationId || '';
  const isAllowTxtMsg = selectedCall?.inboxConfig?.allowTextMessage;
  const isChatTabRingkasan = chatFooterTab === TabMenuEnum.SEND_SUMMARY;
  const isCallEnded = sessionStorage.getItem(`end-call-${meetingRoomId}`);

  const scrollToBottom = () => {
    const chatContainer = scrolldata?.current;
    setTimeout(() => {
      chatContainer?.scrollBy({ top: chatContainer.scrollHeight, left: 0, behavior: 'smooth' });
    }, 210);
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    setChat(e.target.value);
  };

  const handleEndCall = () => {
    if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) leaveMeeting();
    setShowModal(false);
    setShowVideo(false);
    onClose && onClose(CloseConversationEnum.PRECLOSE);
  };

  const addChat = (e: any) => {
    e.preventDefault();
    if (chat) {
      postWidgetMessage(chat, [], 'TEXT', conversationId);
      setChat('');
      scrollToBottom();
    }
  };

  useEffect(() => {
    if (chat && !isTyping) {
      sendTypingStatus(true, conversationId);
      setIsTyping(true);
    } else if (!chat && isTyping) {
      sendTypingStatus(false, conversationId);
      setIsTyping(false);
    }
  }, [chat, conversationId, isTyping]);

  useEffect(() => {
    if (conversationId) {
      setChatList(filteredChatlist);
      setChatFooterTab(TabMenuEnum.SEND_CHAT);
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    setHasMore(true);

    if (userRole === RoleEnum.SUPERVISOR) {
      setChatList([]);
    }
  }, [selectedCall?.conversationId, userRole]);

  const scrolldata = useRef<HTMLDivElement>(null);

  const transformMessage = (data: any): ChatMessageInterface =>
    ({
      id: data?.messageId,
      message: data?.content,
      time: data?.timestamp || Date.now(),
      isUser: data?.senderType === MessageEventEnum.CONTACT,
      documents: data?.documents,
      type: data?.documents
        ? MessageEventEnum.FILE
        : data?.messageType === MessageEventEnum.MEDIA_CALL
        ? MessageEventEnum.CALL
        : MessageEventEnum.TEXT,
      name: data?.sender?.name,
      conversationId: conversationId
    } as ChatMessageInterface);

  /**
   * Loads the message history for the current conversation.
   *
   * @param isInitial - Indicates whether this is the initial load of messages.
   *                    If true, the messages will be loaded from the beginning of the conversation.
   *                    If false, the messages will be loaded from the last message ID provided.
   * @param lastId - The ID of the last message to load. If provided, the messages will be loaded starting from this ID.
   * @returns A Promise that resolves to the transformed message list.
   */
  const loadMessages = async (isInitial: boolean, lastId?: string) => {
    const res = await messageHistory(conversationId, lastId);
    const transformedMessageList = res?.messageList?.map(transformMessage);
    if (transformedMessageList) {
      setChatList((prevList) =>
        isInitial
          ? [...transformedMessageList.reverse()]
          : [...transformedMessageList.reverse(), ...prevList]
      );
      if (res?.messageList?.length === 0) {
        setHasMore(false);
      }
    }
  };

  /**
   * Loads the previous messages for the current conversation.
   * If there are previous messages, it loads them by calling `loadMessages` with the ID of the first message in the current chat list.
   * If there are no previous messages, it loads the initial messages by calling `loadMessages` without a last ID.
   * The function sets flags to track whether previous or initial messages have been loaded to avoid loading the same messages multiple times.
   */
  const loadPreviousMessages = async () => {
    let getLastId = chatList[0]?.id;
    if (getLastId === 'greeting') getLastId = chatList[1]?.id;

    if (getLastId) {
      // If the last message in the chat list is the greeting message, the last message ID is the second message in the chat list.
      // The previous messages should be loaded.
      if (previousMessagesLoaded) return;
      setPreviousMessagesLoaded(true);
      await loadMessages(false, getLastId);
      setPreviousMessagesLoaded(false);
    } else {
      // If the last message in the chat list is not the greeting message, the last message ID is undefined.
      // The initial messages should be loaded.
      if (initialMessagesLoaded) return;
      setInitialMessagesLoaded(true);
      await loadMessages(true);
      setInitialMessagesLoaded(false);
    }
  };

  const isWhatsappInbox = selectedInbox?.channelType === ChannelEnum.WHATSAPP;
  const isActiveCall = selectedCall?.event === MessageEventEnum.ACTIVE;
  const shouldShowFooter =
    userRole === RoleEnum.AGENT &&
    (selectedCall?.event === MessageEventEnum.SESSION_CLOSED || isActiveCall) &&
    (isAllowTxtMsg || !isManualKYC);

  const handleAnswerCall = () => {
    const defaultCommunicationType =
      selectedCall?.inboxConfig?.mediaCallConfiguration.defaultCommunicationType;

    const isAudioOnly = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO;
    const isAudioVideo = defaultCommunicationType === defaultCommunicationTypeEnum.AUDIO_VIDEO;
    const isVoiceCall = isAudioOnly || isAudioVideo;
    // switched condition when customer request from audio call to video call
    const isSwitched =
      selectedCall?.metadata?.communicationType === defaultCommunicationTypeEnum.VIDEO;
    VideoRequest({ audio: true, video: !isVoiceCall });
    setIsVoiceCall((isAudioVideo || isAudioOnly) && !isSwitched);
    setShowCameraButton(isSwitched || !isVoiceCall);
    setShowVideo(true);
  };

  let firstRender = useRef(true);
  useEffect(() => {
    if (
      firstRender.current &&
      localStorage.meetingRoomId &&
      websocketConnected &&
      !withoutVideo &&
      !isVideoRequested
    ) {
      if (!isCallEnded) {
        setIsVideoRequested(true);
        handleAnswerCall();
      }
      setMeetingRoomId(localStorage.meetingRoomId);
      firstRender.current = false;
    }
    // eslint-disable-next-line
  }, [websocketConnected, isVideoRequested]);

  useEffect(() => {
    const isAbleToRefresh = localStorage.meetingRoomId;
    if (isAbleToRefresh && isManualKYC && !isCallEnded) setFullScreen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManualKYC]);

  const currentUser: CurrentUserObjectInterface = JSON.parse(localStorage.getItem('user') || '{}');
  const accountId = currentUser.account?.accountId || '';
  const addNotesHandler = () => {
    if (selectedCall && selectedCall.event === MessageEventEnum.ACTIVE) {
      if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) leaveMeeting();
      setShowVideo(false);
      setFullScreen(false);
      const selectedCallJSON = getSelectedCallJSON();
      selectedCallJSON.event = MessageEventEnum.SESSION_CLOSED;
      setShowChat(false);
      setSelectedCallJSON(JSON.stringify(selectedCallJSON));
      localStorage.removeItem('meetingRoomId');
      localStorage.removeItem('isSwicthed');
      onClose && onClose(CloseConversationEnum.PRECLOSE);
      // if no video interaction happened, we also skipped DeleteCall
      if (accountId && localStorage.videoMessageId)
        DeleteCall(accountId, conversationId, localStorage.videoMessageId);
    }
  };

  const endTicketHandler = () => {
    if (selectedCall && selectedCall.event === MessageEventEnum.SESSION_CLOSED) {
      if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) leaveMeeting();
      setShowVideo(false);

      const selectedCallJSON = getSelectedCallJSON();
      selectedCallJSON.event = MessageEventEnum.INACTIVE;
      setSelectedCallJSON(JSON.stringify(selectedCallJSON));

      onClose && onClose(CloseConversationEnum.CLOSE);
      setShowVideo(false);
      setFullScreen(false);
      setShowChat(false);
      setMeetingRoomId('');
      localStorage.removeItem('meetingRoomId');
      localStorage.removeItem('isSwicthed');
      localStorage.removeItem('isKYCSubmitted');
    }
  };

  const isCallAvailable = filteredArray.filter(
    (items: any) => items.type === MessageEventEnum.CALL
  );
  const RenderChatMessages = () => {
    return filteredArray
      ?.filter((chat) => {
        if (isWhatsappInbox) return true;
        if (isCallEnded && chat.type === MessageEventEnum.CALL) return false;
        if (!isAllowTxtMsg) return chat?.type !== MessageEventEnum.TEXT;
        else return chat;
      })
      .map((chat, index) => (
        <div key={index}>
          <ChatMessage {...chat} conversationId={conversationId} />
        </div>
      ));
  };

  const showIncomingChatCall =
    conversationId === selectedCall?.conversationId &&
    isCallAvailable.length > 0 &&
    userRole === RoleEnum.AGENT &&
    !showVideo &&
    [ConversationStatusEnum.IN_PROGRESS, ConversationStatusEnum.OPEN].includes(
      selectedCall?.conversationStatus as ConversationStatusEnum
    ) &&
    !isCallEnded;

  return (
    <>
      <div
        className={`relative flex ${
          showChat ? 'h-max' : 'h-full'
        } min-h-[550px] flex-col overflow-hidden border-0 border-b border-solid border-b-grey-50 bg-white`}>
        {showCustomerAction && (
          <CustomerAction
            addNotesHandler={addNotesHandler}
            endTicketHandler={endTicketHandler}
            handleEndCall={handleEndCall}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        )}
        <SummaryModal
          openModalConfirm={isOpenModalSummaryConfirm}
          onClickEndTicket={endTicketHandler}
        />
        {showVideo && !withoutVideo && !isCallEnded && <VideoCall v2 />}
        {showIncomingChatCall && (
          <IncomingChatCall
            messageId={isCallAvailable[0].id}
            conversationId={conversationId}
            startTime={isCallAvailable[0].time}
          />
        )}
        {selectedCall ? (
          <div
            id="chat-container"
            ref={scrolldata}
            className={`${showChat && 'max-h-[500px]'} ${
              showVideo || isCallAvailable ? 'h-max' : 'h-full '
            } ${
              shouldShowFooter ? (isChatTabRingkasan ? 'mb-80' : 'mb-44') : 'mb-0'
            } relative  w-full overflow-y-auto pt-4`}>
            <InfiniteScroll
              loadMore={loadPreviousMessages}
              initialLoad
              isReverse
              useWindow={false}
              pageStart={0}
              hasMore={hasMore}
              getScrollParent={() => scrolldata.current}>
              <div className="pb-[50px]">
                <Divider
                  className="mb-2"
                  time={selectedCall.startTime}
                  conversationId={conversationId}
                  contactId={selectedCall.contactId}
                  inboxId={selectedInbox.inboxId}
                  isOfflineOrResolverChat={
                    selectedCall.conversationStatus === ConversationStatusEnum.RESOLVED
                  }
                />
                {RenderChatMessages()}
              </div>
            </InfiniteScroll>
          </div>
        ) : (
          <div className="flex w-full flex-1 flex-col items-center justify-center text-grey-100">
            <div className="scale-[200%]">
              <ChatSlashIcon />
            </div>
            <p className="text-lg font-semibold">Belum ada chat</p>
          </div>
        )}
        {shouldShowFooter && (
          <div className="absolute bottom-0 w-full">
            <ChatFooterV2
              chat={chat}
              addChat={addChat}
              handleChange={handleChange}
              onTabChange={setChatFooterTab}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ChatBox;
