import ChooseResult from './choose-result';
import {
  ChooseTypeEnum,
  KYCVerdictResponse,
  StatusKYCEnum,
  StatusKYCResultEnum
} from './kyc.interface';
import { useSelectedCustomerStore } from '../../store/customer-state';
import { useEffect } from 'react';
import { useChatListStore } from '../../store/chat-list';
import { isNullOrUndefined } from '../../utils/isNullUndefined';

function KYCResult({ kycVerdict }: { kycVerdict: KYCVerdictResponse | undefined }) {
  const { chooseKTP, chooseSelfie, resetAndSetKYCState } = useSelectedCustomerStore();

  const { selectedCall } = useChatListStore();

  useEffect(() => {
    const ktp = kycVerdict?.ktpObjectName
      ? {
          documentName: 'ktp.png',
          documentUrl: kycVerdict.ktpObjectName,
          documentId: kycVerdict.ktpObjectName
        }
      : null;

    const selfie = kycVerdict?.selfieObjectName
      ? {
          documentName: 'selfie.png',
          documentUrl: kycVerdict.selfieObjectName,
          documentId: kycVerdict.selfieObjectName
        }
      : null;

    resetAndSetKYCState(ktp, selfie);

    // eslint-disable-next-line
  }, [kycVerdict, selectedCall]);

  return (
    <div
      className={`my-2 flex w-full flex-col gap-2 rounded-2xl border border-solid border-black p-6`}>
      <div className="flex flex-row items-center gap-2">
        {/* <ArrowLeftOutlined onClick={() => setIsFinishKYC(false)} /> */}
        <p className={`manual-kyc-p text-lg font-semibold`}>Hasil Verifikasi</p>
      </div>
      <ChooseResult
        data={chooseKTP!}
        setData={() => {}}
        isFinishKYC={true}
        name={ChooseTypeEnum.KTP}
      />
      <ChooseResult
        data={chooseSelfie!}
        setData={() => {}}
        isFinishKYC={true}
        name={ChooseTypeEnum.SELFIE}
      />
      <div>
        <p className={`manual-kyc-p mt-4 text-base font-semibold`}>Status KYC</p>
        <p
          className={`manual-kyc-p text-base ${
            kycVerdict?.status === StatusKYCEnum.COMPLETED
              ? 'text-emerald-light-green'
              : 'text-red-600'
          }`}>
          {kycVerdict?.status === StatusKYCEnum.COMPLETED
            ? StatusKYCResultEnum.APPROVED
            : StatusKYCResultEnum.FAILED}
        </p>
      </div>
      {!isNullOrUndefined(kycVerdict?.reason) && (
        <div>
          <p className={`manual-kyc-p text-base font-semibold`}>Alasan</p>
          <p className={`manual-kyc-p break-all text-base`}>{kycVerdict?.reason}</p>
        </div>
      )}
    </div>
  );
}

export default KYCResult;
