import { createConsumer, leaveMeeting, Received, streamTrack } from './videoRequest';
import {
  ConnectWebSocketInterface,
  OnMessageInterface,
  WSConsumerClosed,
  WSMessageCreatedInterface,
  WSNewWebRTCTransportProducer,
  WSTypingInterface,
  WSVideoCallBaseInterface
} from './websocket-interfaces';
import { v4 as uuidv4 } from 'uuid';
import { MessageEventEnum } from '../../static/message-event';
import { useCallStore } from '../../store/call-state';
import { useChatListStore } from '../../store/chat-list';
import { ConversationInterface } from '../../components/card/chat-message/chat-message.interface';
import { useAppStore, useUserStatusStore } from '../../store/user-state';
import { CommunicationTypeEnum, SenderType } from '../../static/communication-type';
import { useConversationListStore } from '../../store/conversation-list-state';
import { RoleEnum } from '../../static/role';
import useAgentActivity from '../../hooks/useAgentActivity';
import { LocalStorageKey } from '../../static/local-storage';
import { sendLogError, sendLogInfo } from '../../config/datadog';
import { useAuthStore } from '../../store/new/auth';
import { useConversationStore } from '../../store/new/conversation';
import { IChatMessage } from '../../types/new/conversation';

export let newSocket: WebSocket;
const requestQueue = new Map();
export let mediaServerProducer: any;
export let meetingRoomId: string = localStorage.meetingRoomId;
let screenShare: boolean = false;

const ConnectWebSocket = (params: ConnectWebSocketInterface) => {
  const wsToken = localStorage.getItem('wsToken');
  const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
  const authProvider = useAuthStore.getState().authProvider;

  let parsedToken = '';

  if (accessToken) {
    try {
      // Attempt to parse the item if it starts with '{', '[', or '"'
      if (/^[\[{"]/.test(accessToken)) {
        parsedToken = JSON.parse(accessToken);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse JSON from localStorage:', error);
    }
  }

  const { onMessage, setRemoteMediaType } = params;
  const url = `${process.env.REACT_APP_WS_URL}/cable?websiteToken=${wsToken}&authToken=${parsedToken}&authenticationPlatform=${authProvider}`;

  newSocket = new WebSocket(url);
  newSocket.onopen = () => {
    // eslint-disable-next-line no-console
    console.log('WebSocket connected.');
    useCallStore.getState().setWebsocketConnected(true);
  };
  newSocket.onmessage = async (event) => {
    const message: OnMessageInterface = JSON.parse(event.data);
    const role = useUserStatusStore.getState().userRole;
    if (message.event === MessageEventEnum.PING) {
      useAppStore.getState().setPingDate(Math.ceil(Date.now() / 1000));
    }
    if (message.event === MessageEventEnum.CUST_HEART_BEAT) {
      const { setStartHeartBeat, setHeartBeat, startHeartBeat } = useAppStore.getState();
      const timeStamp = Math.ceil(Date.now() / 1000);
      setHeartBeat(timeStamp);
      localStorage.setItem('hearthBeat', `${timeStamp}`);
      if (!startHeartBeat) {
        setStartHeartBeat(true);
        localStorage.setItem('startHeartBeat', 'true');
      }
    }
    if (message.event === MessageEventEnum.REQUEST_VIDEO_CALL_EVENT) {
      localStorage.setItem('isSwicthed', message.conversationId);
      useCallStore.getState().setShowCameraButton(true);
    }
    if (message.event === MessageEventEnum.CREATED) {
      if (message.data.attachment.length > 0) {
        useChatListStore.setState({ isNewAttachments: true });
        useCallStore.getState().setInstructionMode(null);
      }
      useConversationStore.getState().setLastMessageMap(message.conversation.conversationId, {
        ...message.data
      } as IChatMessage);
      onMessage && onMessage(message as WSMessageCreatedInterface);
    }
    if (
      message.event === MessageEventEnum.TYPING &&
      message.data.senderType === SenderType.CONTACT
    ) {
      useChatListStore.getState().setIsUserTyping((message as WSTypingInterface).data.typingOn);
    }
    if (message.event === MessageEventEnum.ASSIGNED) {
      const item = message.conversation;
      const newObject: ConversationInterface = {
        id: item.conversationId,
        message: '',
        time: Date.now(),
        isUser: false,
        type: item.communicationType === CommunicationTypeEnum.VIDEO ? 'video' : 'text',
        name: item?.contact?.firstName,
        conversationId: item.conversationId,
        meetingRoomId: item?.meetingRoomId,
        event: role === RoleEnum.SUPERVISOR ? MessageEventEnum.ACTIVE : message?.event,
        contactId: item?.contact?.id,
        startTime: Date.now(),
        agent: item?.agent,
        communicationType: item.communicationType,
        inboxId: message.inbox.inboxId,
        inboxConfig: message.inbox.inboxConfig
      };
      useConversationListStore
        .getState()
        .removeConversation(MessageEventEnum.CREATED, item.conversationId);
      useConversationListStore
        .getState()
        .removeConversation(MessageEventEnum.RECENT_ACTIVITY, item.conversationId);
      useConversationListStore
        .getState()
        .appendConversationList(MessageEventEnum.ASSIGNED, [newObject]);
      useConversationListStore.getState().removeBannerNotificationlist(newObject);
      useConversationListStore.getState().setShouldRefetchConv(true);
    }
    if (message.event === MessageEventEnum.DROPCALL) {
      const conversationId = message.conversationId;
      const createdConversationList = useConversationListStore.getState().createdConversationList;
      const conversation = createdConversationList.filter(
        (item: any) => item.conversationId === message.conversationId
      );
      useConversationListStore
        .getState()
        .removeConversation(MessageEventEnum.CREATED, conversationId);
      useConversationListStore
        .getState()
        .removeConversation(MessageEventEnum.RECENT_ACTIVITY, conversationId);
      useConversationListStore.getState().removeBannerNotificationlist(conversation[0]);
    }
    if (message.event === MessageEventEnum.INCOMING_QUEUE) {
      const newObject: ConversationInterface = {
        id: message.conversationId,
        message: '',
        time: Date.now(),
        isUser: false,
        name: message?.contact?.firstName,
        inboxId: message.inbox.inboxId,
        conversationId: message.conversationId,
        event: MessageEventEnum.CREATED,
        contactId: message.contact?.id,
        startTime: Date.now()
      };
      useConversationListStore.getState().appendBannerNotifiactionlist(newObject);
      useConversationListStore
        .getState()
        .appendConversationList(MessageEventEnum.CREATED, [newObject]);
      useConversationListStore
        .getState()
        .appendConversationList(MessageEventEnum.RECENT_ACTIVITY, [newObject]);
      setTimeout(() => {
        useConversationListStore.getState().removeBannerNotificationlist(newObject);
      }, 5000);
    }

    if (
      message.event === MessageEventEnum.UNASSIGNMENT &&
      (role === RoleEnum.AGENT || role === RoleEnum.SUPERVISOR)
    ) {
      const conversationId = message.conversation.conversationId;
      useConversationListStore
        .getState()
        .removeConversation(MessageEventEnum.ASSIGNED, conversationId);
      useConversationListStore
        .getState()
        .removeConversation(MessageEventEnum.ACTIVE, conversationId);
      useCallStore.getState().removeAttentionChat(conversationId);
      const { selectedCall, resetSelectedCall } = useChatListStore.getState();
      if (selectedCall?.communicationType === CommunicationTypeEnum.VIDEO) {
        localStorage.removeItem('meetingRoomId');
        localStorage.removeItem('isSwicthed');
        localStorage.removeItem('selectedCall');
        localStorage.removeItem('videoMessageId');
        leaveMeeting();
        useCallStore.getState().setShowVideo(false);
        useCallStore.getState().setFullScreen(false);
        resetSelectedCall();
      }
    }
    if (message.event === MessageEventEnum.CLOSURE_CONVERSATION) {
      const assigned = useConversationListStore.getState().assignedConversationList;
      let selected = assigned.filter((item) => item.conversationId === message.conversationId);
      if (selected[0]) {
        selected[0].event = MessageEventEnum.INACTIVE;
        selected[0].endTime = Date.now();
      }
      useConversationListStore
        .getState()
        .removeConversation(MessageEventEnum.ASSIGNED, message.conversationId);
      useConversationListStore
        .getState()
        .appendConversationList(MessageEventEnum.INACTIVE, selected, true);
    }

    const { originalRequestId } = message as WSVideoCallBaseInterface;
    if (originalRequestId && requestQueue.has(originalRequestId)) {
      const resolve = requestQueue.get(originalRequestId);
      resolve(message);
      requestQueue.delete(originalRequestId);
      const reqKey = getKeyByValue(originalRequestId);
      requestQueue.delete(reqKey);
    }
    if (
      (message as WSNewWebRTCTransportProducer).event ===
      MessageEventEnum.NEW_WEBRTC_TRANSPORT_PRODUCER
    ) {
      const consumer = await createConsumer(
        (message as WSNewWebRTCTransportProducer).data.producer
      );
      const mediaType = (message as WSNewWebRTCTransportProducer).data.producer.mediaType;
      Received(consumer, mediaType);
      screenShare === false && mediaType !== 'audio' && setRemoteMediaType(mediaType); //video or screen
      mediaType === 'screen' && setScreenShare(true);
      mediaType === 'screen' && useCallStore.getState().setFullScreen(true);
    }
    if (message.event === MessageEventEnum.MEDIA_SERVER_PRODUCERS) {
      mediaServerProducer = message;
    }
    if ((message as any).event === MessageEventEnum.CONSUMER_PAUSED) {
      if ((message as any).data.consumer.kind === 'video') {
        const remStream = new MediaStream();
        remStream.removeTrack(streamTrack);
        let remoteStream: any = document.getElementById('remote-video');
        remoteStream.srcObject = remStream;
      }
    }
    if ((message as any).event === MessageEventEnum.CONSUMER_RESUMED) {
      if ((message as any).data.consumer.kind === 'video') {
        const remStream = new MediaStream();
        let remoteStream: any = document.getElementById('remote-video');
        remStream.addTrack(streamTrack);
        remoteStream.srcObject = remStream;
      }
    }
    if ((message as WSConsumerClosed).event === MessageEventEnum.CONSUMER_CLOSED) {
      if ((message as WSConsumerClosed).data.consumer.mediaType === 'video') {
        useCallStore.getState().setRemoteVideo(false);
        const remStream = new MediaStream();
        remStream.removeTrack(streamTrack);
        let remoteStream: any = document.getElementById('remote-video');
        if (remoteStream) remoteStream.srcObject = remStream;
      } else if ((message as WSConsumerClosed).data.consumer.mediaType === 'screen') {
        setRemoteMediaType('video');
        setScreenShare(false);
        const remStream = new MediaStream();
        remStream.removeTrack(streamTrack);
        let remoteStream: any = document.getElementById('remote-screen');
        if (remoteStream) remoteStream.srcObject = remStream;
      } else {
        let remoteStream: any = document.getElementById('remote-audio');
        useCallStore.getState().setRemoteSound(false);
        if (remoteStream) remoteStream.srcObject = null;
      }
    }
    if (message.event === MessageEventEnum.SENT_CONVERSATION_SUMMARY) {
      const preClosureList = [...useConversationListStore.getState().preClosureConversationList];
      const index = preClosureList.findIndex(
        (callItem) => callItem.conversationId === message.conversationId
      );
      if (index > -1) {
        preClosureList[index].isSendSummary = true;
        useConversationListStore
          .getState()
          .setConversationList(MessageEventEnum.SESSION_CLOSED, preClosureList);
      }
    }

    if (message.event === MessageEventEnum.AGENT_ONLINE)
      useAgentActivity.getState().addAgentCapacity(message?.agent?.agentId);
    if (message.event === MessageEventEnum.AGENT_OFFLINE)
      useAgentActivity.getState().removeAgentCapacity(message?.agent?.agentId);
    if (role === RoleEnum.SUPERVISOR) {
      if (message.event === MessageEventEnum.AGENT_CAPACITY_CHANGE) {
        useAgentActivity.getState().updateAgentCapacity(message);
      }
    }
    if (message.event === MessageEventEnum.USER_LEFT_MEETING_ROOM) {
      useCallStore.getState().setInstructionMode(null);
    }
  };
  newSocket.onclose = (event) => {
    // eslint-disable-next-line no-console
    console.log(`WebSocket disconnected with code: ${event.code}`);
    useCallStore.getState().setWebsocketConnected(false);
  };
  window.addEventListener('beforeunload', async () => {
    if (newSocket) {
      // await leaveMeeting();
      newSocket.close();
      // eslint-disable-next-line no-console
      console.log('WebSocket disconnected.');
    }
  });
};
export default ConnectWebSocket;
export const CloseWebSocket = () => {
  if (newSocket) {
    newSocket.close();
    // eslint-disable-next-line
    console.log('WebSocket disconnected.');
  }
};
function getKeyByValue<T, U>(value: U): T | undefined {
  let foundKey: T | undefined;
  requestQueue.forEach((val, key) => {
    if (val === value) {
      foundKey = key;
    }
  });
  return foundKey;
}
export const mySignaling = async (originalData: any): Promise<any> => {
  const wsToken = localStorage.getItem('wsToken');
  const accessToken = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);
  let parsedToken = '';

  if (accessToken) {
    try {
      // Attempt to parse the item if it starts with '{', '[', or '"'
      if (/^[\[{"]/.test(accessToken)) {
        parsedToken = JSON.parse(accessToken);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse JSON from localStorage:', error);
    }
  }
  const path = `${process.env.REACT_APP_WS_URL}/cable?websiteToken=${wsToken}&authToken=${parsedToken}&authenticationPlatform=${process.env.REACT_APP_LOGIN_PLATFORM}`;

  const timeout = 15000; // 15 seconds
  const uuid = uuidv4();
  const data = { ...originalData, originalRequestId: uuid };
  let timeoutHandle: NodeJS.Timeout = setTimeout(() => {}, 0);

  const exeptions = [MessageEventEnum.RESTART_ICE];

  const websocketEventPromise = new Promise((resolve) => {
    requestQueue.set(uuid, resolve);
    // Set a timeout to reject the promise if no response is received within the specified timeout period
    timeoutHandle = setTimeout(() => {
      if (!originalData.event.includes(exeptions)) {
        sendLogError(`no respond more than ${timeout / 1000} seconds from websocket key:${key}`, {
          host: window.location.host,
          path: window.location.pathname,
          method: 'websocket',
          api_path: path
        });
      }
    }, timeout);
  });
  const key = JSON.stringify(originalData);
  requestQueue.set(key, uuid);
  newSocket?.send(JSON.stringify(data));
  const response = await websocketEventPromise;
  sendLogInfo(`websocket req event: ${key}`, {
    host: window.location.hostname,
    path: window.location.pathname,
    method: 'websocket',
    api_path: path
  });
  if (timeoutHandle && response) {
    clearTimeout(timeoutHandle); // Clear the timeout if the response is received
  }
  return response;
};
export const mySignalingWithoutRes = (data: any) => {
  newSocket?.send(JSON.stringify(data));
};
export const setMeetingRoomId = (value: string) => {
  meetingRoomId = value;
};

const setScreenShare = (value: boolean) => {
  screenShare = value;
};
