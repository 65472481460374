export const getValueByKeyCaseInsensitive = (obj: { [key: string]: any }, key: string) => {
  if (!obj || !key) return;
  const searchKey = key.toLowerCase();
  const actualKey = Object.keys(obj).find((currentKey) => currentKey.toLowerCase() === searchKey);
  return actualKey ? obj[actualKey] : undefined;
};

export const unionBy = <T>(arr: T[], ...args: (T[] | keyof T | ((item: T) => any))[]): T[] => {
  let iteratee: (item: T) => any = args.pop() as (item: T) => any;

  if (typeof iteratee === 'string') {
    const prop = iteratee;
    iteratee = (item: T) => item[prop as keyof T];
  }

  return arr
    .concat(...(args as T[][]))
    .filter((x, i, self) => i === self.findIndex((y) => iteratee(x) === iteratee(y)));
};