import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@squantumengine/horizon';
import warningIcon from './../../../assets/icons/warning.svg';
import { useAuth } from '../../../hooks/useAuth';
import useIdleDetection from '../../../hooks/useIdleDetection';
import { useEffect, useState } from 'react';
import useCountdownTimer from '../../../hooks/useCountdownTimer';
import { useTeamStore } from '../../../store/team-state';

export default function WarningIddleAutoLogout() {
  const idleTimeInMinutes = 15; // default idleTime is 15 minutes
  const defaultIddleTime = idleTimeInMinutes * 60 * 1000; //convert to miliseconds
  const { logout } = useAuth();
  const { selectedInbox } = useTeamStore();
  const [open, setOpen] = useState<boolean>(false);
  const idleTime = selectedInbox?.inboxConfig?.agentIdleTime || defaultIddleTime;
  const { isIdle, resetTimer } = useIdleDetection(idleTime);
  const { timeLeft, resetTimerCountdown, startTimer, stopTimer } = useCountdownTimer();

  const handleClose = () => {
    resetTimerCountdown();
    stopTimer();
    resetTimer();
    setOpen(false);
  };

  useEffect(() => {
    if (isIdle) {
      setOpen(true);
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  useEffect(() => {
    if (timeLeft < 1) {
      setOpen(false);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <Dialog
      className="flex max-w-[500px] items-start gap-2"
      open={open}
      onClose={handleClose}
      hideCloseBtn>
      <img className="pl-6 pt-6" src={warningIcon} alt="warning" />
      <div>
        <DialogHeader className="pl-0 font-semibold">
          Sesi akan segera berakhir karena Anda tidak aktif
        </DialogHeader>
        <DialogBody className="pl-0 pt-0">
          Sesi Anda akan berakhir dalam <strong>{timeLeft}</strong> detik. Apakah Anda ingin tetap
          terhubung?
        </DialogBody>
        <DialogFooter className="pb-4">
          <div className="hz-flex hz-justify-end hz-gap-2">
            <Button
              variant="secondary"
              size="md"
              onClick={() => {
                logout();
                handleClose();
              }}>
              Log out
            </Button>
            <Button size="md" onClick={handleClose}>
              Tetap Aktif
            </Button>
          </div>
        </DialogFooter>
      </div>
    </Dialog>
  );
}
