import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { local } from './storage';
import { SSOTokenObject } from '../shared/types/auth.interface';
import { LocalStorageKey } from '../static/local-storage';
import { forceLogOut, isUrlExcluded } from './login';
import { maskString } from './maskString';
import { sendLogInfo } from '../config/datadog';
import { CookieNameEnum } from '../static/cookie-management';
import { getCookie } from './cookie-management';

type interceptorSQEIDParams = {
  config: InternalAxiosRequestConfig;
  axiosInstance: AxiosInstance;
};

const interceptorSQEID = ({ config, axiosInstance }: interceptorSQEIDParams) => {
  const tokenObj: SSOTokenObject = JSON.parse(
    local.getItem(LocalStorageKey.SQEID_TOKEN_OBJECT) || '{}'
  );
  const { access_token, expires_in, refresh_token, refresh_expires_in } = tokenObj;
  const tokenCreatedDate: string | null = local.getItem(LocalStorageKey.TOKEN_CREATED_AT);
  const isTokenExistAndValid =
    Object.keys(tokenObj).length > 0 && tokenCreatedDate && expires_in && refresh_expires_in;
  const excludeApi = ['/oidc/token'];

  if (access_token) {
    config.headers.Authorization = `jwt ${access_token}`;
  }

  if (isUrlExcluded(config?.url || '', excludeApi)) return config;

  if (isTokenExistAndValid) {
    const dateExpired = parseInt(tokenCreatedDate) + expires_in * 1000;
    const dateRefreshExpired = parseInt(tokenCreatedDate) + refresh_expires_in * 1000;

    if (Date.now() >= dateExpired) {
      // Check if refresh token is expired
      if (Date.now() >= dateRefreshExpired) {
        forceLogOut();
      } else {
        // Refresh token is not expired, call refresh token API
        axiosInstance
          .post('/oidc/token', {
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_SQEID_CLIENT_ID,
            client_secret: process.env.REACT_APP_SQEID_CLIENT_SECRET,
            refresh_token
          })
          .then((response) => {
            const today = new Date();
            localStorage.setItem(LocalStorageKey.TOKEN_CREATED_AT, today.getTime().toString());
            const newTokenObj: SSOTokenObject = response.data;
            local.setItem(LocalStorageKey.SQEID_TOKEN_OBJECT, JSON.stringify(newTokenObj));
            local.setItem(LocalStorageKey.ACCESS_TOKEN, JSON.stringify(newTokenObj.access_token));
            config.headers.Authorization = `jwt ${newTokenObj.access_token}`;

            const tokenData = {
              ...response?.data,
              access_token: maskString(response?.data?.access_token, 5, 5),
              id_token: maskString(response?.data?.id_token, 5, 5),
              refresh_token: maskString(response?.data?.access_token, 5, 5)
            };

            sendLogInfo(`[${response?.status}] Refresh Token`, {
              host: window.location.hostname,
              path: window.location.pathname,
              api_path: response?.config?.url || '',
              method: response?.config?.method || '',
              status_code: response?.status,
              request_body: tokenData
            });
          })
          .catch((err) => {
            forceLogOut();
          });
      }
    }
  }
  return config;
};

const interceptorNATIVE = (config: InternalAxiosRequestConfig) => {
  const nativeAccessToken = getCookie(CookieNameEnum.NATIVE_TOKEN);
  const excludeApi = [
    'agent/login',
    'user/password/reset',
    'agent/password',
    'authentication/provider',
    'user/validate/reset-link'
  ];

  if (!nativeAccessToken) {
    config.headers.Authorization = null;

    if (isUrlExcluded(config?.url || '', excludeApi)) return config;

    forceLogOut();

    const controller = new AbortController();
    controller.abort();

    return { ...config, signal: controller.signal };
  }

  config.headers.Authorization = `jwt ${nativeAccessToken}`;

  return config;
};

const interceptor = {
  SQEID: interceptorSQEID,
  NATIVE: interceptorNATIVE
};

export default interceptor;
