export const SUPERVISOR_COLUMN_SIZE = 3;

export const SUPERVISOR_CONVERSATION_PER_PAGE = 10;

export const AGENT_CONVERSATION_PAGE_SIZE = 10;

export const OFFLINE_CONVERSATION_PAGE_SIZE = 6;

export const DISABLE_BTN_DURATION = 3000;

export const WAITING_TIME_THRESHOLD = 10; // in seconds
export const NOTIFICATION_TIMEOUT = 6000; // in seconds max number to keep notification

export enum ImgTypeEnum {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg'
}

export interface selectItem {
  label: string;
  value: string;
}

export const maxChatSelection: selectItem[] = [
  { label: '1 Chat', value: '1' },
  { label: '2 Chat', value: '2' },
  { label: '3 Chat', value: '3' },
  { label: '4 Chat', value: '4' },
  { label: '5 Chat', value: '5' }
];
