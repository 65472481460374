import IncomingCallInterface from './incoming-call.interface';
import { useCallStore } from '../../../../store/call-state';
import { useEffect, useState } from 'react';
import { useUserStatusStore } from '../../../../store/user-state';
import { RoleEnum } from '../../../../static/role';
import { DurationToString } from '../../../../utils/timestamp';
import { useTimer } from '../../../../hooks/useTimer';
import { CommunicationTypeEnum } from '../../../../static/communication-type';
import Copy from '../../copy';
import { Button, Icon, Paragraph } from '@squantumengine/horizon';
import { useInboxStore } from '../../../../store/new/inbox';

const MAXIMUM_UNOPENED_SECOND = 10;

export default function IncomingCall(props: IncomingCallInterface) {
  const { data, handleActiveCall, startTimer } = props;
  const { communicationType, conversationId } = data;
  const { addAttentionChat } = useCallStore();
  const { userRole } = useUserStatusStore();
  const { second } = useTimer(startTimer);
  const { isSelectedInboxWhatsapp } = useInboxStore();

  const [requiresAttention, setRequiresAttention] = useState<boolean>(
    startTimer > MAXIMUM_UNOPENED_SECOND
  );

  const isInboxWhatsapp = isSelectedInboxWhatsapp();
  const isVideoComType = communicationType === CommunicationTypeEnum.VIDEO;

  const getIcon = () => {
    if (isInboxWhatsapp) return 'whatsapp';
    if (isVideoComType) return 'video';
    return 'comment';
  };

  // const AvatarIcon = communicationType === CommunicationTypeEnum.VIDEO ? VideoIcon : ChatIcon;

  useEffect(() => {
    if (second < MAXIMUM_UNOPENED_SECOND || requiresAttention) {
      return;
    }

    setRequiresAttention(true);
    addAttentionChat(conversationId);
  }, [second, requiresAttention, addAttentionChat, conversationId]);

  const onHandleAgent = () => {
    props.handleAssignAgent?.(data);
  };

  const durationText = DurationToString(second);
  const isMoreThan30Sec = second > 30;

  return (
    <div
      className={`flex w-full flex-col rounded-lg border border-solid border-neutral-100 text-neutral-950`}>
      <div className="flex shrink-0 flex-row items-center gap-1 p-4">
        <div className="relative flex flex-row items-center">
          <Icon name={getIcon()} />
        </div>
        <Paragraph size="l" weight="semibold" className="max-w-full grow truncate">
          {data?.name}
        </Paragraph>
      </div>

      <hr className="m-0 border border-b-0 border-solid border-neutral-100" />

      <div className="px-4 py-3">
        <div className="mb-1 flex flex-row items-center justify-between">
          <Copy value={conversationId}>
            <Paragraph className="text-neutral-600">Tiket</Paragraph>
          </Copy>
          <Paragraph className="w-[80px] truncate">#{conversationId}</Paragraph>
        </div>

        <div className="mb-1 flex flex-row items-center justify-between">
          <Paragraph className={isMoreThan30Sec ? 'text-red-500' : 'text-neutral-600'}>
            Waktu Tunggu
          </Paragraph>
          <Paragraph className={`truncate ${isMoreThan30Sec && 'text-red-500'}`}>
            {durationText}
          </Paragraph>
        </div>
      </div>

      <hr className="m-0 border border-b-0 border-solid border-neutral-100" />

      <div className="flex flex-col gap-3">
        {userRole === RoleEnum.AGENT && (
          <div className="flex flex-row items-center justify-center py-1">
            <Button variant="text" onClick={async () => handleActiveCall(data)}>
              Jawab
            </Button>
          </div>
        )}
        {userRole === RoleEnum.SUPERVISOR && (
          <div className="flex flex-row items-center justify-center py-1">
            <Button variant="text" onClick={onHandleAgent}>
              Tugaskan Agen
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
