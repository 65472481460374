import { ExpandAltOutlined } from '@ant-design/icons';
import { useState, useMemo } from 'react';
import { Flex, Modal, PaginationProps } from 'antd';
import dayjs from 'dayjs';
import { GalleryModalInterface, ModeTypeEnum } from './kyc.interface';
import { DocumentInterface } from '../../form/workspace/workspace.interface';
import { pageSizeKYC } from '../../../utils/pageLimit';
import { getFileFromUrl } from '../../../utils/file';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { Button, Icon, Pagination, Paragraph, Title, Token } from '@squantumengine/horizon';
import { getIconUrl } from '../../../utils/getIconUrl';
import ExpandedPhotoModal from './modal-expanded-photo';

const { COLORS } = Token;

function GalleryModal(props: GalleryModalInterface) {
  const { open, handleOk, handleCancel, documents, confirmLoading, setIsNewAttachment, type } =
    props;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [choose, setChoose] = useState<DocumentInterface | null>(null);
  const { setChooseKTP, setFileKTP, setChooseSelfie, setFileSelfie } = useSelectedCustomerStore();

  const [isExpandedModalVisible, setIsExpandedModalVisible] = useState<boolean>(false);
  const [expandedImageUrl, setExpandedImageUrl] = useState<string | null>(null);

  const dataKYC = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSizeKYC;
    const lastPageIndex = firstPageIndex + pageSizeKYC;

    const filteredDocuments = documents
      .slice(firstPageIndex, lastPageIndex)
      .filter((doc) => {
        const extension = doc?.documentName?.split('.')?.pop()?.toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp'];
        return imageExtensions.includes(extension!);
      })
      .reverse();

    return filteredDocuments;

    // eslint-disable-next-line
  }, [currentPage, documents]);

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrentPage(page);
  };

  const isKTPMode = type === ModeTypeEnum.KTP;
  const isDataEmpty = dataKYC.length === 0;

  const onChooseKTP = async () => {
    if (choose?.documentUrl) {
      const file = await getFileFromUrl(choose?.documentUrl, 'ktp.png');
      setFileKTP(file);
      setChooseKTP(choose);
    }
    handleOk();
    setIsNewAttachment(false);
  };

  const onChooseSelfie = async () => {
    if (choose?.documentUrl) {
      const file = await getFileFromUrl(choose?.documentUrl, 'selfie.png');
      setFileSelfie(file);
      setChooseSelfie(choose!);
    }
    handleOk();
    setIsNewAttachment(false);
  };

  const onSave = () => {
    isKTPMode ? onChooseKTP() : onChooseSelfie();
  };

  return (
    <>
      <Modal
        title={
          <Title level={4}>Pilih Foto {isKTPMode ? ModeTypeEnum.KTP : ModeTypeEnum.SELFIE}</Title>
        }
        open={open && !isExpandedModalVisible}
        onOk={handleOk}
        width={600}
        confirmLoading={confirmLoading}
        footer={
          <Flex gap={12} justify="end" align="center">
            <Button variant="secondary" onClick={handleCancel}>
              Kembali
            </Button>
            <Button
              className={`${!choose && 'cursor-not-allowed'}`}
              disabled={!choose}
              onClick={onSave}>
              Pilih sebagai {isKTPMode ? ModeTypeEnum.KTP : ModeTypeEnum.SELFIE}
            </Button>
          </Flex>
        }
        centered
        onCancel={handleCancel}>
        {isDataEmpty ? (
          <Flex vertical gap={2} align="center" justify="center">
            <img width={50} src={getIconUrl('no-photo.svg')} />
            <Title level={4}>Belum ada foto</Title>
            <Paragraph className="text-center">
              Minta pelanggan untuk mengunggah foto. <br /> Gunakan petunjuk foto untuk memudahkan
              pelanggan mengambil foto.
            </Paragraph>
          </Flex>
        ) : (
          <Flex vertical gap={24}>
            <Flex vertical gap={24} className="mb-4">
              <div className="grid w-full grid-cols-2 items-center justify-items-end gap-4">
                {dataKYC?.map((item: DocumentInterface, index: number) => {
                  const isSelected = choose?.documentId === item?.documentId;

                  return (
                    <div className="relative" key={index}>
                      <ExpandAltOutlined
                        onClick={() => {
                          setExpandedImageUrl(item?.documentUrl!);
                          setIsExpandedModalVisible(true);
                        }}
                        className="absolute right-4 top-4 cursor-pointer text-white transition-[transform] duration-100 hover:scale-150"
                      />
                      <img
                        alt="kyc-img"
                        width={270}
                        key={item?.documentId}
                        src={item?.documentUrl}
                        className={`cursor-pointer rounded-3xl ${
                          isSelected && 'border-2 border-solid border-blue-500'
                        }`}
                        onClick={() => {
                          setChoose(item);
                        }}
                      />
                      <Flex justify="space-between" align="center">
                        <Paragraph className="text-grey-100">
                          {dayjs(item?.createdAt).format('HH:mm')}
                        </Paragraph>
                        {isSelected && (
                          <Flex gap={4} align="center">
                            <Icon color={COLORS.blue[500]} name="check" />
                            <Paragraph className="text-blue-500">Terpilih</Paragraph>
                          </Flex>
                        )}
                      </Flex>
                    </div>
                  );
                })}
              </div>
              <Pagination
                defaultPageSize={pageSizeKYC}
                total={documents.length}
                showSizeChanger={false}
                onChange={onChange}
                current={currentPage}
                defaultCurrent={1}
              />
            </Flex>
          </Flex>
        )}
      </Modal>

      {/* Expanded Photo Modal */}
      <ExpandedPhotoModal
        isVisible={isExpandedModalVisible}
        expandedImageUrl={expandedImageUrl}
        isKTPMode={isKTPMode}
        handleCancel={() => {
          setIsExpandedModalVisible(false);
          setExpandedImageUrl(null);
        }}
        handleSave={() => {
          onSave();
          setExpandedImageUrl(null);
          setIsExpandedModalVisible(false);
        }}
        handleGoBack={() => {
          setExpandedImageUrl(null);
          setIsExpandedModalVisible(false);
        }}
      />
    </>
  );
}

export default GalleryModal;
